export const initialTableData = {
  status: {
    pending: false,
    error: false,
    completed: true
  },
  data: [],
  page: 1,
  total: 0,
  sort: {
    order: undefined,
    field: undefined
  }
}
