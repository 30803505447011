import BetRequest from "api/request"
import { StoreDictionary } from "store/dictionary/types"
import { RouteComponentProps } from "react-router-dom"
import { StoreDevelopState } from "store/developer/types"

export const ERROR_PAGE = "/ERROR_PAGE"
export const SERVER_ERROR_PAGE = "/SERVER_ERROR_PAGE"

export const REPORT_PAGE = "/report"
export const SCHEDULER_PAGE = "/scheduler"

export const HISTORY_PAGE = "/history"
export const SUCCESS_PAGE = "/history/:id"
export const CREATE_PAGE = "/create"
export const INFO_ROUTE = "/information"
export const INFO_HOW_TO_GET = "/how-to-get"

export interface GetDictionaryProps {
  logout: () => void
  history: RouteComponentProps["history"]
  storeDictionary: (
    data: StoreDictionary
  ) => { type: string; data: StoreDictionary }
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export const getDictionary = ({
  logout,
  history,
  storeDictionary,
  developer,
  addDeveloperLink
}: GetDictionaryProps) => {
  BetRequest({
    url: `dictionary`,
    method: `POST`,
    withHall: true,
    logout,
    developer,
    addDeveloperLink,
    history
  }).then(response => {
    const { data } = response
    const responseData: StoreDictionary = {
      status: data.status,
      course: data.course
    }
    storeDictionary(responseData)
  })
}

export const getCourseForDictionary = ({
  logout,
  history,
  storeDictionary,
  developer,
  addDeveloperLink
}: GetDictionaryProps) => {
  BetRequest({
    url: `dictionary`,
    method: `POST`,
    withHall: true,
    logout,
    developer,
    addDeveloperLink,
    history
  }).then(response => {
    const { data } = response
    const responseData: StoreDictionary = {
      status: data.status,
      course: data.course
    }
    storeDictionary(responseData)
  })
}
