import React, { useCallback, useEffect, useMemo, useState } from "react"

import { useRequest } from "api/hooks"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ButtonPrimary, ButtonCancel } from "Components/Button"

import { CheckModalProps, CheckModalState, CheckModalTableProps } from "./types"
import { initialData, getRequestUrl } from "./constants"

import { Input } from "antd"

const CheckModalTable = ({
  data,
  title,
  snils,
  setSnils,
  isNew
}: CheckModalTableProps) => {
  return (
    <div className="CheckModalTableWrapper">
      <div className="CheckModalTableTitle">{title}</div>
      <div className="CheckModalTableContent FlexRow">
        <div>
          <div className="CheckModalTableContentLabel">Клуб №</div>
          <div className="CheckModalTableContentValue">{data.hall}</div>
        </div>
        <div>
          <div className="CheckModalTableContentLabel">ФИО</div>
          <div className="CheckModalTableContentValue CheckModalTableContentValueName">
            {data.employee_name}
          </div>
        </div>
        <div>
          <div className="CheckModalTableContentLabel">Телефон</div>
          <div className="CheckModalTableContentValue">{data.phone}</div>
        </div>
        <div>
          <div className="CheckModalTableContentLabel FlexRow">
            <span>СНИЛС</span>
            {isNew && <EditIcon />}
          </div>
          <div className="CheckModalTableContentValue CheckModalTableContentValueSnils">
            {isNew && !!setSnils ? (
              <Input
                placeholder=""
                bordered={false}
                value={snils}
                onChange={({ target: { value } }) =>
                  setSnils(value || undefined)
                }
              />
            ) : (
              data.snils
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const CheckModal = ({ open, close, refresh }: CheckModalProps) => {
  const [data, setData] = useState<CheckModalState>(initialData)
  const [snils, setSnils] = useState<string | undefined>(undefined)
  const canCancel = snils !== data.old.snils
  const url = useMemo(() => getRequestUrl(open), [])
  const { request: getData } = useRequest({
    url,
    method: "GET"
  })
  const { request: approveData } = useRequest({
    url,
    method: "POST",
    requestBody: { approve: true }
  })
  const { request: cancelData } = useRequest({
    url,
    method: "POST",
    requestBody: { approve: false, snils }
  })
  const success = useCallback(() => {
    close()
    refresh()
  }, [])
  useEffect(() => {
    setData({ ...data, pending: true })
    getData()
      .then(({ data }) => {
        setData({ ...data, pending: false })
        setSnils(data?.new.snils)
      })
      .catch(() => setData({ ...data, pending: false }))
  }, [])
  return (
    <div>
      <div className="CheckModalWrapper">
        <div className="CheckModalHeader FlexRow">
          <div className="CheckModalHeaderTitle">
            Подтверждение идентичности сотрудника
          </div>
          <div className="CheckModalHeaderClose" onClick={close}>
            <CloseIcon />
          </div>
        </div>
        <div className="CheckModalDescription">
          Система обнаружила две записи возможно одного сотрудника, но с
          отличными данными. Если это один и тот же человек — нажмите
          «Подтвердить», если нет — «Отклонить»
        </div>
        <CheckModalTable
          data={data.old}
          title="Данные введенные вручную"
          snils={snils}
          setSnils={setSnils}
          isNew={true}
        />
        <CheckModalTable data={data.new} title="Данные полученные из 1С" />
        <div className="Rectangle" />
        <div className="CheckModalButtons">
          <div className="FlexRow">
            <ButtonCancel
              disabled={!canCancel}
              onClick={() => {
                cancelData().then(() => success())
              }}
            >
              Отклонить
            </ButtonCancel>
            <ButtonPrimary
              disabled={false}
              onClick={() => {
                approveData().then(() => success())
              }}
            >
              Подтвердить
            </ButtonPrimary>
          </div>
        </div>
      </div>
      <div className="ModalOverflow" />
    </div>
  )
}

export default CheckModal
