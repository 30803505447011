import { StoreDevelopState } from "./types"
import { ADD_LINK } from "./constants"
const init: StoreDevelopState = {}

export function developerReducer(
  state: StoreDevelopState = init,
  action: { type: string; data: StoreDevelopState }
): StoreDevelopState {
  const { type, data } = action
  switch (type) {
    case ADD_LINK: {
      return { ...state, ...data }
    }

    default:
      return state
  }
}
