import React, { useState } from "react"

import { checkPhone } from "api/constant"
import { useRequestParams } from "api/hooks"

import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ReactComponent as OkIcon } from "Components/icons/check.svg"
import { ReactComponent as CrossIcon } from "Components/icons/redCross.svg"

import { Input } from "antd"

import editPhoneNumber from "./helper"
import { PhoneInputComponentProps, PhoneState } from "./types"

const PhoneInputComponent = ({
  phone,
  employeeId
}: PhoneInputComponentProps) => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()
  const [phoneState, setPhoneState] = useState<PhoneState>({
    value: phone,
    isInput: false,
    pending: false,
    initial: phone
  })

  const isPhoneEnterDone = phoneState.value
    ? phoneState.value.search(`_`) > 0
    : true
  return (
    <div className="PhoneInputComponentWrapper">
      {phoneState.isInput ? (
        <div>
          <div className="EmployeePhoneInput">
            <Input
              maxLength={11}
              placeholder=""
              value={phoneState.value}
              onChange={({ target: { value } }) => {
                const newValue =
                  !value || checkPhone(value) ? value : phoneState.value
                setPhoneState({
                  ...phoneState,
                  value: newValue
                })
              }}
              bordered={false}
              onPressEnter={() => {
                if (!isPhoneEnterDone) {
                  editPhoneNumber({
                    employeeId,
                    developer,
                    addDeveloperLink,
                    logout,
                    history,
                    phoneState,
                    setPhoneState
                  })
                }
              }}
            />
            <div className="PhoneInputIconsWrapper">
              <OkIcon
                className={`PhoneInputIconWrapper ${
                  !isPhoneEnterDone ? `` : `PhoneInputIconDisabled`
                }`}
                onClick={() => {
                  if (!isPhoneEnterDone) {
                    editPhoneNumber({
                      employeeId,
                      developer,
                      addDeveloperLink,
                      logout,
                      history,
                      phoneState,
                      setPhoneState
                    })
                  }
                }}
              />
            </div>
            <div className="PhoneInputIconsWrapper">
              <CrossIcon
                className="PhoneInputIconWrapper"
                onClick={() =>
                  setPhoneState({
                    ...phoneState,
                    value: phoneState.initial,
                    isInput: false
                  })
                }
              />
            </div>
          </div>
          <div className="PhoneInputComponentMaskRectangle" />
        </div>
      ) : (
        <div className="PhoneInputComponentSpanWrapper">
          {phoneState.value}
          <div
            className="PhoneInputComponentSpan"
            onClick={() => setPhoneState({ ...phoneState, isInput: true })}
          >
            <EditIcon />
          </div>
        </div>
      )}
    </div>
  )
}

export default PhoneInputComponent
