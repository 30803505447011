import React from "react"

import { SuccessHideIcon, SuccessOpenIcon, SuccessArchive } from "./icons"
import { ReactComponent as VideoIcon } from "Components/icons/video-icon.svg"
import { ReactComponent as CenterIcon } from "Components/icons/center.svg"

import { FormatItem } from "./types"

export const archiveUrl = (id: number) => `admin/course/${id}/archive`
export const visibleUrl = (id: number) => `admin/course/${id}/visible`
export const chartToggleUrl = (id: number) => `admin/course/${id}/graph_visible`

export const checkIsCanEdit = () => {
  const userFromStorage = JSON.parse(localStorage.getItem(`user`) || ``)
  return (
    !!userFromStorage &&
    (userFromStorage.isAdmin ||
      userFromStorage.role === `ROLE_DEVELOPER` ||
      userFromStorage.role === `ROLE_TRAINING`)
  )
}

export const visibleApproveText = (visible: boolean) => {
  if (visible) {
    return {
      title: `Скрыть курс `,
      description: [
        `Вы точно хотите скрыть курс?`,
        `После этого он станет не доступен для записи`
      ],
      cancelText: `Отменить`,
      approveText: `Да, скрыть`,
      successIcon: <SuccessHideIcon />,
      successText: [`Курс скрыт.`, `Запись на курс недоступна`]
    }
  } else {
    return {
      title: `Открыть курс `,
      description: [
        `Вы точно хотите открыть курс?`,
        `После этого он станет доступен для записи`
      ],
      cancelText: `Отменить`,
      approveText: `Да, открыть`,
      successIcon: <SuccessOpenIcon />,
      successText: [`Курс открыт.`, `Запись на курс доступна`]
    }
  }
}

export const archiveApproveText = (archive: boolean) => {
  if (!archive) {
    return {
      title: `Архивировать курс`,
      description: [
        `Вы точно хотите архивировать курс?`,
        `После этого он будет не доступен для записи`,
        `и станет неактивным`
      ],
      cancelText: `Отменить`,
      approveText: `Да, архивировать`,
      successIcon: <SuccessArchive />,
      successText: [`Курс заархивирован.`, `Запись на курс недоступна`]
    }
  } else {
    return {
      title: `Разархивировать курс `,
      description: [
        `Разархивировать курс?`,
        `После этого он станет активным`,
        `и доступен для записи`
      ],
      cancelText: `Отменить`,
      approveText: `Да, разархивировать`,
      successIcon: <SuccessOpenIcon />,
      successText: [`Курс разархивирован.`, `Запись на курс доступна`]
    }
  }
}

export const textOpenCategory = {
  getLessonDuration: (duration: number) => (
    <div className="FlexRow">
      <p>Ориентировочное время обучения — </p>
      <b> {` ${duration} часов каждый день`}</b>
    </div>
  ),
  getTime: (start: string, assemble: string) => (
    <div className="FlexRow">
      <p>
        Сбор обучающихся в {assemble}.{" "}
        <b>Начало в {start} по московскому времени. </b>После указанного времени
        сотрудник до обучения не допускается.{" "}
      </p>
    </div>
  ),
  getDay: (day: number) => `${day} день:`,
  format: {
    [FormatItem.remotely]: {
      label: `Формат дистанционного обучения:`,
      icon: <VideoIcon />
    },
    [FormatItem.center]: {
      label: `Формат в обучающем центре:`,
      icon: <CenterIcon />
    }
  },
  free: `Обучение бесплатное.`,
  open: `Открыть запись`,
  close: `Скрыть курс`,
  edit: `Редактировать курс`,
  archive: `Архивировать курс`,
  unzip: `Разархивировать курс`
}
