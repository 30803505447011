import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory, useParams } from "react-router-dom"
import getRequestInfo, { getColumns, saveCompleted } from "./helper"
import { ButtonPrimary } from "Components/Button"
import { HISTORY_PAGE } from "Containers/BaseLayout/constants"
import { ReactComponent as BackIcon } from "Components/icons/backArrow.svg"
import { LOGOUT } from "store/auth/constants"
import {
  StoreDictionaryCourse,
  StoreDictionaryStatus
} from "store/dictionary/types"
import { IRootState } from "store"
import { ReactComponent as CourseDate } from "Components/icons/course_date.svg"
import { ReactComponent as CourseDuration } from "Components/icons/course_duration.svg"
import { ReactComponent as CourseName } from "Components/icons/course_name.svg"
import moment from "moment"
import TableComponent from "Components/Table"
import { downloadFile } from "api/hooks"
import { ReactComponent as ExelIcon } from "Components/icons/icon-excel.svg"
import ButtonDefault from "Components/Button"
import StatusComponent from "Components/Status"
import { StoreDevelopState } from "store/developer/types"
import { ADD_LINK } from "store/developer/constants"

type SuccessPageType = {
  logout: () => void
  course: StoreDictionaryCourse[]
  status: StoreDictionaryStatus[]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export interface ISuccessPageData {
  course_name: string | null
  date_start: number | null
  date_end: number | null
  course_id: number | null
  status_id: number
  hall?: number | null
}

export interface ISuccessPage {
  pending: boolean
  data: ISuccessPageData
}

export interface ISuccessData {
  [id: number]: {
    id: number | null
    visits: number[]
    result: number | undefined | string
    comment: string | null
  }
}

const SuccessPageComponent = ({
  logout,
  course,
  status,
  developer,
  addDeveloperLink
}: SuccessPageType) => {
  const offset = new Date().getTimezoneOffset() * 60000
  const { id }: { id: string | undefined } = useParams()
  const userData: { role: string } = localStorage.getItem(`user`)
    ? JSON.parse(localStorage.getItem(`user`) || ``)
    : {}
  const history = useHistory()
  const [requestData, changeRequestData] = useState<ISuccessPage>({
    pending: false,
    data: {
      course_name: null,
      date_start: null,
      date_end: null,
      status_id: 0,
      course_id: null
    }
  })

  useEffect(() => {
    getRequestInfo({
      id,
      history,
      logout,
      requestData,
      developer,
      addDeveloperLink,
      changeData: changeRequestData
    })
  }, [id])
  const editMode = userData
    ? userData.role === `ROLE_TRAINING` && requestData.data.status_id === 4
    : false

  const successMode = userData
    ? userData.role === `ROLE_TRAINING` && requestData.data.status_id === 2
    : false

  const isAdmin = userData.role === `ROLE_TRAINING`
  const currentCourse = course.find(
    item => item.id === requestData.data.course_id
  )
  const currentStatus = status.find(item =>
    item.id === requestData.data.status_id ? item : null
  )
  const [successData, setSuccessData] = useState<ISuccessData>({})
  const [downloadPending, loadSetPending] = useState<boolean>(false)

  const initialSuccessData = (data: any[]) => {
    if (
      (editMode || successMode) &&
      currentCourse &&
      currentCourse.duration &&
      data
    ) {
      const initialVisit: number[] = []
      for (let i = 0; i < currentCourse.duration; i++) {
        initialVisit.push(1)
      }
      const newData: ISuccessData = {}
      for (let j = 0; j < data.length; j++) {
        newData[data[j].id] = {
          id: data[j].id,
          visits: data[j].visit || initialVisit,
          result: data[j].result || null,
          comment: data[j].comment || null
        }
      }
      setSuccessData(newData)
    }
  }
  const columns = getColumns({
    duration:
      currentCourse && currentCourse.duration ? currentCourse.duration : 0,
    lesson_duration:
      currentCourse && currentCourse.lesson_duration
        ? currentCourse.lesson_duration
        : 0,
    date_end: requestData.data.date_end,
    date_start: requestData.data.date_start,
    initial: successData,
    setSuccessData,
    editMode,
    successMode
  })
  return (
    <div className="SuccessPageWrapper">
      <div className="SuccessPageEvents">
        <div className="SuccessPageEventsLeft">
          <div className="SuccessPageTitle">
            <div
              className="SuccessPageTitleButton"
              onClick={() => history.push(HISTORY_PAGE)}
            >
              <BackIcon />
            </div>
            <h1 className="SuccessPageTitleText">Заявка №{id}</h1>
          </div>
          <div className="SuccessPageTitleDescriptions">
            {isAdmin && (
              <div style={{ paddingTop: `2px` }}>
                Клуб №{requestData.data.hall}
              </div>
            )}
            {currentStatus && (
              <div style={{ paddingLeft: isAdmin ? `8px` : `` }}>
                <StatusComponent
                  label={currentStatus.name || ``}
                  color={currentStatus.color || ``}
                  id={currentStatus.id}
                />
              </div>
            )}
          </div>
        </div>
        <div className="SuccessPageEventsRight">
          {isAdmin && (
            <div style={{ paddingRight: `16px`, marginTop: `6px` }}>
              <ButtonDefault
                disabled={downloadPending}
                size="small"
                onClick={() =>
                  downloadFile({
                    logout,
                    history,
                    source: `admin/report/application/${id}`,
                    method: `GET`,
                    loadSetPending,
                    requestBody: {},
                    developer,
                    addDeveloperLink
                  })
                }
              >
                <div className="HistoryEventsLeftItemButtonIconWrapper">
                  <ExelIcon />
                </div>
                Выгрузить в Excel
              </ButtonDefault>
            </div>
          )}
          {successMode && (
            <div className="SuccessPageEventsRightButton">
              <ButtonPrimary
                onClick={() =>
                  saveCompleted({
                    logout,
                    history,
                    completedData: successData,
                    id,
                    status_id: 4,
                    developer,
                    addDeveloperLink
                  })
                }
                disabled={
                  currentCourse &&
                  currentCourse.lesson_duration &&
                  requestData.data.date_end &&
                  moment
                    .utc(requestData.data.date_end)
                    .startOf(`day`)
                    .add(
                      moment.utc(requestData.data.date_start).hour() +
                        currentCourse.lesson_duration,
                      `hours`
                    )
                    .valueOf() +
                    offset >
                    moment.utc().valueOf()
                }
              >
                Перевести в Выполнено
              </ButtonPrimary>
            </div>
          )}
          {(successMode || editMode) && (
            <div className="SuccessPageEventsRightButton">
              <ButtonPrimary
                onClick={() =>
                  saveCompleted({
                    logout,
                    history,
                    completedData: successData,
                    id,
                    status_id: undefined,
                    developer,
                    addDeveloperLink
                  })
                }
                disabled={false}
              >
                Сохранить
              </ButtonPrimary>
            </div>
          )}
        </div>
      </div>
      <div className="SuccessPageInfoWrapper">
        <div className="SuccessPageInfoItem">
          <div className="SuccessPageInfoInner">
            <div className="SuccessPageInfoIcon">
              <CourseDate />
            </div>
            <div className="SuccessPageInfoText">
              <div className="SuccessPageInfoLabel">Тип обучения</div>
              <div className="SuccessPageInfoValue">
                {currentCourse ? `${currentCourse.name}` : ``}
              </div>
            </div>
          </div>
        </div>
        <div className="SuccessPageInfoItem">
          <div className="SuccessPageInfoInner">
            <div className="SuccessPageInfoIcon">
              <CourseDuration />
            </div>
            <div className="SuccessPageInfoText">
              <div className="SuccessPageInfoLabel">Даты обучения</div>
              {requestData.data.date_start && requestData.data.date_end && (
                <div className="SuccessPageInfoValue">
                  {`${moment(requestData.data.date_start).format(
                    `DD.MM.YYYY`
                  )} — ${moment
                    .utc(requestData.data.date_end)
                    .format(`DD.MM.YYYY`)}${moment(
                    requestData.data.date_start
                  ).format(` (с HH:mm)`)}`}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="SuccessPageInfoItem">
          <div className="SuccessPageInfoInner">
            <div className="SuccessPageInfoIcon">
              <CourseName />
            </div>
            <div className="SuccessPageInfoText">
              <div className="SuccessPageInfoLabel">Длительность курса</div>
              <div className="SuccessPageInfoValue">
                {currentCourse ? `${currentCourse.duration}` : ``} дня{" "}
                <span className="SuccessPageInfoDuration">
                  {" "}
                  ({currentCourse
                    ? `${currentCourse.lesson_duration}`
                    : ``}{" "}
                  часов в день)
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="HistoryTableWrapper">
        <TableComponent
          scrollX={columns.length * 150}
          waitRequest={!currentCourse}
          scrollY="calc(100vh - 400px)"
          columnsData={columns}
          dataSource={`admin/application/${id}/employees`}
          scrollWithoutPagination
          getTableData={initialSuccessData}
        />
      </div>
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    course: state.dictionary.course,
    status: state.dictionary.status,
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)

export default connector(SuccessPageComponent)
