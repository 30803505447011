import { Columns } from "Components/Table/types"
import { CheckBoxComponent } from "Components/CheckBox"
import PhoneInputComponent from "Components/PhoneInput"
import { PanelState } from "Containers/CreateComponent"
import React from "react"
import moment from "moment"

export interface IEmployeeData {
  id: number
  name: string
  position: string
  hired: number
  phone: string
  fired: boolean
  hall?: string
}
export const getColumns = (
  panelState: PanelState,
  changePanelState: (data: PanelState) => void
): Array<Columns> => {
  return [
    {
      title: null,
      key: `check`,
      dataIndex: `check`,
      withoutSorter: true,
      width: 7
    },
    {
      title: `Сотрудники`,
      key: `name`,
      dataIndex: `name`,
      withoutSorter: false,
      render: (
        text: string | undefined,
        record: IEmployeeData,
        index: number | undefined
      ) => {
        const {
          employee: { list, data }
        } = panelState
        const { id } = record
        const isChooseOne = list && list.length > 0 && list[0] !== id
        const isChecked = list.findIndex(item => item === id) >= 0
        const isDisabled =
          (panelState.date.students &&
            panelState.date.booking_students &&
            panelState.date.students === panelState.date.booking_students &&
            panelState.employee &&
            panelState.employee.data &&
            !panelState.employee.data[id]) ||
          isChooseOne
        return (
          <div className="EmployeeTableCheckColumnWrapper">
            <CheckBoxComponent
              checked={isChecked}
              disabled={!!isDisabled}
              onChange={({
                target: { checked }
              }: {
                target: { checked: any }
              }) => {
                const newList = list
                const newData = data || {}
                if (checked) {
                  changePanelState({
                    ...panelState,
                    date: {
                      ...panelState.date,
                      booking_students: panelState.date.booking_students
                        ? panelState.date.booking_students + 1
                        : 1
                    },
                    employee: {
                      list: newList.concat(id),
                      data: { ...newData, [id]: record },
                      hall: record.hall
                    }
                  })
                } else {
                  newList.splice(
                    list.findIndex(item => item === id),
                    1
                  )
                  delete newData[id]
                  changePanelState({
                    ...panelState,
                    date: {
                      ...panelState.date,
                      booking_students: panelState.date.booking_students
                        ? panelState.date.booking_students - 1
                        : 0
                    },
                    employee: {
                      list: newList,
                      data: newData
                    }
                  })
                }
              }}
              label=""
            />
            <div className="EmployeeTableCheckColumnText">{text}</div>
          </div>
        )
      }
    },
    {
      title: `Дожность`,
      key: `position`,
      dataIndex: `position`,
      withoutSorter: true
    },
    {
      title: `Телефон`,
      key: `phone`,
      dataIndex: `phone`,
      withoutSorter: true,
      width: 160,
      // eslint-disable-next-line react/display-name
      render: (phone: string | undefined, record: IEmployeeData) => {
        return <PhoneInputComponent phone={phone} employeeId={record.id} />
      }
    },
    {
      title: `Дата последнего обучения`,
      key: `date_last_lesson`,
      dataIndex: `date_last_lesson`,
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (date_last_lesson: number[] | null) => (
        <div>
          {date_last_lesson
            ? `${moment(date_last_lesson).format(`DD.MM.YYYY`)}`
            : ``}
        </div>
      )
    }
  ]
}
