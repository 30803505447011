import BetRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import { IEditData } from "./index"
import { IEmployeeData } from "Containers/CreateComponent/Employee/helper"
import { StoreDevelopState } from "store/developer/types"

import { errorCanvas } from "Components/Messages"

type saveRequestType = {
  graph_id: number | undefined | null
  id: number | boolean
  isAdmin: boolean
  employees: number[]
  logout: () => void
  history: RouteComponentProps["history"]
  toggleSuccess: (data: boolean) => void
  developer: StoreDevelopState
  setDisabledButton: (disabledButton: boolean) => void
  addDeveloperLink: (data: StoreDevelopState) => void
}

export const saveRequestData = ({
  id,
  isAdmin,
  graph_id,
  employees,
  history,
  logout,
  toggleSuccess,
  developer,
  setDisabledButton,
  addDeveloperLink
}: saveRequestType) => {
  setDisabledButton(true)
  BetRequest({
    url: `${isAdmin ? `admin/` : ``}application/${id}`,
    method: `PUT`,
    history,
    developer,
    addDeveloperLink,
    logout,
    requestBody: { graph_id, employees, status_id: isAdmin ? 1 : undefined }
  })
    .then(() => {
      setDisabledButton(false)
      toggleSuccess(true)
    })
    .catch(({ response }) => {
      setDisabledButton(false)
      errorCanvas({
        errors: response.data.errors,
        description: response.data.description
      })
    })
}

interface IGetInitialData {
  id: number | boolean
  logout: () => void
  history: RouteComponentProps["history"]
  initialData: IEditData
  changeInitialData: (data: IEditData) => void
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

type InfoDataType = {
  status_id: number | null
  course_id: number | null
  date_start: number | null
  date_end: number | null
  graph_id: number | null
  employees: IEmployeeData[]
  graph: {
    students: number | null
  }
}

const getInitialData = ({
  id,
  logout,
  history,
  initialData,
  changeInitialData,
  developer,
  addDeveloperLink
}: IGetInitialData) => {
  changeInitialData({ ...initialData, pending: true })
  BetRequest({
    url: `application/${id}`,
    logout,
    developer,
    addDeveloperLink,
    history,
    method: `GET`
  })
    .then(({ data }: { data: InfoDataType }) => {
      const { course_id, employees, status_id, graph_id } = data
      const currentEmployee = employees.map(item => item.id)
      changeInitialData({
        ...initialData,
        course_id,
        employees: {
          list: currentEmployee,
          data: employees
        },
        status_id,
        graph_id: graph_id || null,
        pending: false,
        students: data.graph ? data.graph.students : 1,
        date_end: data.date_end,
        date_start: data.date_start
      })
    })
    .catch(() => changeInitialData({ ...initialData, pending: false }))
}

export default getInitialData
