import React from "react"
import { Calendar } from "antd"

const CalendarComponent = ({ ...rest }: any) => {
  return (
    <div className="CalendarComponentWrapper">
      <Calendar {...rest} />
    </div>
  )
}

export default CalendarComponent
