import { action } from "typesafe-actions"
import { LOGIN, LOGOUT } from "./constants"
import { StoreUserState } from "./types"

export const loginUser = (payload: StoreUserState) => {
  return action(LOGIN, payload)
}

export const logoutUser = () => {
  return action(LOGOUT)
}
