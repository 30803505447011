import BetRequest from "api/request"
import moment from "moment"

import { DAY, DayMonth } from "Components/DatePicker/constants"
import { errorCanvas } from "Components/Messages"

import { SaveGraph, DeleteGraph, SaveGraphText, CreateFormState } from "./types"

const offset = new Date().getTimezoneOffset() * 60000

export const hideSnils = (snils: string) =>
  !!snils ? `∙∙∙ ∙∙∙ ∙∙∙ ${snils.slice(-2)}` : ``

export const checkNumber = (str?: string) => /^[0-9]+$/.test(str || ``)
export const checkPhone = (str?: string) => {
  const regex = /^[+]*[0-9]+$/
  return regex.test(str || ``)
}

export const validateState = (data: CreateFormState) => {
  const isNew = !data.id
  const employees = data.employees
  const checkEmployees = employees.filter(
    item => !item || !item.hall || !item.name || !item.snils || !item.phone
  )
  const newCond =
    !data.course_id || !data.time_start || !data.name || !data.students
  const editCond = !data.date_start || newCond || checkEmployees?.length > 0

  return isNew ? newCond : editCond
}

const isRequiredText = `Обязательное поле`

export const createGraphText = ({ isEdit, id, startDate }: SaveGraphText) => ({
  title: isEdit
    ? `Редактирование курса №${id}`
    : `Создать курс на ${moment(startDate).format(DayMonth)}`,
  fields: {
    name: {
      name: `name`,
      title: `Название курса:`,
      message: isRequiredText,
      placeholder: `Название`
    },
    course_id: {
      name: `course_id`,
      title: `Тип обучения:`,
      message: isRequiredText,
      placeholder: `Тип обучения`
    },
    date_start: {
      name: `date_start`,
      title: `Дата начала:`,
      message: isRequiredText,
      placeholder: ``
    },
    date_end: {
      name: `date_end`,
      title: `Дата завершения:`,
      message: isRequiredText,
      placeholder: ``
    },
    students: {
      name: `students`,
      title: `Количество мест:`,
      message: isRequiredText,
      placeholder: ``
    },
    time_start: {
      name: `time_start`,
      title: `Время начала:`,
      message: isRequiredText
    },
    employees: {
      title: `Сотрудники:`,
      name: `employees`,
      message: ``,
      placeholder: ``
    }
  },
  buttons: {
    save: `Сохранить`,
    cancel: `Отменить`,
    add: `Добавить`
  }
})

export const newEmployeeInitial = {
  name: undefined,
  phone: undefined,
  hall: undefined,
  snils: undefined
}

export const deleteGraph = ({
  history,
  logout,
  refresh,
  close,
  id,
  developer,
  addDeveloperLink
}: DeleteGraph) => {
  BetRequest({
    url: `admin/graph/${id}`,
    developer,
    addDeveloperLink,
    method: `DELETE`,
    history,
    logout
  })
    .then(() => {
      refresh()
      close()
    })
    .catch(({ response }) => {
      errorCanvas({
        errors: response.data.errors,
        description: response.data.description
      })
    })
}

const saveGraph = async ({
  logout,
  history,
  toggleSuccessModal,
  dateStart,
  initial,
  isEdit,
  form,
  course,
  id,
  inProgress,
  addDeveloperLink,
  setDisabledButton,
  developer
}: SaveGraph) => {
  const values = form.getFieldsValue()
  const duration =
    ((
      course.find(
        item => item.id === (values.course_id || initial.course_id)
      ) || {}
    ).duration || 0) - 1
  setDisabledButton(true)
  const hour = moment(values.time_start).hour()
  const minute = moment.utc(values.time_start).minute()
  const startBasicDate = isEdit
    ? moment(values.date_start)
        .startOf(DAY)
        .valueOf()
    : moment(dateStart).valueOf()
  const createDateStart = startBasicDate + 60000 * hour * 60 + 60000 * minute
  const date_end = inProgress
    ? moment(values.date_end)
        .endOf(DAY)
        .valueOf()
    : moment
        .utc(createDateStart - offset)
        .add(duration, DAY)
        .endOf(DAY)
        .valueOf()

  const requestBody = {
    name: values.name,
    date_start: inProgress ? undefined : createDateStart,
    date_end,
    course_id: initial.course_id || values.course_id,
    students: values.students,
    employees: !initial.id ? undefined : values.employees
  }

  BetRequest({
    url: `admin/graph${!!id ? `/${id}` : ``}`,
    logout,
    history,
    developer,
    addDeveloperLink,
    method: isEdit ? `PUT` : `POST`,
    requestBody
  })
    .then(() => {
      setDisabledButton(false)
      toggleSuccessModal(true)
    })
    .catch(({ response }) => {
      setDisabledButton(false)
      errorCanvas({
        errors: response.data.errors,
        description: response.data.description
      })
    })
}

export default saveGraph
