import React, { useState } from "react"
import { useHistory } from "react-router-dom"

import { Popover } from "antd"

import { downloadFile, useRequestParams } from "api/hooks"

import moment from "moment"

import { ReactComponent as MoreIcon } from "Components/icons/info.svg"
import { ReactComponent as EditIcon } from "Components/icons/edit.svg"
import { ReactComponent as BlockIcon } from "Components/icons/block.svg"
import { ReactComponent as ApprovedIcon } from "Components/icons/approved.svg"
import { ReactComponent as ExelIcon } from "Components/icons/icon-excel.svg"
import { ReactComponent as HistoryIcon } from "Components/icons/history.svg"

import { Columns } from "Components/Table/types"
import StatusComponent from "Components/Status"
import { statuses } from "Components/Status/constants"
import ButtonDefault from "Components/Button"

import { GetColumnProps, TableFilter } from "./types"

import { HistoryModalData } from "Components/History/types"

export const getColumns = ({
  isAdmin,
  toggleStatusModal,
  toggleCancelModal,
  toggleEditModal,
  toggleMoreModal,
  toggleHistoryModal,
  status,
  course
}: GetColumnProps): Array<Columns> => {
  const history = useHistory()

  const hallColumnForAdmin = isAdmin
    ? [
        {
          title: "№ клуба",
          key: "hall",
          dataIndex: "hall",
          withoutSorter: false,
          width: 70,
          // eslint-disable-next-line react/display-name
          render: (text: number | null) => (
            <div className="HistoryTableCourseNumber">{text}</div>
          )
        }
      ]
    : []

  const dateHiredColumnForAdmin = isAdmin
    ? [
        {
          title: "Дата трудоустройства",
          key: "date_hired",
          dataIndex: "date_hired",
          withoutSorter: true,
          // eslint-disable-next-line react/display-name
          render: (date_last_lesson: number[] | null) => (
            <div>
              {date_last_lesson
                ? `${moment(date_last_lesson).format(`DD.MM.YYYY`)}`
                : ``}
            </div>
          )
        }
      ]
    : []

  return [
    {
      title: "№ заявки",
      key: "id",
      dataIndex: "id",
      withoutSorter: false,
      width: 70,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div className="HistoryTableCourseNumber HistoryTableClubNumber">
          {text}
        </div>
      )
    },
    ...hallColumnForAdmin,
    {
      title: "Тип обучения",
      key: "course_id",
      dataIndex: "course_id",
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (courseId: number | null) => {
        const currentCourse = course.find(item =>
          item.id === courseId ? item : null
        )
        return (
          <div>
            <div className="HistoryTableCourseLabel">
              {currentCourse ? currentCourse.name : ``}
            </div>
            <div className="HistoryTableCourseDescription">
              {currentCourse ? `(${currentCourse.description})` : ``}
            </div>
          </div>
        )
      }
    },
    {
      title: "Даты",
      key: "dates",
      dataIndex: "dates",
      withoutSorter: true,
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (
        date: number[] | null,
        record: { date_start: number | null; date_end: number | null }
      ) => (
        <div>
          {record.date_start && record.date_end
            ? `${moment(record.date_start).format(`DD.MM`)} — ${moment
                .utc(record.date_end)
                .format(`DD.MM.YYYY`)}`
            : ``}
        </div>
      )
    },
    {
      title: "Статус",
      key: "status_id",
      dataIndex: "status_id",
      withoutSorter: true,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (statusId: number | null, record) => {
        const currentStatus = status.find(item =>
          item.id === statusId ? item : null
        )
        const editableStatus =
          !!currentStatus && currentStatus.id === statuses.validation
        return (
          <div
            onClick={() => {
              if (editableStatus) {
                toggleStatusModal(record.id)
              }
            }}
          >
            {currentStatus && (
              <StatusComponent
                id={currentStatus.id}
                label={currentStatus.name || ``}
                color={currentStatus.color || ``}
              />
            )}
          </div>
        )
      }
    },
    {
      title: "Сотрудник",
      key: "employee_name",
      dataIndex: "employee_name",
      withoutSorter: false,
      width: 120
    },
    ...dateHiredColumnForAdmin,
    {
      title: "Результат",
      key: "result",
      dataIndex: "result",
      withoutSorter: true,
      width: 60
    },
    {
      title: "Комментарий",
      key: "comment",
      dataIndex: "comment",
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div className="HistoryTableCourseComment">{text}</div>
      )
    },
    {
      title: "Автор",
      key: "created_by",
      dataIndex: "created_by",
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (
        created_by: string,
        record: { history: HistoryModalData[]; id: number }
      ) => {
        const haveHistory = !!record?.history?.length
        return (
          <div className="FlexRow">
            {haveHistory && (
              <div
                className="HistoryTableAuthor FlexRow"
                onClick={() =>
                  toggleHistoryModal({
                    isOpen: true,
                    data: record.history || [],
                    id: record.id
                  })
                }
              >
                <HistoryIcon />
              </div>
            )}
            <div className="HistoryTableAuthorName">{created_by}</div>
          </div>
        )
      }
    },
    {
      title: "",
      key: "more",
      dataIndex: "more",
      withoutSorter: true,
      fixed: "right",
      width: 25,
      // eslint-disable-next-line react/display-name
      render: (
        text: number | null,
        record: {
          status_id: number | null
          id: number
          date_start: number | null
          date_end: number
        }
      ) => {
        const { status_id, id, date_start, date_end } = record
        const isEditVisible = !isAdmin
          ? status_id === statuses.agreement || status_id === statuses.approved
          : null
        const Content = (
          <div className="MorePopoverWrapper">
            <div
              className="MorePopoverItem"
              onClick={() => {
                if (
                  status_id === 4 ||
                  (status_id === statuses.approved && isAdmin)
                ) {
                  history.push(`history/${id}`)
                } else {
                  toggleMoreModal(id)
                }
              }}
            >
              <div className="MorePopoverItemIcon">
                <MoreIcon />
              </div>
              <div className="MorePopoverItemName">Подробнее</div>
            </div>
            {isEditVisible && (
              <div
                className="MorePopoverItem"
                onClick={() => {
                  toggleEditModal(id)
                }}
              >
                <div className="MorePopoverItemIcon">
                  <EditIcon />
                </div>
                <div className="MorePopoverItemName">Редактировать</div>
              </div>
            )}
            {isAdmin && status_id === statuses.agreement && date_start && (
              <div
                className="MorePopoverItem"
                onClick={() => {
                  toggleCancelModal({
                    active: true,
                    status: statuses.approved,
                    id,
                    isAdmin
                  })
                }}
              >
                <div className="MorePopoverItemIcon">
                  <ApprovedIcon />
                </div>
                <div className="MorePopoverItemName">Одобрить</div>
              </div>
            )}
            {status_id !== statuses.completed && status_id !== statuses.cancel && (
              <div
                className="MorePopoverItem"
                onClick={() => {
                  toggleCancelModal({
                    active: true,
                    status: statuses.cancel,
                    id,
                    isAdmin
                  })
                }}
              >
                <div className="MorePopoverItemIcon">
                  <BlockIcon />
                </div>
                <div className="MorePopoverItemName">Отменить</div>
              </div>
            )}
          </div>
        )
        return (
          <Popover placement="left" content={Content} trigger="click">
            <div className="HistoryTableMoreWrapper">...</div>
          </Popover>
        )
      }
    }
  ]
}

const DownloadComponent = ({ tableFilter }: { tableFilter: TableFilter }) => {
  const [downloadPending, loadSetPending] = useState(false)
  const { history, developer, addDeveloperLink, logout } = useRequestParams()
  return (
    <div style={{ paddingRight: `16px`, marginTop: `20px` }}>
      <ButtonDefault
        disabled={downloadPending}
        size="small"
        onClick={() =>
          downloadFile({
            logout,
            history,
            source: `applications`,
            method: `POST`,
            loadSetPending,
            requestBody: { filter: tableFilter, download: 1 },
            developer,
            addDeveloperLink
          })
        }
      >
        <div className="HistoryEventsLeftItemButtonIconWrapper">
          <ExelIcon />
        </div>
        Выгрузить в Excel
      </ButtonDefault>
    </div>
  )
}

export default DownloadComponent
