import React, { useEffect } from "react"
import { useHistory } from "react-router-dom"
import { ButtonPrimary } from "Components/Button"
import { ReactComponent as ErrorImage } from "Components/icons/error_404.svg"
import { ReactComponent as ServerErrorImage } from "Components/icons/error_500.svg"
import { HISTORY_PAGE } from "Containers/BaseLayout/constants"

export const NotExistPage = () => {
  const history = useHistory()
  const redirectToHistory = () => history.push(HISTORY_PAGE)
  useEffect(() => {
    if (!localStorage.getItem(`error`)) {
      redirectToHistory()
    }
  })
  return (
    <div className="ErrorPageWrapper">
      <div className="ErrorContentWrapper">
        <div className="IconWrapperSvg">
          <ErrorImage />
        </div>
        <h1>Ошибка 404</h1>
        <div
          style={{ width: `480px`, textAlign: `center`, paddingBottom: `24px` }}
        >
          Вы видите эту страницу потому что, что-то пошло не по плану :( Мы
          постараемся все починить в ближайшее время.
        </div>
        <div>
          <ButtonPrimary disabled={false} onClick={redirectToHistory}>
            Вернуться на главную
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export const ServerErrorPage = () => {
  const history = useHistory()
  const redirectToHistory = () => history.push(HISTORY_PAGE)
  useEffect(() => {
    if (!localStorage.getItem(`error`)) {
      redirectToHistory()
    }
  })
  return (
    <>
      <div className="ErrorPageWrapper">
        <div className="ErrorContentWrapper">
          <div className="IconWrapperSvg">
            <ServerErrorImage />
          </div>
          <h1>Ошибка 500</h1>
          <div
            style={{
              width: `480px`,
              textAlign: `center`,
              paddingBottom: `24px`
            }}
          >
            Кто-то пролил кофе на клавиатуру и у нас сломался сервер. Сервис
            временно недоступен. Приходите позже.
          </div>
        </div>
      </div>
    </>
  )
}
