import React, { useCallback, useState } from "react"

import { archiveLabel, hideLabel } from "../constants"
import { CategoryProps } from "../types"

import {
  HideIcon,
  ArchiveIcon,
  ArchiveBigIcon,
  HideDescriptionIcon
} from "../icons"
import { ReactComponent as ExpandIcon } from "Components/icons/chevron-down.svg"
import { ReactComponent as CalendarLockIcon } from "Components/icons/calendar_lock.svg"

import OpenCategory from "./OpenCategory"

const Category = ({
  data,
  isOpen,
  open,
  canEdit,
  refresh,
  toggleEditModal
}: CategoryProps) => {
  const [isChart, toggleChart] = useState(!!data.is_graph_visible)

  const toggleChartCb = useCallback(
    val => {
      toggleChart(val)
    },
    [isChart]
  )

  const {
    visible: isVisible,
    id: idCurrent,
    archive: isArchive,
    is_graph_visible: isGraph,
    name,
    duration,
    description
  } = data
  return (
    <div className={isOpen ? "InfoItem InfoItem-open" : "InfoItem"}>
      <div
        onClick={() => open(isOpen ? null : idCurrent)}
        className="InfoItem-header"
      >
        {isArchive && (
          <div className="InfoItem-hide">
            <ArchiveBigIcon />
          </div>
        )}
        {!isArchive && !isVisible && (
          <div className="InfoItem-hide">
            <HideIcon />
          </div>
        )}
        {isVisible && !isArchive && (
          <div className="InfoItem-bg">{`${duration} дня`}</div>
        )}
        <div className="FlexRow">
          <div className="InfoItemTitle">
            <span>{name}</span>
            <div className="InfoItem-description FlexRow">
              <span>{description}</span>
              {!isChart && (
                <div className="InfoItemCalendarLock">
                  <CalendarLockIcon />
                </div>
              )}
            </div>
          </div>
          {isArchive && (
            <div className="FlexRow InfoItemArchiveLabel">
              <ArchiveIcon />
              <span>{archiveLabel}</span>
            </div>
          )}
          {!isArchive && !isVisible && (
            <div className="FlexRow InfoItemArchiveLabel">
              <HideDescriptionIcon />
              <span>{hideLabel}</span>
            </div>
          )}
        </div>
        <div className="InfoItem-icon">
          {isOpen ? <ExpandIcon /> : <ExpandIcon />}
        </div>
      </div>
      {isOpen ? (
        <div className="InfoItem-body">
          <OpenCategory
            data={data}
            open={open}
            isOpen={isOpen}
            isChart={isChart}
            toggleChart={toggleChartCb}
            canEdit={canEdit}
            refresh={refresh}
            toggleEditModal={toggleEditModal}
          />
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default Category
