export const ModalText = {
  success: {
    primary: "Заявка отправлена!",
    secondary:
      "Информация по срокам проведения обучения\n будет после формирования группы"
  },
  error: {
    primary: "Ошибка"
  },
  button: "К списку заявок"
}
