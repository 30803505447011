import React from "react"
import superman from "Components/icons/superman.svg"
import robin from "Components/icons/robin.svg"
import batman from "Components/icons/batman.svg"
import green from "Components/icons/green.svg"
import flash from "Components/icons/flash.svg"
import wonder from "Components/icons/wonder.svg"
import { Popover } from "antd"
import { IRootState } from "store"
import { connect } from "react-redux"
import { StoreDevelopState } from "store/developer/types"

interface IDeveloperComponent {
  devIcon: number
  developer: StoreDevelopState
}

const DeveloperComponent = ({ devIcon, developer }: IDeveloperComponent) => {
  const heroIcon = [batman, superman, green, robin, flash, wonder]

  const devLinks = Object.entries(developer).map(item => item[1]) || []

  return (
    <div className="DeveloperProfileWrapper">
      <Popover
        content={
          <div className="DeveloperProfileLinksWrapper">
            {devLinks[0] ? (
              devLinks.map(item => (
                <div key={`${item.url}_profile`} className="DeveloperLinksItem">
                  <a href={item.link} target="_blank" rel="noreferrer">
                    <div>
                      Запрос: <span>{item.url}</span>
                    </div>
                  </a>
                </div>
              ))
            ) : (
              <div>Нет записей</div>
            )}
          </div>
        }
        placement="topLeft"
        trigger="click"
      >
        <div
          style={{
            width: `48px`,
            height: `48px`,
            background: `url(${heroIcon[devIcon]}) center center no-repeat`,
            backgroundSize: `cover`
          }}
        />
      </Popover>
    </div>
  )
}

const connector = connect((state: IRootState) => ({
  developer: state.developer
}))

export default connector(DeveloperComponent)
