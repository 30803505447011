import React from "react"
import { Input, InputNumber } from "antd"
import { InputProps, TextAreaProps } from "antd/lib/input"
import { InputNumberProps } from "antd/lib"

interface IInputComponent extends InputProps {
  placeholder: string
  error?: boolean | string
  rest?: any
  ref?: any
}

interface ITextArea extends TextAreaProps {
  placeholder: string
  error?: boolean | string
  rest?: any
}

const InputComponent = ({
  placeholder,
  error = false,
  ref,
  ...rest
}: IInputComponent) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input placeholder={placeholder} {...rest} ref={ref} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputTextAreaComponent = ({
  error,
  placeholder,
  ...rest
}: ITextArea) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input.TextArea placeholder={placeholder} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const InputPasswordComponent = ({
  placeholder,
  error = false,
  ...rest
}: IInputComponent) => (
  <div className={`InputWrapper ${error ? `ErrorInputWrapper` : ``}`}>
    <Input.Password placeholder={placeholder} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

interface IInputNumber extends InputNumberProps {
  rest?: any
}

export const InputNumberComponent = ({ ...rest }: IInputNumber) => (
  <div className="InputWrapper">
    <InputNumber {...rest} />
  </div>
)
export default InputComponent
