export const dateFormatForTitle = `DD.MM.YYYY в HH:mm`
export const dateFormatForKey = `DD.MM_HH:mm`

export const labels = { comment: `Комментарий:` }

// 0 | 1 | 2 | 3 (соответственно 0 - создал, 1 - изменил, 2 - одобрил, 3 - отменил)

export const getStatusData = (id: number) => {
  switch (id) {
    case 0:
      return { color: `#EBFBEE`, title: `Создал` }
    case 1:
      return { color: `#FFF4E6`, title: `Изменил` }
    case 2:
      return { color: `rgba(0, 122, 255, 0.1)`, title: `Одобрил` }
    case 3:
      return { color: `rgba(255, 59, 48, 0.1)`, title: `Отменил` }
    default:
      return { color: `#FFF4E6`, title: `Изменил` }
  }
}
