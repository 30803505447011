import { StoreUserState } from "./types"
import { LOGIN, LOGOUT } from "./constants"
const init: StoreUserState = {
  fullName: null,
  token: null,
  role: null,
  isAuth: !!localStorage.getItem(`user`) || false,
  hall: null,
  halls: [],
  isAdmin: false
}

export function authReducer(
  state: StoreUserState = init,
  action: { type: string; payload: StoreUserState }
): StoreUserState {
  const { type, payload } = action

  switch (type) {
    case LOGIN: {
      return { ...state, ...payload }
    }

    case LOGOUT: {
      localStorage.removeItem(`error`)
      localStorage.removeItem(`role`)
      localStorage.clear()
      localStorage.setItem(`developer`, ``)
      localStorage.setItem(`user`, ``)
      return { ...init, isAuth: false }
    }

    default:
      return state
  }
}
