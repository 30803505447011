import { CheckModalState } from "./types"

export const initialData: CheckModalState = {
  new: {
    title: ``,
    hall: ``,
    employee_name: ``,
    phone: ``,
    snils: ``
  },
  old: {
    title: ``,
    hall: ``,
    employee_name: ``,
    phone: ``,
    snils: ``
  },
  pending: false
}

export const getRequestUrl = (open: number) =>
  `admin/application/validate/${open}`
