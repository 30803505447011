import { combineReducers, createStore } from "redux"
import { authReducer } from "./auth/reducer"
import { developerReducer } from "./developer/reducer"
import { dictionaryReducer } from "./dictionary/reducer"
import { StoreUserState } from "./auth/types"
import { StoreDictionary } from "./dictionary/types"
import { composeWithDevTools } from "redux-devtools-extension"
import { StoreDevelopState } from "./developer/types"
export interface IRootState {
  auth: StoreUserState
  dictionary: StoreDictionary
  developer: StoreDevelopState
}
const store = createStore<IRootState, any, any, any>(
  combineReducers({
    auth: authReducer,
    dictionary: dictionaryReducer,
    developer: developerReducer
  }),
  composeWithDevTools()
)
export default store
