import React, { useEffect } from "react"
import { connect, useDispatch } from "react-redux"
import { IRootState } from "store"
import { IDate, PanelState } from "Containers/CreateComponent"
import { ReactComponent as CalendarLockIcon } from "Components/icons/calendar_lock.svg"
import { StoreDictionaryCourse } from "store/dictionary/types"
import { useDictionaryMethods } from "../../BaseLayout/helper"
import { storeDictionaryList } from "store/dictionary/actions"

interface ICourse extends IDate {
  initialPanelState: PanelState
  course: Array<StoreDictionaryCourse>
}

const Course = ({
  course,
  initialPanelState,
  panelState,
  changePanelState
}: ICourse): JSX.Element => {
  const dispatch = useDispatch()
  const { getCourseForDictionaryReq } = useDictionaryMethods()

  const storeDictionary: (data: Array<StoreDictionaryCourse>) => void = data =>
    dispatch(storeDictionaryList(data))

  useEffect(() => {
    getCourseForDictionaryReq().then(resp => {
      storeDictionary(resp.data)
    })
  }, [])
  return (
    <div>
      <div className="CreateModalTitle">Выберите тип обучения:</div>
      <div className="CourseWrapper">
        {course.map(item => {
          return (
            <div
              key={`${item.name}_${item.id}`}
              className={`CourseItem ${
                panelState.course.id === item.id ? `CourseItemActive` : ``
              }`}
              onClick={() => {
                if (item.id !== panelState.course.id) {
                  changePanelState({ ...initialPanelState, course: item })
                }
              }}
            >
              <div className="CourseItemInfo">
                <div className="CourseItemLabel">{item.name}</div>
                <div className="CourseItemDescription">{`(${item.description})`}</div>
              </div>
              <div className="CourseItemDays">
                <div className="CourseItemDaysLabel">
                  {!item.graph && <CalendarLockIcon />}
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

const connector = connect((state: IRootState) => ({
  course: state.dictionary.course
}))

export default connector(Course)
