import BetRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import { IMoreModal } from "Containers/History/MoreModal"
import { StoreDevelopState } from "store/developer/types"
import { IGraphList } from "./index"

import { errorCanvas } from "Components/Messages"

type GraphDataType = {
  data: Array<IGraphList>
  pending: boolean
}

type getRequestInfoType = {
  id: number | null
  logout: () => void
  history: RouteComponentProps["history"]
  changeData: (data: IMoreModal) => void
  requestData: IMoreModal
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

type getAdminCourseType = {
  id: number | null
  logout: () => void
  history: RouteComponentProps["history"]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
  graphList: GraphDataType
  setGraphList: (data: GraphDataType) => void
}

export const getAdminCourse = ({
  id,
  logout,
  history,
  graphList,
  developer,
  addDeveloperLink,
  setGraphList
}: getAdminCourseType) => {
  setGraphList({ ...graphList, data: graphList.data, pending: true })
  BetRequest({
    url: `admin/course/graphs`,
    developer,
    addDeveloperLink,
    method: `POST`,
    requestBody: { course_id: id },
    history,
    logout
  })
    .then(({ data }) => setGraphList({ ...graphList, data, pending: true }))
    .catch(({ response }) => {
      errorCanvas({
        errors: response.data.errors,
        description: response.data.description
      })
    })
}

const getRequestInfo = ({
  id,
  logout,
  history,
  changeData,
  requestData,
  developer,
  addDeveloperLink
}: getRequestInfoType) => {
  changeData({ ...requestData, pending: true })
  BetRequest({
    url: `application/${id}`,
    addDeveloperLink,
    developer,
    method: `GET`,
    history,
    logout
  })
    .then(({ data }) =>
      changeData({
        ...requestData,
        data,
        pending: false
      })
    )
    .catch(() => changeData({ ...requestData, pending: false }))
}

export default getRequestInfo
