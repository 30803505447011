export const checkNumber = (str?: string) => /^[0-9]+$/.test(str || ``)
export const checkPhone = (str?: string) => {
  const regex = /^[+]*[0-9]+$/
  return regex.test(str || ``)
}

const wordsByNumber = (value: number, words: Array<string>) => {
  if (value > 4 && value < 20) return words[2]
  if (value > 1 && value < 5) return words[1]
  if (value == 1) return words[0]
}

const roles = {
  partner: `ROLE_PARTNER`,
  training: `ROLE_TRAINING`,
  developer: `ROLE_DEVELOPER`,
  control: `ROLE_CONTROL`
}

export const userFromStorage = () => {
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user:
    | { token: string; hall: number; role: string }
    | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage

  const isPartner = user && user.role === roles.partner
  const isControl = user && user.role === roles.control

  return { user, isControl, isPartner }
}

export default wordsByNumber
