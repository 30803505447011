import React from "react"
import {
  YMaps,
  Map,
  Placemark,
  FullscreenControl,
  RouteButton,
  GeolocationControl,
  ZoomControl
} from "react-yandex-maps"

import { ReactComponent as PhoneIcon } from "Components/icons/phone-call-m.svg"
import { ReactComponent as MapIcon } from "Components/icons/map-icon.svg"

import img2 from "Components/icons/image2.jpg"
import img3 from "Components/icons/image3.jpg"

import { howToGetConstants } from "./constant"

const InfoRoute = () => (
  <div className="HowToGetWrapper">
    <div className="HowToGetWrapper-header">
      <h1>{howToGetConstants.title}</h1>
    </div>
    <div className="HowToGetWrapper-body">
      <div className="HowToGetWrapper-icon">
        <MapIcon />
        <span>
          <b>{howToGetConstants.address.label}</b>{" "}
          {howToGetConstants.address.text}
        </span>
      </div>
      <div className="HowToGetWrapper-map">
        <YMaps>
          <Map
            defaultState={howToGetConstants.map.defaultState}
            width={howToGetConstants.map.width}
            height={howToGetConstants.map.height}
          >
            <ZoomControl options={howToGetConstants.map.zoomControl} />
            <Placemark geometry={howToGetConstants.map.geometry} />
            <FullscreenControl />
            <RouteButton options={howToGetConstants.map.routeButton} />
            <GeolocationControl
              options={howToGetConstants.map.geolocationControl}
            />
          </Map>
        </YMaps>
      </div>
      <div className="HowToGetWrapper-row">
        {howToGetConstants.text.part_one}
      </div>
      <div className="HowToGetWrapper-row HowToGetWrapper-row-desc">
        <div className="HowToGetWrapper-cell">
          <p>{howToGetConstants.text.part_two}</p>
          <br />
          <p>{howToGetConstants.text.part_three}</p>
        </div>
        <div className="HowToGetWrapper-cell">
          <div className="HowToGetWrapper-img">
            <img
              alt={howToGetConstants.img_description}
              src={img2}
              style={{ height: `240px` }}
            />
          </div>
          <div className="HowToGetWrapper-img">
            <img
              alt={howToGetConstants.img_description}
              src={img3}
              style={{ height: `240px` }}
            />
          </div>
        </div>
      </div>
      <div className="HowToGetWrapper-icon">
        <PhoneIcon />
        <span>
          <b>{howToGetConstants.contacts.label}</b>
        </span>
      </div>
      <div className="HowToGetWrapper-col">
        <p>
          {howToGetConstants.contacts.call_center.text}
          <a href={howToGetConstants.contacts.call_center.href}>
            {howToGetConstants.contacts.call_center.phone}
          </a>
        </p>
        <p>
          {howToGetConstants.contacts.mail.label}
          <a href={howToGetConstants.contacts.mail.href}>
            {howToGetConstants.contacts.mail.value}
          </a>
        </p>
        <p>
          {howToGetConstants.contacts.manager.text}
          <a href={howToGetConstants.contacts.manager.href}>
            {howToGetConstants.contacts.manager.phone}
          </a>
        </p>
      </div>
    </div>
  </div>
)

export default InfoRoute
