import React, { useEffect } from "react"

import { ReactComponent as SuccessIcon } from "./success.svg"

const SuccessModal = ({
  close,
  isNew
}: {
  close: () => void
  isNew: boolean
}) => {
  useEffect(() => {
    let waitForIt
    if (!!close) {
      clearTimeout(waitForIt)
      waitForIt = setTimeout(() => close(), 2000)
    }
  }, [])
  const text = isNew ? `Курс создан` : `Курс отредактирован`
  return (
    <div className="CreateGraphModalSuccessWrapper">
      <SuccessIcon />
      <div className="CreateGraphModalSuccessTitle">{text}</div>
    </div>
  )
}

export default SuccessModal
