import React, { useState } from "react"
import TableComponent from "Components/Table"
import InputComponent from "Components/Input"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import {
  IEmployeeData,
  getColumns
} from "Containers/History/EditModal/EditEmployee/helper"
import { IEditData } from "Containers/History/EditModal"
import { Columns } from "Components/Table/types"

type EditEmployeeType = {
  employees: {
    list: number[]
    data: IEmployeeData[]
  }
  initialData: IEditData
  changeInitialData: (data: IEditData) => void
}

const EditEmployee = ({
  employees: { list, data },
  initialData,
  changeInitialData
}: EditEmployeeType) => {
  const [employeeSearch, changeEmployeeSearch] = useState<{
    name: string | undefined
  }>({ name: undefined })
  const columns: Array<Columns> = getColumns(initialData, changeInitialData)
  return (
    <div>
      <div className="EditEmployeeSearchWrapper">
        <InputComponent
          allowClear
          value={employeeSearch.name}
          placeholder="Поиск сотрудника"
          suffix={<SearchIcon />}
          onChange={({ target: { value } }) => {
            changeEmployeeSearch({ name: value })
          }}
        />
      </div>
      <div className="EditEmployeeTableWrapper">
        <TableComponent
          dataSource={`employees`}
          columnsData={columns}
          scrollX={columns.length * 150}
          scrollY={`calc(100vh - 500px)`}
          classNameForPagination="EmployeeTablePaginationWrapper"
          scrollWithoutPagination={true}
          withHall
          filter={employeeSearch}
        />
      </div>
    </div>
  )
}

export default EditEmployee
