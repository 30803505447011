import { action } from "typesafe-actions"
import { STORE_COURSE_LIST, GET_DICTIONARY } from "./constants"
import { StoreDictionary, StoreDictionaryCourse } from "./types"

export const getDictionary = (data: StoreDictionary) => {
  return action(GET_DICTIONARY, data)
}

export const storeDictionaryList = (data: Array<StoreDictionaryCourse>) => {
  return action(STORE_COURSE_LIST, data)
}
