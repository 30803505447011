import { StoreDictionary } from "./types"
import { GET_DICTIONARY, STORE_COURSE_LIST } from "./constants"
const init: StoreDictionary = {
  status: [],
  course: []
}

export function dictionaryReducer(
  state: StoreDictionary = init,
  action: { type: string; payload: StoreDictionary }
): StoreDictionary {
  const { type, payload } = action
  switch (type) {
    case GET_DICTIONARY: {
      return { ...state, ...payload }
    }
    case STORE_COURSE_LIST: {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      //@ts-ignore
      return { ...state, course: payload }
    }
    default:
      return state
  }
}
