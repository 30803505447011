/* eslint react/prop-types: "off" */
import React from "react"
import { Button } from "antd"

export const ButtonPrimary = ({ children, disabled, ...rest }) => {
  return (
    <div className="ButtonPrimaryWrapper">
      <Button
        type="primary"
        disabled={disabled}
        className={disabled ? `ButtonPrimaryDisabled` : `ButtonPrimary`}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonCancel = ({ children, disabled, ...rest }) => {
  return (
    <div className="ButtonCancelWrapper">
      <Button
        disabled={disabled}
        className={disabled ? `ButtonCancelDisabled` : `ButtonCancel`}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonFade = ({ children, disabled, color, ...rest }) => {
  const isRed = color === `red`
  return (
    <div className={`ButtonFadeWrapper ${isRed ? `ButtonFadeRedWrapper` : ``}`}>
      <Button
        className={
          disabled
            ? `${isRed ? `ButtonFadeRedDisabled` : `ButtonFadeDisabled`}`
            : `${isRed ? `ButtonFadeRed` : `ButtonFade`}`
        }
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export const ButtonDefault = ({ children, disabled, ...rest }) => {
  return (
    <div className="ButtonDefaultWrapper">
      <Button
        className={disabled ? `ButtonDefaultDisabled` : `ButtonDefault`}
        disabled={disabled}
        {...rest}
      >
        {children}
      </Button>
    </div>
  )
}

export default ButtonDefault
