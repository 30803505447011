import {
  StoreDictionary,
  StoreDictionaryCourse,
  StoreDictionaryStatus
} from "./types"
import { IRootState } from "../index"

export const getDictionary = (state: IRootState): StoreDictionary =>
  state.dictionary

export const getDictionaryCourse = (
  state: IRootState
): StoreDictionaryCourse[] => state.dictionary.course

export const getDictionaryStatus = (
  state: IRootState
): StoreDictionaryStatus[] => state.dictionary.status
