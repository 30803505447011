import React, { useMemo, useState } from "react"

import { useRequest } from "api/hooks"

import { ButtonFade, ButtonPrimary } from "Components/Button"
import { CheckBoxComponent } from "Components/CheckBox"

import { ReactComponent as TimeIcon } from "Components/icons/time-icon.svg"
import { ReactComponent as RubIcon } from "Components/icons/rub-icon.svg"

import ApproveModal from "../Approve"

import {
  EditIcon,
  ArchiveIcon,
  UnzipIcon,
  ShowButtonIcon,
  HideButtonIcon
} from "../icons"
import {
  ApproveModalState,
  ApproveModalTypes,
  CategoryProps,
  FormatItem
} from "../types"

import {
  archiveApproveText,
  archiveUrl,
  chartToggleUrl,
  textOpenCategory,
  visibleApproveText,
  visibleUrl
} from "../helper"

const OpenCategory = ({
  data,
  open,
  canEdit,
  refresh,
  toggleEditModal,
  isChart,
  toggleChart
}: CategoryProps) => {
  const {
    id: idCurrent,
    visible: isVisible,
    can_edit: isEditable,
    archive: isArchive,
    is_graph_visible,
    lesson_start,
    lesson_assemble,
    format,
    lesson_format_description,
    lesson_duration,
    days
  } = data

  const [approveModal, toggleApprove] = useState<ApproveModalState>({
    isOpen: false,
    type: null
  })
  const visUrl = useMemo(() => visibleUrl(idCurrent), [idCurrent])
  const archUrl = useMemo(() => archiveUrl(idCurrent), [idCurrent])
  const chartUrl = useMemo(() => chartToggleUrl(idCurrent), [idCurrent])

  const { request: toggleVisible } = useRequest({
    url: visUrl,
    requestBody: { visible: !isVisible },
    method: "PUT"
  })
  const { request: toggleArchive } = useRequest({
    url: archUrl,
    requestBody: { archive: !isArchive },
    method: "PUT"
  })
  const { request: toggleChartReq } = useRequest({
    url: chartUrl,
    requestBody: { is_graph_visible: !isChart },
    method: "PUT"
  })

  const approveModalData = useMemo(
    () =>
      approveModal.type === ApproveModalTypes.visible
        ? visibleApproveText(isVisible)
        : archiveApproveText(isArchive),
    [approveModal.type]
  )

  return (
    <div>
      <div className="withIcon">
        <TimeIcon />
        <div>
          {textOpenCategory.getLessonDuration(lesson_duration)}
          {textOpenCategory.getTime(lesson_start, lesson_assemble)}
        </div>
      </div>
      <div className="withIcon">
        <RubIcon />
        <b>{textOpenCategory.free}</b>
      </div>
      {!!lesson_format_description && (
        <div>
          <div className="withIcon">
            {textOpenCategory.format[format].icon}
            <b>{textOpenCategory.format[format].label}</b>
          </div>
          <div>
            <span
              className="EditorTextWrapper"
              dangerouslySetInnerHTML={{
                __html: lesson_format_description
              }}
            />
          </div>
        </div>
      )}
      <div>
        {days.map((day, key) => (
          <div key={`course_day_data_${key}`}>
            <p className="highlight">{textOpenCategory.getDay(key + 1)}</p>
            <span
              className="EditorTextWrapper"
              dangerouslySetInnerHTML={{
                __html: day
              }}
            />
          </div>
        ))}
      </div>
      {
        <CheckBoxComponent
          label="Даты в планировщике"
          checked={isChart}
          onChange={({ target: { checked } }: { target: { checked: any } }) => {
            toggleChart && toggleChart(checked)
            toggleChartReq().catch(() => toggleChart && toggleChart(!checked))
          }}
        />
      }
      {canEdit && !isArchive && (
        <div className="FlexRow OpenCategoryButtonsWrapper">
          <div className="OpenCategoryVisibleButtons">
            {!isVisible ? (
              <ButtonPrimary
                disabled={false}
                onClick={() =>
                  toggleApprove({
                    type: ApproveModalTypes.visible,
                    isOpen: true
                  })
                }
              >
                <div className="FlexRow OpenCategoryButton">
                  <div className="OpenCategoryButtonsIcon">
                    <ShowButtonIcon />
                  </div>
                  <span>{textOpenCategory.open}</span>
                </div>
              </ButtonPrimary>
            ) : (
              <ButtonFade
                color="grey"
                disabled={false}
                onClick={() =>
                  toggleApprove({
                    type: ApproveModalTypes.visible,
                    isOpen: true
                  })
                }
              >
                <div className="FlexRow OpenCategoryButton">
                  <div className="OpenCategoryButtonsIcon">
                    <HideButtonIcon />
                  </div>
                  <span>{textOpenCategory.close}</span>
                </div>
              </ButtonFade>
            )}
          </div>
          <ButtonFade color="grey" disabled={!isEditable}>
            <div
              className="FlexRow OpenCategoryButtonEdit"
              onClick={() =>
                toggleEditModal({
                  isOpen: true,
                  initial: data,
                  refreshList: refresh
                })
              }
            >
              <div className="OpenCategoryButtonsIcon">
                <EditIcon />
              </div>
              <span>{textOpenCategory.edit}</span>
            </div>
          </ButtonFade>
          <ButtonFade
            color="red"
            disabled={false}
            onClick={() =>
              toggleApprove({
                type: ApproveModalTypes.archive,
                isOpen: true
              })
            }
          >
            <div className="FlexRow OpenCategoryButtonEdit">
              <div className="OpenCategoryButtonsIcon">
                <ArchiveIcon />
              </div>
              <span>{textOpenCategory.archive}</span>
            </div>
          </ButtonFade>
        </div>
      )}
      {isArchive && (
        <div className="OpenCategoryButtonsWrapper">
          <ButtonPrimary
            disabled={false}
            onClick={() =>
              toggleApprove({
                type: ApproveModalTypes.archive,
                isOpen: true
              })
            }
          >
            <div className="FlexRow OpenCategoryButtonUnzip">
              <div className="OpenCategoryButtonsIcon">
                <UnzipIcon />
              </div>
              <span>{textOpenCategory.unzip}</span>
            </div>
          </ButtonPrimary>
        </div>
      )}
      {approveModal.isOpen && (
        <ApproveModal
          approve={(success: () => void) =>
            (approveModal.type === ApproveModalTypes.visible
              ? toggleVisible()
              : toggleArchive()
            ).then(() => success())
          }
          refresh={refresh}
          close={() => open(null)}
          cancel={() => toggleApprove({ ...approveModal, isOpen: false })}
          data={approveModalData}
        />
      )}
    </div>
  )
}

export default OpenCategory
