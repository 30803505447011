import React, { useEffect, useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { connect } from "react-redux"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { LOGOUT } from "store/auth/constants"
import getInitialData, { saveRequestData } from "./helper"
import {
  StoreDictionaryCourse,
  StoreDictionaryStatus
} from "store/dictionary/types"
import EditEmployee from "./EditEmployee"
import EditDate from "./EditDate"
import SuccessEdit from "./SuccessEdit"
import { IEmployeeData } from "Containers/CreateComponent/Employee/helper"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import { IRootState } from "store"
import { StoreDevelopState } from "store/developer/types"
import { ADD_LINK } from "store/developer/constants"

type EditComponentProps = {
  id: number | boolean
  close: () => void
  refresh: () => void
  logout: () => void
  status: StoreDictionaryStatus[]
  course: StoreDictionaryCourse[]
  history: RouteComponentProps["history"]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export interface IEditData {
  pending: boolean
  employees: {
    list: number[]
    data: IEmployeeData[]
  }
  graph_id: number | null
  date_end: number | null
  date_start: number | null
  students: number | null
  status_id: number | null
  course_id: number | null
}

const EditComponent = ({
  id,
  close,
  history,
  refresh,
  logout,
  status,
  course,
  developer,
  addDeveloperLink
}: EditComponentProps) => {
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user:
    | { token: string; hall: number; role: string }
    | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  const isAdmin = !!user && user.role === `ROLE_TRAINING`

  const [initialData, changeInitialData] = useState<IEditData>({
    pending: false,
    employees: { list: [], data: [] },
    graph_id: null,
    date_end: null,
    date_start: null,
    students: null,
    status_id: null,
    course_id: null
  })

  useEffect(() => {
    getInitialData({
      id,
      history,
      logout,
      initialData,
      changeInitialData,
      developer,
      addDeveloperLink
    })
  }, [id])
  const currentStatus = status.find(item => item.id === initialData.status_id)
  const currentCourse = course.find(item => item.id === initialData.course_id)
  const [tabState, setTabState] = useState<boolean>(isAdmin)
  const [disabledButton, setDisabledButton] = useState(false)

  const [successOpen, toggleSuccess] = useState(false)
  const isDateActive = Boolean(
    currentStatus &&
      currentStatus.id !== 2 &&
      currentCourse &&
      currentCourse.graph
  )

  return (
    <div>
      <div className="ModalOverflow" />
      <div className="EditComponentModalWrapper">
        {!successOpen ? (
          <div className="EditComponentModalContentWrapper">
            <div className="EditComponentModalHeader">
              <div className="EditComponentModalHeaderTitle">
                Редактирование заявки
              </div>
              <div className="EditComponentModalHeaderClose" onClick={close}>
                <CloseIcon />
              </div>
            </div>
            {isDateActive && !isAdmin && (
              <div className="EditComponentTabsWrapper">
                <div
                  className={`EditComponentTabsItem ${
                    tabState ? `EditComponentTabsActiveItem` : ``
                  } ${!isDateActive ? `EditComponentTabsDisabledItem` : ``}`}
                  onClick={() => {
                    if (isDateActive) setTabState(true)
                  }}
                >
                  Даты
                </div>
                <div
                  className={`EditComponentTabsItem ${
                    !tabState ? `EditComponentTabsActiveItem` : ``
                  }`}
                  onClick={() => setTabState(false)}
                >
                  Сотрудники
                </div>
              </div>
            )}
            {!isDateActive && !isAdmin && (
              <div className="EditComponentTitleWithoutDate">
                {!isDateActive
                  ? `Вы можете убрать или заменить ранее заявленных сотрудников`
                  : `Добавьте или удалите сотрудников для обучения`}
              </div>
            )}
            <div>
              {tabState ? (
                <EditDate
                  logout={logout}
                  history={history}
                  initialData={initialData}
                  changeInitialData={changeInitialData}
                  currentCourse={currentCourse}
                  developer={developer}
                  addDeveloperLink={addDeveloperLink}
                />
              ) : (
                <EditEmployee
                  employees={initialData.employees}
                  initialData={initialData}
                  changeInitialData={changeInitialData}
                />
              )}
            </div>
            <div className="EditComponentModalFooterWrapper">
              {!tabState ? (
                <div
                  style={{ marginTop: tabState ? `34px` : `90px` }}
                  className="EditComponentModalFooterEmployeeCounterWrapper"
                >
                  <div className="EmployeeCounterDescription">
                    Доступно мест:
                  </div>
                  <div
                    className={`EmployeeCounterItem ${
                      initialData.employees.list.length === initialData.students
                        ? `EmployeeCounterItemRed`
                        : ``
                    }`}
                  >
                    {initialData.students
                      ? initialData.students - initialData.employees.list.length
                      : 0}
                  </div>
                  <div className="EmployeeCounterItem">
                    /{initialData.students}
                  </div>
                </div>
              ) : (
                <div />
              )}
              <div
                style={{ marginTop: tabState ? `24px` : `80px` }}
                className="EditComponentFooterButtonsWrapper"
              >
                <ButtonDefault
                  style={{
                    width: `132px`,
                    height: `48px`,
                    marginRight: `10px`
                  }}
                  disabled={false}
                  onClick={close}
                >
                  Отменить
                </ButtonDefault>
                <ButtonPrimary
                  style={{ width: `132px`, height: `48px` }}
                  disabled={disabledButton}
                  onClick={() =>
                    saveRequestData({
                      graph_id:
                        initialData.status_id === 2
                          ? undefined
                          : initialData.graph_id,
                      employees: initialData.employees.list,
                      isAdmin,
                      id,
                      logout,
                      history,
                      toggleSuccess,
                      developer,
                      setDisabledButton,
                      addDeveloperLink
                    })
                  }
                >
                  Сохранить
                </ButtonPrimary>
              </div>
            </div>
          </div>
        ) : (
          <SuccessEdit close={close} refresh={refresh} />
        )}
      </div>
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
export default connector(EditComponent)
