import {
  StoreDictionaryCourse,
  StoreDictionaryStatus
} from "store/dictionary/types"
import { HistoryModalData } from "Components/History/types"

// for index
export type TableFilter = {
  date_start: number | undefined | null
  date_end: number | undefined | null
  course_id: null | number | undefined
  status_id: null | number | undefined
  hall: string | undefined
  query: string | undefined
}

export type CancelModalData = {
  active: boolean | number
  id: number | null
  status: number | null
  isAdmin: boolean
  graphId?: number | undefined | null
}

export const CancelModalInitial: CancelModalData = {
  active: false,
  id: null,
  status: null,
  isAdmin: false,
  graphId: null
}

// for helper
export const initialTableFilter: TableFilter = {
  date_start: undefined,
  date_end: undefined,
  course_id: undefined,
  status_id: undefined,
  hall: undefined,
  query: undefined
}

export type IHistoryData = {
  number: number
  course: number
  course_date: Array<number>
  status: number
  comment: string
}

export type GetColumnProps = {
  isAdmin: boolean
  status: Array<StoreDictionaryStatus>
  course: Array<StoreDictionaryCourse>
  toggleMoreModal: (
    value: ((prevState: null | number) => null | number) | null | number
  ) => void
  toggleCancelModal: (data: CancelModalData) => void
  toggleEditModal: (data: boolean | number) => void
  toggleStatusModal: (data: number | undefined) => void
  toggleHistoryModal: (data: {
    isOpen: boolean
    data: HistoryModalData[]
    id?: number
  }) => void
}

export type DownloadComponentProps = {
  tableFilter: TableFilter
}
