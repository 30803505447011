import React, { useState } from "react"
import { connect, ConnectedProps } from "react-redux"
import { useHistory } from "react-router-dom"
import { downloadFile } from "api/hooks"
import { Select } from "antd"
import * as moment from "moment"
import SelectComponent from "Components/Select"
import { ReactComponent as ExelIcon } from "Components/icons/icon-excel.svg"
import { RangePickerComponent } from "Components/DatePicker"
import TableComponent from "Components/Table"
import { IRootState } from "store"
import getColumn from "Containers/Report/helper"
import { StoreDictionaryCourse } from "store/dictionary/types"
import ButtonDefault from "Components/Button"
import InputComponent from "Components/Input"
import { LOGOUT } from "store/auth/constants"
import { StoreDevelopState } from "store/developer/types"
import { ADD_LINK } from "store/developer/constants"

const { Option } = Select

export interface ITableFilter {
  date_start: number | undefined | null
  date_end: number | undefined | null
  course_id: null | number | undefined
  hall: string | undefined | number
}

export interface CancelModalData {
  active: boolean | number
  id: number | null
  status: number | null
  isAdmin: boolean
}

const ReportComponent = (
  props: ConnectedProps<typeof connector>
): JSX.Element => {
  const {
    course,
    logout
  }: { course: StoreDictionaryCourse[]; logout: () => void } = props
  const {
    developer,
    addDeveloperLink
  }: {
    developer: StoreDevelopState
    addDeveloperLink: (data: StoreDevelopState) => void
  } = props
  const history = useHistory()
  const [tableFilter, changeTableFilter] = useState<ITableFilter>({
    date_start: undefined,
    date_end: undefined,
    course_id: undefined,
    hall: undefined
  })

  const columns = getColumn({ course })
  const [downloadPending, loadSetPending] = useState<boolean>(false)
  return (
    <div>
      <div className="HistoryWrapper">
        <h1 className="HistoryTitle">Отчет по сотрудникам клубов</h1>
        <div className="HistoryEvents">
          <div className="HistoryEventsLeft">
            <div className="HistoryEventsLeftItem">
              <RangePickerComponent
                name="training_date"
                format="DD.MM.YYYY"
                style={{ width: `240px`, marginRight: `16px` }}
                value={
                  tableFilter.date_start && tableFilter.date_end
                    ? [tableFilter.date_start, tableFilter.date_end]
                    : null
                }
                onChange={(value: null | Array<moment.Moment>) =>
                  changeTableFilter({
                    ...tableFilter,
                    date_start:
                      value && value[0]
                        ? value[0].startOf(`day`).valueOf()
                        : null,
                    date_end:
                      value && value[1] ? value[1].endOf(`day`).valueOf() : null
                  })
                }
              />
            </div>
            <div className="HistoryEventsLeftItem">
              <SelectComponent
                name="training_type"
                style={{ width: `280px`, marginRight: `16px` }}
                placeholder="Тип обучения"
                value={tableFilter.course_id}
                onChange={(value: number | null) =>
                  changeTableFilter({ ...tableFilter, course_id: value })
                }
              >
                {course.map(item => (
                  <Option key={`${item.name}_${item.id}`} value={item.id || 0}>
                    <div className="TrainingTypeOptionsWrapper">
                      <div className="HistoryTableCourseLabel">{item.name}</div>
                      <div className="HistoryTableCourseDescription">{`(${item.description})`}</div>
                    </div>
                  </Option>
                ))}
              </SelectComponent>
            </div>
            <div className="HistoryEventsLeftItem">
              <InputComponent
                style={{ width: `200px`, marginRight: `16px` }}
                allowClear={true}
                placeholder="Введите номер клуба"
                value={tableFilter.hall}
                onChange={({ target: { value } }) =>
                  changeTableFilter({
                    ...tableFilter,
                    hall: value ? value : undefined
                  })
                }
              />
            </div>
            <div className="HistoryEventsLeftItem HistoryEventsLeftItemButton">
              <ButtonDefault
                disabled={downloadPending}
                size="small"
                onClick={() =>
                  downloadFile({
                    logout,
                    history,
                    source: `admin/report/employees`,
                    loadSetPending,
                    developer,
                    addDeveloperLink,
                    requestBody: { filter: tableFilter }
                  })
                }
              >
                <div className="HistoryEventsLeftItemButtonIconWrapper">
                  <ExelIcon />
                </div>
                Выгрузить в Excel
              </ButtonDefault>
            </div>
          </div>
        </div>
        <div className="HistoryTableWrapper">
          <TableComponent
            scrollX={columns.length * 150}
            scrollY="calc(100%)"
            columnsData={columns}
            dataSource="admin/report/employees"
            filter={tableFilter}
          />
        </div>
      </div>
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    course: state.dictionary.course,
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)
export default connector(ReportComponent)
