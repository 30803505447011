import boldIcon from "./bold.svg"
import italicIcon from "./italic.svg"
import underlineIcon from "./underline.svg"
import strikethroughIcon from "./strikethrough.svg"
import paragraphIcon from "./paragraph.svg"
import colorsIcon from "./colors.svg"
import linkIcon from "./link.svg"
import listIcon from "./list.svg"

export default {
  boldIcon,
  italicIcon,
  underlineIcon,
  strikethroughIcon,
  paragraphIcon,
  colorsIcon,
  linkIcon,
  listIcon
}
