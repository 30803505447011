import React, { useState } from "react"
import moment, { Moment } from "moment"
import { ReactComponent as GraduationIcon } from "Components/icons/graduation.svg"
import { Popover, Tooltip, TooltipProps } from "antd"

import { ICurrentCourseData } from "Containers/CreateComponent/Date"
import { IEditData } from "Containers/History/EditModal"
import { StoreDictionaryCourse } from "store/dictionary/types"

const GraduationComponent = ({
  course,
  currentCourse,
  initialData,
  changeInitialData,
  startDate,
  isDisabled
}: {
  currentCourse: StoreDictionaryCourse | undefined
  course: Array<ICurrentCourseData>
  initialData: IEditData
  changeInitialData: (data: IEditData) => void
  startDate: Moment
  isDisabled: boolean
}) => {
  const currentWeekDay: number = startDate.day()
  const popupDirection =
    0 < currentWeekDay && currentWeekDay < 5 ? `right` : `left`
  const [visiblePopover, changeVisiblePopover] = useState(false)
  const [hoverInfo, changeHoverInfo] = useState<{
    hour: string | null
    start_date: string | null
    allBooking: boolean
  }>({
    hour: null,
    start_date: null,
    allBooking: false
  })
  return (
    <div className="CalendarGraduationWrapper">
      <Popover
        placement="bottomLeft"
        content={
          <div className="CalendarGraduationPopup">
            {course.map(item => {
              const allBooking = !(
                item && item.booking_students < item.students
              )
              return (
                <Tooltip
                  placement={popupDirection}
                  key={`${item.date_start}_${item.graph_id}`}
                  style={{ width: `300px` }}
                  color="#FAEBEB"
                  trigger={allBooking ? `hover` : ``}
                  overlayClassName="CoursePopupTooltip"
                  overlay={
                    <div className="CoursePopupTooltipWrapper">
                      <div className="CoursePopupTooltipTitle">
                        В этой группе недостаточно мест
                      </div>
                      <div className="CoursePopupTooltipText">
                        Выберите другую группу или день для подачи заявки
                      </div>
                    </div>
                  }
                >
                  <div
                    className={`CalendarGraduationPopupItem ${
                      !allBooking ? `GraduationPopupItemActive` : ``
                    }`}
                    onClick={e => {
                      if (
                        currentCourse &&
                        currentCourse.duration &&
                        !allBooking
                      ) {
                        // TODO remove after back fix second's
                        const newStartDate = item.date_start
                        e.stopPropagation()
                        changeInitialData({
                          ...initialData,
                          graph_id: item.graph_id,
                          students: item.students,
                          date_start: item.date_start,
                          date_end: moment(newStartDate)
                            .add(currentCourse.duration - 1, `day`)
                            .endOf(`day`)
                            .valueOf()
                        })
                        changeVisiblePopover(false)
                      }
                    }}
                    onMouseEnter={() => {
                      if (currentCourse && !allBooking) {
                        changeHoverInfo({
                          hour: `${currentCourse.lesson_duration} часов/день`,
                          start_date: `начало в ${moment(
                            item.date_start
                          ).format(`HH:mm`)}`,
                          allBooking: false
                        })
                      } else if (allBooking) {
                        changeHoverInfo({
                          hour: null,
                          start_date: null,
                          allBooking: true
                        })
                      }
                    }}
                    onMouseLeave={() =>
                      changeHoverInfo({
                        hour: null,
                        start_date: null,
                        allBooking: false
                      })
                    }
                  >
                    <div className="CoursePopupItemName">{item.name}</div>
                    <div className="CoursePopupItemInfo">
                      Доступно мест:
                      <span
                        className={
                          allBooking
                            ? `CoursePopupItemWithoutFreePlace`
                            : `CoursePopupItemPlace`
                        }
                      >
                        {` ${item.students - item.booking_students}`}
                      </span>
                      <span className="CoursePopupItemPlace">{`/${item.students}`}</span>
                    </div>
                  </div>
                </Tooltip>
              )
            })}
          </div>
        }
        trigger="click"
        visible={visiblePopover}
        onVisibleChange={changeVisiblePopover}
      >
        <div
          className={
            isDisabled
              ? `DisabledGraduationIconWrapper`
              : `GraduationIconWrapper`
          }
        >
          <GraduationIcon />
        </div>
      </Popover>
      {hoverInfo.hour ? (
        <div
          style={{
            width: `${
              currentCourse && currentCourse.duration
                ? currentCourse.duration * 120
                : 240
            }px`
          }}
          className="CourseHoverInfo"
        >
          <div className="CourseHoverInfoHour">{hoverInfo.hour}</div>
          <div className="CourseHoverInfoDateStart">{`начало в ${hoverInfo.start_date}`}</div>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default GraduationComponent
