import React, { useEffect, useState } from "react"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"

import { ApproveModalProps, SuccessApproveProps } from "./types"
import ButtonDefault, { ButtonPrimary } from "Components/Button"

const SuccessApprove = ({
  close,
  icon,
  text,
  refresh
}: SuccessApproveProps) => {
  useEffect(() => {
    let waitForIt
    if (!!close) {
      clearTimeout(waitForIt)
      waitForIt = setTimeout(() => {
        close()
        if (!!refresh) {
          refresh()
        }
      }, 2000)
    }
  }, [])
  return (
    <div className="ApproveModal SuccessApproveModal">
      <div className="SuccessApproveImg">{icon}</div>
      {text.map(item => (
        <div
          key={`approve_success_text_${item?.length}`}
          className="SuccessApproveDescription"
        >
          {item}
        </div>
      ))}
    </div>
  )
}

const ApproveModal = ({
  close,
  approve,
  cancel,
  data,
  refresh
}: ApproveModalProps) => {
  const [isSuccess, toggleIsSuccess] = useState(false)
  return (
    <div>
      <div className="ModalOverflow" />
      {isSuccess ? (
        <SuccessApprove
          close={close}
          refresh={refresh}
          icon={data.successIcon}
          text={data.successText}
        />
      ) : (
        <div className="ApproveModalWrapper ApproveModal">
          <div className="ApproveModalClose" onClick={cancel}>
            <CloseIcon />
          </div>
          <div className="ApproveModalContent">
            <div className="ApproveModalTitle">{data.title}</div>
            {data.description.map(item => (
              <div
                key={`approve_text_${item?.length}`}
                className="ApproveModalDescription"
              >
                {item}
              </div>
            ))}
            <div className="ApproveModalButtons">
              <div className="FlexRow">
                <ButtonDefault onClick={cancel} disabled={false}>
                  {data.cancelText}
                </ButtonDefault>
                <ButtonPrimary
                  onClick={() => {
                    approve(() => toggleIsSuccess(true))
                  }}
                  disabled={false}
                >
                  {data.approveText}
                </ButtonPrimary>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default ApproveModal
