import { useRequest } from "api/hooks"

export const useDictionaryMethods = () => {
  const { request: getDictionaryReq } = useRequest({
    url: `dictionary`,
    method: `POST`,
    withHall: true
  })
  const { request: getCourseForDictionaryReq } = useRequest({
    url: `course/list`,
    method: `POST`,
    withHall: true
  })

  return { getDictionaryReq, getCourseForDictionaryReq }
}
