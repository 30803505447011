import BetRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import { StoreDevelopState } from "store/developer/types"

interface IGetSchedulerProps {
  logout: () => void
  history: RouteComponentProps["history"]
  month: number
  year: number
  setGraphData: (data: any[]) => void
  graphData: any[]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export interface IGraphData {
  booking_students: number
  course_color: string
  course_description: string
  course_id: number | null
  course_name: string
  graph_name: string
  date_end: number
  date_start: number
  graph_id: number
  students: number
  employees: Array<
    { id: number; name: string; phone: string; hall: number } | undefined
  >
}

const getSchedulerData = ({
  logout,
  history,
  month,
  year,
  developer,
  addDeveloperLink,
  setGraphData
}: IGetSchedulerProps) => {
  BetRequest({
    url: `admin/graphs`,
    logout,
    history,
    developer,
    addDeveloperLink,
    method: "POST",
    requestBody: { month, year }
  }).then(({ data }: { data: IGraphData[] }) => {
    const offset = new Date().getTimezoneOffset() * 60000

    const appointmentsData = data.map(item => ({
      id: item.graph_id,
      course_id: item.course_id,
      title: item.graph_name,
      startDate: item.date_start,
      endDate: item.date_end + offset,
      color: item.course_color,
      students: item.students,
      booking_students: item.booking_students,
      employees: item.employees
    }))
    setGraphData(appointmentsData)
  })
}

interface IDragDropData {
  logout: () => void
  history: RouteComponentProps["history"]
  graph_id: number
  date_start: number
  date_end: number
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export const editDragDrop = ({
  logout,
  date_end,
  date_start,
  graph_id,
  developer,
  addDeveloperLink,
  history
}: IDragDropData) =>
  BetRequest({
    url: `admin/graph/${graph_id}`,
    logout,
    developer,
    addDeveloperLink,
    history,
    method: `PUT`,
    requestBody: { date_start, date_end }
  })

export default getSchedulerData
