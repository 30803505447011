import React from "react"
import { ReactComponent as PlusIcon } from "Components/icons/plus.svg"
import { ReactComponent as EmptyIcon } from "Components/icons/empty_history.svg"
import { ReactComponent as FilterEmptyIcon } from "Components/icons/filtersEmptyTable.svg"
import { ButtonPrimary } from "Components/Button"

const EmptyTable = ({
  redirectToCreate,
  isFilterShow
}: {
  redirectToCreate: () => void
  isFilterShow: boolean
}) => {
  return (
    <div className="EmptyTableComponentWrapper">
      <div className="IconWrapperSvg">
        {isFilterShow ? <FilterEmptyIcon /> : <EmptyIcon />}
      </div>
      <div
        className={`EmptyTableIconTitle ${
          isFilterShow ? `FilterEmptyTableIconTitle` : ``
        }`}
      >
        {isFilterShow
          ? `По вашему запросу ничего не найдено...`
          : `На этой странице будет отображаться история созданных заявок для обучения`}
      </div>
      <div className="EmptyTableIconDescription">
        {!isFilterShow
          ? `Для создания первой заявки, нажмите кнопку «Новая заявка» и выполните
        необходимые шаги`
          : ``}
      </div>
      {!isFilterShow && (
        <div className="EmptyTableButtonWrapper">
          <ButtonPrimary
            icon={
              <div style={{ paddingRight: `16px`, marginBottom: `-2px` }}>
                <PlusIcon />
              </div>
            }
            disabled={false}
            onClick={redirectToCreate}
          >
            Новая заявка
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}
export default EmptyTable
