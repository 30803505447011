import moment, { Moment } from "moment"
import { RangePickerProps } from "antd/lib/date-picker"

export const DAY = `day`
export const TIME = `HH:mm`
export const DayMonth = `DD.MM`

export const dateField = (
  value: Moment | null | undefined,
  checkOffset: boolean
) => {
  const trueTime = checkOffset ? moment(value) : moment.utc(value)
  return value ? trueTime : null
}
export const rangeField = (
  value: Array<number> | null
): RangePickerProps["value"] =>
  value && value[0] ? [moment(value[0]), moment(value[1])] : null
