import React from "react"
import { useHistory } from "react-router-dom"
import { ReactComponent as SuccessIcon } from "Components/icons/successCreate.svg"
import { ReactComponent as ErrorIcon } from "Components/icons/errorCreate.svg"
import { ButtonPrimary } from "Components/Button"
import { HISTORY_PAGE } from "Containers/BaseLayout/constants"
import { CreateModalProps } from "./types"
import { ModalText } from "./helper"

const CreateModal: React.FC<CreateModalProps> = ({
  withGraph,
  success,
  errorText
}) => {
  const history = useHistory()
  return (
    <div className="SuccessCreateLayoutWrapper">
      <div className="SuccessCreateWrapper">
        <div className="SuccessCreateHeader">
          {success ? ModalText.success.primary : ModalText.error.primary}
        </div>
        <div className="SuccessCreateDescription">
          {success ? withGraph && ModalText.success.secondary : errorText}
        </div>
        <div className="IconWrapperSvg" style={{ margin: `-32px 0` }}>
          {success ? <SuccessIcon /> : <ErrorIcon />}
        </div>
        <ButtonPrimary
          onClick={() => history.push(HISTORY_PAGE)}
          className="SuccessCreateRedirectButton"
          disabled={false}
        >
          {ModalText.button}
        </ButtonPrimary>
      </div>
    </div>
  )
}

export default CreateModal
