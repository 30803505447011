import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
import moment, { Moment } from "moment"
import CalendarComponent from "Components/Calendar"
import { ReactComponent as LeftArrowIcon } from "Components/icons/leftArrow.svg"
import { ReactComponent as RightArrowIcon } from "Components/icons/rightArrow.svg"
import { IDate } from "../index"
import GraduationComponent from "./Graduation"
import getCalendarData from "./helper"

export interface ICurrentCourseData {
  name: string
  graph_id: number
  students: number
  booking_students: number
  date_start: number
  date_end: number
  day: number
}

interface ICourseData {
  [key: number]: Array<ICurrentCourseData>
}

const Date = ({
  logout,
  panelState,
  changePanelState,
  developer,
  addDeveloperLink
}: IDate) => {
  const history = useHistory()
  const currentDay = moment()
    .startOf(`day`)
    .valueOf()
  const [courseData, setCourseData] = useState<ICourseData>({})
  const [currentPosition, changePosition] = useState<Moment>(moment())

  useEffect(() => {
    const currentMonth: number = currentPosition.month() + 1
    const currentYear: number = currentPosition.year()
    getCalendarData({
      month: currentMonth,
      year: currentYear,
      course_id: panelState.course.id,
      setCourseData,
      history,
      developer,
      addDeveloperLink,
      logout
    })
  }, [currentPosition.month()])

  return (
    <div>
      <div className="CreateModalDateCalendarComponent">
        <CalendarComponent
          value={currentPosition}
          onPanelChange={(value: Moment) => changePosition(value)}
          disabledDate={(date: Moment) => date.valueOf() < currentDay}
          headerRender={({ value, type, onChange, onTypeChange }: any) => {
            const currentMonthYear = value.format(`MMMM YYYY`)
            const isCurrentMonth =
              moment().month() === value.month() &&
              moment().year() === value.year()
            return (
              <div className="CalendarComponentHeader">
                <div className="CreateModalTitle">Выберите даты обучения:</div>
                <div
                  style={{
                    visibility: !isCurrentMonth ? undefined : `hidden`,
                    marginLeft: `auto`
                  }}
                  className="CalendarComponentHeaderArrow"
                  onClick={() => {
                    const newValue = value
                      .clone()
                      .month(currentPosition.month() - 1)
                    onChange(newValue)
                    changePosition(newValue)
                  }}
                >
                  <LeftArrowIcon />
                </div>
                <div className="CalendarComponentHeaderDate">
                  {currentMonthYear}
                </div>
                <div
                  className="CalendarComponentHeaderArrow"
                  onClick={() => {
                    const newValue = value
                      .clone()
                      .month(currentPosition.month() + 1)
                    onChange(newValue)
                    changePosition(newValue)
                  }}
                >
                  <RightArrowIcon />
                </div>
              </div>
            )
          }}
          // dateFullCellRender
          dateCellRender={(value: Moment) => {
            let isCellActive = false
            if (panelState.date.course_date) {
              isCellActive =
                moment(panelState.date.course_date)
                  .startOf(`day`)
                  .valueOf() <= value.valueOf() &&
                value.valueOf() <=
                  moment(panelState.date.course_date)
                    .add(
                      panelState.course.duration
                        ? panelState.course.duration - 1
                        : 0,
                      `day`
                    )
                    .endOf(`day`)
                    .valueOf()
            }

            const currentDay: number = value.date()
            const isCurrentMonth = currentPosition.month() === value.month()
            const isDisabled = value.valueOf() < moment().valueOf()

            if (courseData && courseData[currentDay] && isCurrentMonth) {
              return (
                <div>
                  <div className={isCellActive ? `ActiveCalendarCell` : ``} />
                  <GraduationComponent
                    course={courseData[currentDay]}
                    panelState={panelState}
                    changePanelState={changePanelState}
                    startDate={value}
                    isDisabled={isDisabled}
                  />
                </div>
              )
            }
            return <div className={isCellActive ? `ActiveCalendarCell` : ``} />
          }}
        />
      </div>
    </div>
  )
}

export default Date
