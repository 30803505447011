import React, { useState } from "react"
import { RouteComponentProps } from "react-router-dom"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { connect } from "react-redux"
import { LOGOUT } from "store/auth/constants"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import cancelRequest from "./helper"
import { CancelModalData } from "Containers/History/types"
import { InputTextAreaComponent } from "Components/Input"
import { IRootState } from "store"
import { StoreDevelopState } from "store/developer/types"
import { ADD_LINK } from "store/developer/constants"

interface CancelModal {
  cancelData: CancelModalData
  logout: () => void
  close: () => void
  refresh: () => void
  history: RouteComponentProps["history"]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

const CancelModal = ({
  cancelData: { id, status, isAdmin, graphId },
  logout,
  close,
  refresh,
  history,
  developer,
  addDeveloperLink
}: CancelModal) => {
  const isCancel = status === 3
  const isEditingDates = status === 2 && !!graphId
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user:
    | { token: string; hall: number; role: string }
    | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  const isUser = user && user.role === `ROLE_PARTNER`

  const [comment, setComment] = useState<string | undefined>(undefined)
  const [disabledButton, setDisabledButton] = useState(false)

  return (
    <div>
      <div className="ModalOverflow" />
      <div className="CancelModalWrapper">
        <div>
          <div className="CancelModalCloseWrapper" onClick={close}>
            <CloseIcon />
          </div>
          <div className="CancelModalTitle">
            {isCancel ? `Отменить` : isEditingDates ? `Сохранить` : `Одобрить`}
          </div>
          <div className="CancelModalText">
            Вы действительно хотите{" "}
            {isCancel
              ? `отменить `
              : isEditingDates
              ? `сохранить `
              : `одобрить `}
            заявку №{id}? {isCancel ? `Её нельзя будет восстановить` : ``}
            {isEditingDates
              ? `Сохранение приведет к переводу заявки в статус “Одобрена“`
              : ``}
          </div>
          {(isAdmin || isSupport || isUser) && (
            <div className="CancelModalCommentWrapper">
              <InputTextAreaComponent
                value={comment}
                autoSize={{ minRows: 4 }}
                onChange={({ target: { value } }) => setComment(value)}
                placeholder="Введите комментарий"
              />
            </div>
          )}
          <div className="CancelModalButtonsWrapper">
            <ButtonDefault
              style={{ width: `152px`, height: `48px` }}
              onClick={close}
              disabled={false}
            >
              Закрыть
            </ButtonDefault>
            <div style={{ width: `24px` }} />
            <ButtonPrimary
              style={{ width: `152px`, height: `48px` }}
              onClick={() =>
                cancelRequest({
                  id,
                  comment,
                  isAdmin,
                  status,
                  logout,
                  history,
                  close,
                  refresh,
                  graphId,
                  developer,
                  setDisabledButton,
                  addDeveloperLink
                })
              }
              disabled={
                ((isAdmin || isUser) && status === 3 && !comment) ||
                disabledButton
              }
            >
              Да,
              {isCancel
                ? ` отменить`
                : isEditingDates
                ? ` сохранить`
                : ` одобрить`}
            </ButtonPrimary>
          </div>
        </div>
      </div>
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)

export default connector(CancelModal)
