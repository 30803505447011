import React, { useState } from "react"
import { Editor } from "react-draft-wysiwyg"
import { EditorState, convertToRaw, ContentState } from "draft-js"
import draftToHtml from "draftjs-to-html"
import htmlToDraft from "html-to-draftjs"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"

import allImg from "./icons"

import { EditorComponentProps } from "./types"
import { editorLabels, options } from "./constants"

const EditorComponent = ({
  disabled,
  initial,
  setFormData,
  error,
  placeholder
}: EditorComponentProps) => {
  const contentBlock = htmlToDraft(initial || ``)
  const contentState = ContentState.createFromBlockArray(
    contentBlock.contentBlocks
  )
  const editorInitialState = EditorState.createWithContent(contentState)
  const [editorState, setEditorState] = useState<EditorState>(
    editorInitialState ?? EditorState.createEmpty()
  )
  const onEditorStateChange = (value: EditorState) => {
    setEditorState(value)
    setFormData(draftToHtml(convertToRaw(value.getCurrentContent())))
  }
  let className = "RichEditor-editor"
  const content = editorState.getCurrentContent()
  if (!content.hasText()) {
    if (
      content
        .getBlockMap()
        .first()
        .getType() !== "unstyled"
    ) {
      className += " RichEditor-hidePlaceholder"
    }
  }

  return (
    <div style={{ position: `relative` }}>
      {disabled ? (
        <div className="EditorWrapperView">
          <span dangerouslySetInnerHTML={{ __html: initial || `` }} />
        </div>
      ) : (
        <div className="RichEditor-root">
          <div className={className}>
            <Editor
              localization={{ locale: "en", translations: editorLabels }}
              onEditorStateChange={onEditorStateChange}
              editorState={editorState}
              stripPastedStyles={true}
              wrapperClassName="EditorWrapper"
              editorClassName={`EditorTextArea ${
                !!error ? `EditorErrorTextArea` : ``
              }`}
              toolbarClassName="EditorToolbar"
              placeholder={placeholder}
              toolbar={{
                options: options.toolbar,
                inline: {
                  options: options.inline,
                  bold: {
                    className: `EditorToolbarItem`,
                    icon: allImg.boldIcon
                  },
                  italic: { icon: allImg.italicIcon },
                  underline: {
                    icon: allImg.underlineIcon
                  },
                  strikethrough: {
                    icon: allImg.strikethroughIcon
                  }
                },
                blockType: {
                  icon: allImg.paragraphIcon,
                  inDropdown: true,
                  options: options.blockType,
                  Normal: {
                    className: `EditorToolbarItem`,
                    icon: allImg.boldIcon
                  },
                  className: `EditorToolbarItemBlockType`,
                  dropdownClassName: `EditorToolbarItemBlockTypeDropdown`
                },
                fontSize: {
                  options: options.fontSize,
                  className: `EditorToolbarItemFontSize`,
                  dropdownClassName: `EditorToolbarItemFontSizeDropdown`
                },
                list: {
                  inDropdown: false,
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                  options: options.list,
                  ordered: { icon: allImg.listIcon }
                },
                colorPicker: {
                  icon: allImg.colorsIcon,
                  colors: options.colorPicker
                },
                link: {
                  showOpenOptionOnHover: true,
                  defaultTargetOption: "_self",
                  options: options.link,
                  link: { icon: allImg.linkIcon, className: `LinkButton` }
                },
                embedded: {
                  defaultSize: {
                    height: "auto",
                    width: "auto"
                  }
                },
                image: {
                  urlEnabled: true,
                  uploadEnabled: true,
                  alignmentEnabled: true,
                  previewImage: false,
                  inputAccept:
                    "image/gif,image/jpeg,image/jpg,image/png,image/svg",
                  alt: { present: false, mandatory: false },
                  defaultSize: {
                    height: "auto",
                    width: "auto"
                  }
                },
                history: {
                  inDropdown: false,
                  options: options.history
                }
              }}
            />
          </div>
        </div>
      )}
      {!!error && <div className="ErrorText">{error}</div>}
    </div>
  )
}

export default EditorComponent
