export enum InfoListActive {
  list = `list`,
  archive = `archive`
}

export enum FormatItem {
  center = 2,
  remotely = 1
}

export enum ApproveModalTypes {
  visible = `visible`,
  archive = `archive`
}

export type ConstantCategoryProps = {
  id: number
  name: string
  subName: string
  open: () => void
  isOpen: boolean
  children: React.ReactNode
  close: () => void
  bg: string
}

export type InfoTitleProps = {
  openCreate: () => void
  canEdit: boolean
  toggleActiveList: (value: InfoListActive) => void
  activeList: InfoListActive
}

export type InfoData = {
  id: number
  name: string
  description: string
  duration: number
  format: FormatItem
  lesson_duration: number
  lesson_assemble: string
  lesson_start: string
  lesson_format_description: string
  days: string[]
  visible: boolean
  can_edit: boolean
  archive: boolean
  is_graph_visible: number
}

export type InfoState = {
  data: InfoData[]
  pending: boolean
}

export type CreateModalData = {
  isOpen: boolean
  initial?: InfoData
  refreshList: () => void
}

export type CreateModalDataState = {
  name?: string
  duration?: number
  format?: 1 | 2 | null
  lesson_duration?: number
  lesson_assemble?: string
  lesson_start?: string
  lesson_format_description?: string
  days: string[]
}

export type CategoryProps = {
  data: InfoData
  open: (data: null | number) => void
  isOpen: boolean
  canEdit: boolean
  isChart?: boolean
  toggleChart?: (data: boolean) => void
  refresh: () => void
  toggleEditModal: (data: CreateModalData) => void
}

export type InfoListProps = {
  infoData: InfoState
  canEdit: boolean
  refresh: () => void
  toggleEditModal: (data: CreateModalData) => void
}

export type ApproveModalState = {
  isOpen: boolean
  type: ApproveModalTypes | null
}
