import React from "react"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"

import HistoryModalProps from "./types"
import {
  getStatusData,
  dateFormatForTitle,
  dateFormatForKey,
  labels
} from "./constants"
import moment from "moment"

const HistoryModal = ({ close, data, id }: HistoryModalProps) => {
  return (
    <div>
      <div className="HistoryModalWrapper">
        <div className="HistoryModalClose" onClick={close}>
          <CloseIcon />
        </div>
        <div className="HistoryModalTitle">{`История заявки №${id}`}</div>
        <div className="HistoryModalTable">
          {data.map((item, key) => {
            const isLast = data.length === key + 1
            const { color, title } = getStatusData(item.status)
            return (
              <div
                key={`history_item_${moment(item.status).format(
                  dateFormatForKey
                )}`}
                className={`${
                  isLast ? `HistoryModalTableRowLast` : ``
                } HistoryModalTableRow`}
              >
                <div className="FlexRow HistoryModalTableRowTitle">
                  <div
                    style={{ backgroundColor: color }}
                    className="HistoryModalTableRowItem HistoryModalTableRowStatus"
                  >
                    <span>{title}</span>
                    <span className="StatusPrefix">(а)</span>
                  </div>
                  <div className="HistoryModalTableRowItem HistoryModalTableRowName">
                    {item.name}
                  </div>
                  <div className="HistoryModalTableRowItem HistoryModalTableRowDate">
                    {moment(item.date).format(dateFormatForTitle)}
                  </div>
                </div>
                <div>
                  {item.comment && (
                    <div className="HistoryModalTableRowText">
                      <div className="HistoryModalTableRowLabel">
                        {labels.comment}
                      </div>
                      <div className="HistoryModalTableRowValue">
                        {item.comment}
                      </div>
                    </div>
                  )}
                  {item.changes && (
                    <div className="HistoryModalTableRowText">
                      <div className="HistoryModalTableRowValue">
                        {item.changes}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      </div>
      <div className="ModalOverflow" />
    </div>
  )
}

export default HistoryModal
