export const employeesFields = {
  hall: {
    label: `Клуб №`,
    rules: [
      {
        required: true,
        message: "Обязательное поле"
      }
    ],
    validateTrigger: [`onSubmit`, "onBlur"],
    name: `hall`,
    placeholder: `...`
  },
  name: {
    label: ``,
    rules: [{ required: true, message: "Обязательное поле" }],
    validateTrigger: [`onSubmit`, "onBlur"],
    name: `name`,
    placeholder: `ФИО...`
  },
  phone: {
    label: `+7`,
    rules: [{ required: true, message: "Обязательное поле" }],
    validateTrigger: [`onSubmit`, "onBlur", "onChange"],
    name: `phone`,
    placeholder: `Телефон...`,
    extraValidate: { count: 10, text: "Не менее 10 символов" }
  },
  snils: {
    label: ``,
    rules: [{ required: true, message: "Обязательное поле" }],
    validateTrigger: [`onSubmit`, "onBlur", "onChange"],
    name: `snils`,
    placeholder: `СНИЛС...`,
    extraValidate: { count: 11, text: "Не менее 11 символов" }
  }
}
