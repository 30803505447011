import BetRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import { StoreDevelopState } from "store/developer/types"

import { errorCanvas } from "Components/Messages"

type cancelRequestType = {
  id: number | null
  isAdmin: boolean
  comment: string | undefined
  status: number | null
  logout: () => void
  close: () => void
  refresh: () => void
  history: RouteComponentProps["history"]
  graphId: number | undefined | null
  developer: StoreDevelopState
  setDisabledButton: (disabledButton: boolean) => void

  addDeveloperLink: (data: StoreDevelopState) => void
}

const cancelRequest = async ({
  id,
  comment,
  isAdmin,
  status,
  logout,
  refresh,
  history,
  close,
  graphId,
  developer,
  setDisabledButton,
  addDeveloperLink
}: cancelRequestType) => {
  setDisabledButton(true)
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  if (!isSupport) {
    BetRequest({
      url: `${isAdmin ? `admin/` : ``}application/${id}`,
      logout,
      history,
      developer,
      addDeveloperLink,
      method: `PUT`,
      requestBody: {
        status_id: status,
        comment,
        graph_id: graphId
      }
    })
      .then(() => {
        setDisabledButton(false)
        close()
        refresh()
      })
      .catch(({ response }) => {
        setDisabledButton(false)
        errorCanvas({
          errors: response.data.errors,
          description: response.data.description
        })
      })
  } else {
    close()
  }
}

export default cancelRequest
