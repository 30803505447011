import React, { useState, useMemo } from "react"
import { useSelector } from "react-redux"

import { useRequestParams } from "api/hooks"

import { ReactComponent as CloseIcon } from "Components/icons/close.svg"
import { ReactComponent as PlusIcon } from "Components/icons/plus.svg"

import InputComponent, { InputNumberComponent } from "Components/Input"
import SelectComponent from "Components/Select"
import ButtonDefault, { ButtonPrimary } from "Components/Button"
import DatePickerComponent from "Components/DatePicker"
import { DAY, TIME } from "Components/DatePicker/constants"

import { Select, TimePicker, Form } from "antd"
import { DeleteFilled } from "@ant-design/icons"

import moment, { Moment } from "moment"

import saveGraph, {
  deleteGraph,
  createGraphText,
  validateState
} from "./helper"
import SuccessModal from "./Components/success"
import { EmployeesList, NewEmployeeItem } from "./Components/employees"
import { CreateGraphProps, CreateFormState, EmployeesType } from "./types"
import { getDictionaryCourse } from "store/dictionary/selector"

const { Option } = Select

const CreateGraph = ({
  close,
  refresh,
  dateStart,
  isEditable,
  inProgress,
  initial
}: CreateGraphProps) => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()
  const course = useSelector(getDictionaryCourse)
  const [createState, setCreateState] = useState<CreateFormState>({
    ...initial,
    employees: []
  })
  const [disabledButton, setDisabledButton] = useState(false)
  const id = initial.id || undefined
  const isEdit = !!id
  const initialCourseType =
    isEdit && initial.course_id
      ? course.find(item => item.id === initial.course_id)
      : null
  const offset = new Date().getTimezoneOffset() * 60000
  const dateStartForTitle = moment(dateStart).valueOf() - offset
  const viewOnly = !isEditable

  const textObj = useMemo(
    () => createGraphText({ id, isEdit, startDate: dateStartForTitle }),
    [id, isEdit, dateStartForTitle]
  )
  const haveEmployees = !!initial.employees[0]

  const [successModal, toggleSuccessModal] = useState(false)

  const [form] = Form.useForm()

  const disableByEmpty = useMemo(() => validateState(createState), [
    createState
  ])

  return (
    <div className="CreateGraphWrapper">
      <div className="ModalOverflow" />
      {successModal ? (
        <div>
          <SuccessModal
            close={() => {
              refresh()
              close()
            }}
            isNew={!isEdit}
          />
        </div>
      ) : (
        <div className="CreateGraphModalWrapper">
          <div>
            <div className="CreateGraphModalCloseWrapper" onClick={close}>
              <CloseIcon />
            </div>
            <div className="CancelModalTitle">
              {viewOnly ? `Курс №${initial.id}` : textObj.title}
            </div>
          </div>
          {isEdit && initialCourseType && (
            <div>
              <div
                style={{ textAlign: `center` }}
                className="HistoryTableCourseDescription"
              >
                {`${initialCourseType.name}. ${initialCourseType.description}`}
              </div>
            </div>
          )}
          <Form
            form={form}
            initialValues={{ ...initial, employees: [] }}
            autoComplete="off"
          >
            <div className="CreateGraphModalContent">
              <div className="CreateGraphModalContentField">
                <div className="CreateGraphModalContentFieldDescription">
                  {textObj.fields.name.title}
                </div>
                <div>
                  <Form.Item
                    name={textObj.fields.name.name}
                    rules={[
                      {
                        required: true,
                        message: textObj.fields.name.message
                      }
                    ]}
                    tooltip={false}
                  >
                    <InputComponent
                      placeholder={textObj.fields.name.placeholder}
                      value={createState.name}
                      disabled={viewOnly}
                      onChange={({ target: { value } }) => {
                        setCreateState({ ...createState, name: value })
                      }}
                    />
                  </Form.Item>
                </div>
              </div>
              {!isEdit && (
                <div className="CreateGraphModalContentField">
                  <div className="CreateGraphModalContentFieldDescription">
                    {textObj.fields.course_id.title}
                  </div>
                  <div>
                    <Form.Item
                      name={textObj.fields.course_id.name}
                      rules={[
                        {
                          required: true,
                          message: textObj.fields.course_id.message
                        }
                      ]}
                    >
                      <SelectComponent
                        style={{ width: `100%` }}
                        placeholder={textObj.fields.course_id.placeholder}
                        value={createState.course_id}
                        disabled={viewOnly}
                        onChange={(value: number | null) => {
                          form.setFieldsValue({ training_type: value })
                          setCreateState({ ...createState, course_id: value })
                        }}
                      >
                        {course.map(item => (
                          <Option
                            key={`${item.name}_${item.id}`}
                            value={item.id || 0}
                          >
                            <div className="TrainingTypeOptionsWrapper">
                              <div className="HistoryTableCourseLabel">
                                {item.name}
                              </div>
                              <div className="HistoryTableCourseDescription">{`(${item.description})`}</div>
                            </div>
                          </Option>
                        ))}
                      </SelectComponent>
                    </Form.Item>
                  </div>
                </div>
              )}
              {isEdit && !inProgress && (
                <div className="CreateGraphModalContentField">
                  <div className="CreateGraphModalContentFieldDescription">
                    {textObj.fields.date_start.title}
                  </div>
                  <Form.Item
                    name={textObj.fields.date_start.name}
                    rules={[
                      {
                        required: true,
                        message: textObj.fields.date_start.message
                      }
                    ]}
                  >
                    <DatePickerComponent
                      disabled={viewOnly}
                      value={moment(
                        moment(createState.date_start).valueOf() - offset
                      )}
                      onChange={(date: Moment | null) => {
                        const newValue = moment(date).valueOf() + offset
                        setCreateState({
                          ...createState,
                          date_start: moment(newValue).startOf(DAY)
                        })
                      }}
                      style={{ width: `100%` }}
                    />
                  </Form.Item>
                </div>
              )}
              {isEdit && inProgress && (
                <div className="CreateGraphModalContentField">
                  <div className="CreateGraphModalContentFieldDescription">
                    {textObj.fields.date_end.title}
                  </div>
                  <Form.Item
                    name={textObj.fields.date_end.name}
                    rules={[
                      {
                        required: true,
                        message: textObj.fields.date_end.message
                      }
                    ]}
                  >
                    <DatePickerComponent
                      disabled={viewOnly && !inProgress}
                      value={moment(createState.date_end)}
                      onChange={(date: Moment | null) => {
                        setCreateState({
                          ...createState,
                          date_end: moment(date).valueOf()
                        })
                      }}
                      style={{ width: `100%` }}
                    />
                  </Form.Item>
                </div>
              )}
              <div className="CreateGraphModalContentFieldRow">
                <div
                  className="CreateGraphModalContentField"
                  style={{ width: `180px` }}
                >
                  <div className="CreateGraphModalContentFieldDescription">
                    {textObj.fields.students.title}
                  </div>
                  <Form.Item
                    name={textObj.fields.students.name}
                    rules={[
                      {
                        required: true,
                        message: textObj.fields.students.message
                      }
                    ]}
                  >
                    <InputNumberComponent
                      style={{ width: `100%` }}
                      value={createState.students}
                      disabled={viewOnly && !inProgress}
                      min={0}
                      max={100}
                      onChange={(value?: number | string) => {
                        const haveSlots =
                          initial.employees?.length +
                            (form.getFieldsValue([
                              textObj.fields.employees.name
                            ])?.employees?.length || 0) <=
                          Number(value)
                        setCreateState({
                          ...createState,
                          students: haveSlots ? value : createState.students
                        })
                        form.setFieldsValue({
                          students: haveSlots ? value : createState.students
                        })
                      }}
                    />
                  </Form.Item>
                </div>
                <div
                  className="CreateGraphModalContentField"
                  style={{ width: `180px` }}
                >
                  <div className="CreateGraphModalContentFieldDescription">
                    {textObj.fields.time_start.title}
                  </div>
                  <Form.Item
                    name={textObj.fields.time_start.name}
                    rules={[
                      {
                        required: true,
                        message: textObj.fields.time_start.message
                      }
                    ]}
                  >
                    <TimePicker
                      disabled={viewOnly}
                      style={{ height: `50px`, width: `100%` }}
                      defaultValue={moment("12:00", TIME)}
                      format={TIME}
                      value={createState.time_start}
                      onChange={(time: Moment | null) =>
                        setCreateState({ ...createState, time_start: time })
                      }
                    />
                  </Form.Item>
                </div>
              </div>
              {(viewOnly || (isEditable && isEdit)) && (
                <Form.List name={textObj.fields.employees.name}>
                  {(fields, { add }, { errors }) => {
                    const students = form.getFieldsValue([
                      textObj.fields.students.name
                    ])?.students
                    const haveSlots =
                      students > fields?.length + initial.employees?.length
                    return (
                      <>
                        <div className="CreateGraphModalEmployees FlexRow">
                          <div className="CreateGraphModalEmployeesTitle">
                            {textObj.fields.employees.title}
                          </div>
                          {!inProgress && !viewOnly && haveSlots && (
                            <Form.Item>
                              <div
                                className="CreateGraphAddEmployees FlexRow"
                                onClick={() => {
                                  add()
                                  setCreateState({
                                    ...createState,
                                    employees:
                                      form.getFieldsValue([`employees`])
                                        ?.employees || []
                                  })
                                }}
                              >
                                <PlusIcon />
                                <div className="CreateGraphAddEmployeesTitle">
                                  {textObj.buttons.add}
                                </div>
                              </div>
                            </Form.Item>
                          )}
                        </div>
                        <div className="CreateGraphModalEmployeesWrapper">
                          {fields.map(field => (
                            <div key={field.key}>
                              <NewEmployeeItem
                                field={field}
                                form={form}
                                clear={(employees: EmployeesType[]) =>
                                  setCreateState({
                                    ...createState,
                                    employees
                                  })
                                }
                              />
                            </div>
                          ))}
                          {haveEmployees ? (
                            <EmployeesList employees={initial.employees} />
                          ) : (
                            <div className="HistoryTableCourseDescription">
                              Не было добавлено ни одного сотрудника
                            </div>
                          )}
                        </div>
                      </>
                    )
                  }}
                </Form.List>
              )}
              {(!viewOnly || inProgress) && (
                <div className="CreateGraphModalButtonWrapper">
                  <Form.Item shouldUpdate className="submit">
                    {() => (
                      <ButtonPrimary
                        disabled={
                          disableByEmpty ||
                          disabledButton ||
                          form
                            .getFieldsError()
                            .filter(({ errors }) => errors.length).length > 0
                        }
                        htmlType="submit"
                        onClick={() =>
                          saveGraph({
                            logout,
                            toggleSuccessModal,
                            close,
                            history,
                            dateStart,
                            course,
                            initial,
                            form,
                            id,
                            refresh,
                            isEdit,
                            inProgress,
                            developer,
                            setDisabledButton,
                            addDeveloperLink
                          })
                        }
                      >
                        {textObj.buttons.save}
                      </ButtonPrimary>
                    )}
                  </Form.Item>
                  <div style={{ marginLeft: `16px` }}>
                    <ButtonDefault disabled={false} onClick={close}>
                      {textObj.buttons.cancel}
                    </ButtonDefault>
                  </div>
                  {isEdit && !viewOnly && (
                    <div
                      className="CreateGraphModalDeleteWrapper"
                      style={{ marginLeft: `16px` }}
                      onClick={() =>
                        deleteGraph({
                          logout,
                          history,
                          developer,
                          addDeveloperLink,
                          id,
                          refresh,
                          close
                        })
                      }
                    >
                      <DeleteFilled style={{ fontSize: "24px" }} />
                    </div>
                  )}
                </div>
              )}
            </div>
          </Form>
        </div>
      )}
    </div>
  )
}

export default CreateGraph
