import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"
import moment from "moment"
import { ButtonPrimary, ButtonDefault } from "Components/Button"
import { ReactComponent as ArrowIcon } from "Components/icons/arrow.svg"
import { StoreDictionaryCourse } from "store/dictionary/types"
import { ReactComponent as BackIcon } from "Components/icons/backArrow.svg"
import { IEmployeeData } from "Containers/CreateComponent/Employee/helper"
import Course from "./Course"
import Employee from "./Employee"
import Date from "./Date"
import CreateModal from "./CreateModal"
import { LOGOUT } from "store/auth/constants"
import createRequest, { isCourseAvailableRequest } from "./helper"
import PanelEmployeeList from "./Employee/PanelEmployeeList"
import { HISTORY_PAGE } from "../BaseLayout/constants"
import { IRootState } from "store"
import { StoreDevelopState } from "store/developer/types"
import { ADD_LINK } from "store/developer/constants"
import { ErrorModal } from "./types"

export interface PanelState {
  active: number
  course: StoreDictionaryCourse
  date: {
    name: string | null
    graph_id: number | null
    students: number | null
    booking_students: number | null
    course_date: number | null
    date_start: number | null
    date_end: number | null
    day: number | null
  }
  employee: {
    list: Array<number>
    hall?: string
    data: { [key: number]: IEmployeeData } | null
  }
}

export interface IDate {
  logout: () => void
  panelState: PanelState
  changePanelState: (data: PanelState) => void
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

const initialPanelState: PanelState = {
  active: 1,
  course: {
    id: null,
    name: null,
    description: null,
    distance: false,
    duration: null,
    lesson_duration: null,
    graph: true
  },
  date: {
    name: null,
    graph_id: null,
    students: null,
    booking_students: null,
    course_date: null,
    date_end: null,
    date_start: null,
    day: null
  },
  employee: {
    list: [],
    hall: undefined,
    data: null
  }
}

const CreateComponent = ({
  logout,
  developer,
  addDeveloperLink
}: {
  logout: () => void
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}) => {
  const history = useHistory()
  const [panelState, changePanelState] = useState<PanelState>(initialPanelState)

  const openSuccess: () => void = () =>
    changePanelState({ ...panelState, active: 4 })

  const nextButtonActive =
    (panelState.active === 1 && !!panelState.course.name) ||
    (panelState.active === 2 && !!panelState.date.course_date)

  const panelOneIsClickable = panelState.active !== 1
  const panelTwoIsClickable =
    panelState.active !== 2 && !!panelState.course.name
  const panelThreeIsClickable =
    (panelState.active !== 3 && !!panelState.date.course_date) ||
    (panelState.active === 1 && !panelState.course.graph)

  const [successModalOpen, toggleSuccessModal] = useState(false)
  const [errorModal, toggleErrorModal] = useState<ErrorModal>({
    open: false,
    description: ""
  })
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  const [disabledButton, setDisabledButton] = useState(false)

  useEffect(() => {
    if (panelState.active !== 1) {
      isCourseAvailableRequest({
        courseId: panelState.course.id,
        graphId: panelState.date.graph_id,
        history,
        addDeveloperLink,
        developer,
        logout,
        toggleErrorModal
      })
    }
  }, [panelState.active])

  return (
    <div className="CreateComponentWrapper">
      <div className="CreateComponentContent">
        <div className="CreateComponentTitleWrapper">
          <div
            className="CreateComponentTitleButton"
            onClick={() => history.push(HISTORY_PAGE)}
          >
            <BackIcon />
          </div>
          <h1 className="CreateComponentTitleText">Создание заявки</h1>
        </div>
        <div className="CreateModal">
          <div className="CreateModalBody">
            {panelState.active === 1 && (
              <Course
                logout={logout}
                initialPanelState={initialPanelState}
                panelState={panelState}
                changePanelState={changePanelState}
                developer={developer}
                addDeveloperLink={addDeveloperLink}
              />
            )}
            {panelState.active === 2 && (
              <Date
                logout={logout}
                panelState={panelState}
                changePanelState={changePanelState}
                developer={developer}
                addDeveloperLink={addDeveloperLink}
              />
            )}
            {panelState.active === 3 && (
              <Employee
                logout={logout}
                panelState={panelState}
                changePanelState={changePanelState}
                developer={developer}
                addDeveloperLink={addDeveloperLink}
              />
            )}
          </div>
          <div className="CreateModalButtons">
            <div>
              {panelState.active !== 1 && panelState.active !== 4 ? (
                <ButtonDefault
                  disabled={panelState.active === 1}
                  onClick={() => {
                    if (!panelState.course.graph && panelState.active === 3) {
                      changePanelState({
                        ...panelState,
                        active: panelState.active - 2
                      })
                    } else {
                      changePanelState({
                        ...panelState,
                        active: panelState.active - 1
                      })
                    }
                  }}
                  style={{ marginRight: `12px` }}
                >
                  Назад
                </ButtonDefault>
              ) : (
                <div />
              )}
            </div>
            <div>
              {panelState.active !== 3 && panelState.active !== 4 ? (
                <ButtonPrimary
                  disabled={!nextButtonActive}
                  onClick={() => {
                    if (!panelState.course.graph && panelState.active === 1) {
                      changePanelState({
                        ...panelState,
                        active: panelState.active + 2
                      })
                    } else {
                      changePanelState({
                        ...panelState,
                        active: panelState.active + 1
                      })
                    }
                  }}
                >
                  Далее
                </ButtonPrimary>
              ) : (
                <div />
              )}
            </div>
          </div>
        </div>
      </div>
      {!!panelState.active && panelState.active !== 4 && (
        <div className="CreateComponentRightPanel">
          <h3 className="RightPanelTitle">Моя заявка</h3>
          <div className="RightPanelList">
            <div className="RightPanelListItemWrapper">
              <div className="RightPanelListLabelWrapper">
                {panelState.active === 1 ? (
                  <div>
                    <ArrowIcon />
                  </div>
                ) : (
                  <div />
                )}
                <h4
                  className={`RightPanelListLabel ${
                    panelOneIsClickable ? `RightPanelListLabelIsClickable` : ``
                  }`}
                  style={{ paddingLeft: panelState.active === 1 ? `14px` : `` }}
                  onClick={() => {
                    if (panelOneIsClickable)
                      changePanelState({ ...panelState, active: 1 })
                  }}
                >
                  Тип обучения
                </h4>
              </div>
              {panelState.course.name ? (
                <div>
                  <div className="RightPanelListDescription">
                    <div>{panelState.course.name}</div>
                    <div>{`(${panelState.course.description})`}</div>
                  </div>
                  <div className="RightPanelListRectangle" />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="RightPanelListItemWrapper">
              <div className="RightPanelListLabelWrapper">
                {panelState.active === 2 ? (
                  <div>
                    <ArrowIcon />
                  </div>
                ) : (
                  <div />
                )}
                <h4
                  className={`RightPanelListLabel ${
                    panelState.active === 1 || !panelState.course.graph
                      ? `RightPanelDisabled`
                      : ``
                  } ${
                    panelTwoIsClickable && panelState.course.graph
                      ? `RightPanelListLabelIsClickable`
                      : ``
                  }`}
                  style={{
                    paddingLeft: panelState.active === 2 ? `14px` : ``
                  }}
                  onClick={() => {
                    if (panelTwoIsClickable && panelState.course.graph)
                      changePanelState({ ...panelState, active: 2 })
                  }}
                >
                  Даты обучения
                </h4>
              </div>
              {!panelState.course.graph && (
                <div>
                  <div className="RightPanelListDescriptionDisabled">
                    Для выбранного типа обучения даты проставляются отделом
                    обучения
                  </div>
                  <div className="RightPanelListRectangle" />
                </div>
              )}
              {panelState.date.course_date && panelState.course.graph ? (
                <div>
                  <div className="RightPanelListDescription">
                    <div>{`${moment(panelState.date.date_start).format(
                      `DD.MM`
                    )} — ${moment
                      .utc(panelState.date.date_end)
                      .format(
                        `DD.MM.YYYY ${moment(panelState.date.date_start).format(
                          `(с HH:mm)`
                        )}`
                      )}`}</div>
                    <div>{`${panelState.course.lesson_duration} часов/день, ${panelState.date.name}`}</div>
                  </div>
                  <div className="RightPanelListRectangle" />
                </div>
              ) : (
                <div />
              )}
            </div>
            <div className="RightPanelListItemWrapper">
              <div
                className="RightPanelListLabelWrapper"
                onClick={() => {
                  if (panelThreeIsClickable)
                    changePanelState({ ...panelState, active: 3 })
                }}
              >
                {panelState.active === 3 ? (
                  <div>
                    <ArrowIcon />
                  </div>
                ) : (
                  <div />
                )}
                <h4
                  className={`RightPanelListLabel ${
                    panelState.active < 3 ? `RightPanelDisabled` : ``
                  } ${
                    panelThreeIsClickable
                      ? `RightPanelListLabelIsClickable`
                      : ``
                  }`}
                  style={{ paddingLeft: panelState.active === 3 ? `14px` : `` }}
                >
                  Сотрудники
                </h4>
                {panelState.course.graph && panelState.date.students && (
                  <h4
                    className={`RightPanelListLabel ${
                      panelState.active < 3 ? `RightPanelDisabled` : ``
                    } ${
                      panelThreeIsClickable
                        ? `RightPanelListLabelIsClickable`
                        : ``
                    }`}
                    style={{ paddingLeft: `8px` }}
                  >
                    {`${
                      panelState.date.booking_students !== null
                        ? (panelState.date.students || 0) -
                          panelState.date.booking_students
                        : panelState.date.students
                    }/${panelState.date.students || 0}`}
                  </h4>
                )}
              </div>
              <div className="RightPanelListDescription">
                <PanelEmployeeList
                  logout={logout}
                  panelState={panelState}
                  changePanelState={changePanelState}
                  developer={developer}
                  addDeveloperLink={addDeveloperLink}
                />
              </div>
            </div>
          </div>
          {panelState.active === 3 && (
            <div className="RightPanelCreateWrapper">
              <div className="RightPanelListCreateRectangle" />
              <ButtonPrimary
                className="RightPanelCreatButton"
                disabled={
                  panelState.employee.list[0] === undefined || disabledButton
                }
                onClick={() =>
                  createRequest({
                    isSupport,
                    course_id: panelState.course.id,
                    employees: panelState.employee.list,
                    employee_hall: panelState.employee.hall,
                    graph_id: panelState.date.graph_id || undefined,
                    toggleSuccessModal,
                    logout,
                    developer,
                    addDeveloperLink,
                    setDisabledButton,
                    history,
                    toggleErrorModal
                  })
                }
              >
                Подать заявку
              </ButtonPrimary>
            </div>
          )}
        </div>
      )}
      {successModalOpen && (
        <CreateModal
          withGraph={!(panelState.date.course_date && panelState.course.graph)}
          success
        />
      )}
      {errorModal.open && (
        <CreateModal
          withGraph={!(panelState.date.course_date && panelState.course.graph)}
          success={!errorModal.open}
          errorText={errorModal.description}
        />
      )}
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)

export default connector(CreateComponent)
