import InfoList from "./InfoList"
import Category from "./Category"
import OpenCategory from "./OpenCategory"
import InfoTitle from "./InfoTitle"

export default {
  InfoList,
  Category,
  OpenCategory,
  InfoTitle
}
