import React, { useState } from "react"

import { userFromStorage } from "api/constant"

import { InfoListProps } from "../types"
import Category from "./Category"

const InfoList = ({
  infoData,
  canEdit,
  refresh,
  toggleEditModal
}: InfoListProps) => {
  const [openCategory, toggleCategory] = useState<number | null>(null)

  const { isPartner } = userFromStorage()

  const data = !isPartner
    ? infoData.data
    : infoData.data.filter(item => item.visible)

  return (
    <div>
      {data.map(item => {
        return (
          <div className="InfoListItem" key={`info_list_item_${item.id}`}>
            <Category
              data={item}
              isOpen={openCategory === item.id}
              open={toggleCategory}
              canEdit={canEdit}
              refresh={refresh}
              toggleEditModal={toggleEditModal}
            />
          </div>
        )
      })}
    </div>
  )
}

export default InfoList
