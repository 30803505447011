import React from "react"

import Status from "./types"

const StatusComponent = ({ label, color, id }: Status) => {
  const isEditable = id && id === 5
  return (
    <div
      className={`StatusComponentWrapper ${
        isEditable ? `StatusComponentEditableWrapper` : ``
      }`}
      style={{ background: `rgba(${color}, ${isEditable ? 1 : 0.1})` }}
    >
      <div
        style={{ color: isEditable ? `#fff` : `rgba(${color}, 1)` }}
        className="StatusComponentLabel"
      >
        {label}
      </div>
    </div>
  )
}

export default StatusComponent
