import React from "react"
import { Checkbox } from "antd"
import { CheckboxProps } from "antd/lib"

interface ICheckBoxComponent extends CheckboxProps {
  label?: string
  rest?: any
}

export const CheckBoxComponent = ({ label, ...rest }: ICheckBoxComponent) => (
  <div className="CheckboxWrapper">
    <Checkbox {...rest} />
    <div className="CheckboxText" style={{ paddingLeft: `12px` }}>
      {label}
    </div>
  </div>
)
