import { useState, useCallback } from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import { useSelector, useDispatch } from "react-redux"

import BetRequest from "./request"

import { Method } from "axios"

// store auth - logout
import { logoutUser } from "store/auth/actions"
// store developer
import { StoreDevelopState } from "store/developer/types"
import { addLink } from "store/developer/actions"
import { getDeveloperList } from "store/developer/selector"

export const useNow = () => {
  const [timestamp, setTimestamp] = useState<number>(Date.now())

  const refresh = useCallback(() => setTimestamp(Date.now()), [])

  return { timestamp, refresh }
}

type downloadFileProps = {
  logout: () => void
  source: string
  requestBody: any
  fileName?: string
  loadSetPending: (data: boolean) => void
  sort?: {
    field: string
    order: string
  }
  method?: Method | undefined
  history: RouteComponentProps["history"]
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export const downloadFile = ({
  logout,
  source,
  requestBody,
  fileName,
  loadSetPending,
  sort,
  method,
  developer,
  addDeveloperLink,
  history
}: downloadFileProps) => {
  loadSetPending(true)
  BetRequest({
    url: source,
    logout,
    history,
    developer,
    addDeveloperLink,
    requestBody: { ...requestBody, download: 1, sorter: sort || undefined },
    method: method || `POST`
  })
    .then(({ data, data: { link } }) => {
      const myRegexp = /uploads\/(.*)/
      const filenameFromLink = myRegexp.exec(link)
      const loadLink = document.createElement(`a`)
      loadLink.setAttribute(`href`, link)
      loadLink.setAttribute(
        `download`,
        `${filenameFromLink ? filenameFromLink[1] : `Отчет.xlsx`}`
      )
      loadLink.style.display = `none`
      document.body.appendChild(loadLink)
      loadLink.click()
      document.body.removeChild(loadLink)
      loadSetPending(false)
    })
    .catch(() => loadSetPending(false))
}

interface IRequestProps {
  url: string
  method: Method | undefined
  requestBody?: any
  params?: any
  withHall?: boolean
}

export const useRequestParams = () => {
  const history = useHistory()
  const developer: StoreDevelopState = useSelector(getDeveloperList)
  const dispatch = useDispatch()
  const addDeveloperLink: (data: StoreDevelopState) => void = data =>
    dispatch(addLink(data))
  const logout: () => void = () => dispatch(logoutUser())
  return { history, developer, addDeveloperLink, logout }
}

export const useRequest = ({
  url,
  method,
  requestBody,
  withHall,
  params
}: IRequestProps) => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()
  return {
    request: () =>
      BetRequest({
        history,
        withHall,
        logout,
        developer,
        addDeveloperLink,
        method,
        url,
        requestBody,
        params
      })
  }
}
