import BetRequest from "api/request"

import { EditPhoneNumber } from "./types"
import { errorCanvas } from "Components/Messages"

const editPhoneNumber = ({
  employeeId,
  developer,
  addDeveloperLink,
  logout,
  history,
  phoneState,
  setPhoneState
}: EditPhoneNumber) => {
  setPhoneState({ ...phoneState, pending: true })
  BetRequest({
    url: `employee/${employeeId}`,
    method: `PUT`,
    requestBody: {
      phone: phoneState.value
    },
    developer,
    addDeveloperLink,
    logout,
    history
  })
    .then(() =>
      setPhoneState({
        ...phoneState,
        pending: false,
        isInput: false,
        initial: phoneState.value
      })
    )
    .catch(({ response }) => {
      setPhoneState({ ...phoneState, pending: false })
      errorCanvas({
        errors: response.data.errors,
        description: response.data.description
      })
    })
}

export default editPhoneNumber
