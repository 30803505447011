import React from "react"
import { InfoTitleProps, InfoListActive } from "../types"
import { textInfoTitle } from "../constants"
import { ReactComponent as PlusIcon } from "Components/icons/plus.svg"
import { ButtonPrimary } from "Components/Button"

const InfoTitle = ({
  openCreate,
  canEdit,
  toggleActiveList,
  activeList
}: InfoTitleProps) => {
  const isList = activeList === InfoListActive.list
  const isArchive = activeList === InfoListActive.archive
  return (
    <div className="InfoWrapperHeader FlexRow">
      <div className="CreateButtonTitle FlexRow">
        <span>{textInfoTitle.componentTitle}</span>
        <div className="InfoWrapperTitleTabs FlexRow">
          <div
            onClick={() => {
              if (isArchive) {
                toggleActiveList(InfoListActive.list)
              }
            }}
            className={`InfoWrapperTitleTabsItem ${
              isList
                ? `InfoWrapperTitleTabsItemActive`
                : `InfoWrapperTitleTabsItemNotActive`
            }`}
          >
            {textInfoTitle.tabs[InfoListActive.list]}
          </div>
          <div
            onClick={() => {
              if (isList) {
                toggleActiveList(InfoListActive.archive)
              }
            }}
            className={`InfoWrapperTitleTabsItem ${
              isArchive
                ? `InfoWrapperTitleTabsItemActive`
                : `InfoWrapperTitleTabsItemNotActive`
            }`}
          >
            {textInfoTitle.tabs[InfoListActive.archive]}
          </div>
        </div>
      </div>
      {canEdit && (
        <div className="CreateButtonWrapper">
          <ButtonPrimary disabled={false} onClick={openCreate}>
            <div className="CreateButtonLabel FlexRow">
              <PlusIcon />
              <div className="CreateButtonLabelText">
                {textInfoTitle.createLabel}
              </div>
            </div>
          </ButtonPrimary>
        </div>
      )}
    </div>
  )
}

export default InfoTitle
