import React, { useEffect, useState } from "react"
import { useRequestParams } from "api/hooks"

import { useDispatch } from "react-redux"
import InputComponent, { InputPasswordComponent } from "Components/Input"
import { ReactComponent as BetLogoIcon } from "Components/icons/bb-logo.svg"
import { StoreUserState } from "store/auth/types"
import { ButtonPrimary } from "Components/Button"

import makeLogin from "./helper"
import { StoreLoginData } from "./types"

import { loginUser } from "store/auth/actions"
import { useLocation } from "react-router-dom"

const Authentication = () => {
  const { developer, addDeveloperLink } = useRequestParams()
  const location = useLocation()

  const dispatch = useDispatch()
  const login: (data: StoreUserState) => void = data =>
    dispatch(loginUser(data))

  const [loginData, setLoginData] = useState<StoreLoginData>({
    username: ``,
    password: ``,
    errors: false,
    pending: false
  })

  useEffect(() => {
    if (location) {
      const token = location.search.split("=")[1]
      if (token) {
        makeLogin({
          addDeveloperLink,
          setLoginData,
          token,
          developer,
          login,
          loginData
        })
      }
    }
  }, [location])

  const makeAuth = () =>
    makeLogin({ loginData, setLoginData, login, developer, addDeveloperLink })

  return (
    <div className="LoginWrapper">
      <div className="LoginWrapperIcon">
        <BetLogoIcon />
      </div>
      <div className="LoginFormWrapper">
        <div className="LoginFormLabel">Сервис обучения сотрудников</div>
        <form>
          <div
            className="LoginFormFieldWrapper"
            style={{ paddingBottom: !loginData.errors ? `32px` : `` }}
          >
            <div className="LoginFormField">
              <InputComponent
                placeholder="Логин"
                value={loginData.username}
                style={{ border: loginData.errors ? `1px solid #ff0025` : `` }}
                onChange={({ target: { value } }) =>
                  setLoginData({ ...loginData, username: value, errors: false })
                }
                onPressEnter={makeAuth}
              />
            </div>
            <div className="LoginFormField">
              <InputPasswordComponent
                placeholder="Пароль"
                value={loginData.password}
                style={{ border: loginData.errors ? `1px solid #ff0025` : `` }}
                onChange={({ target: { value } }) =>
                  setLoginData({ ...loginData, password: value, errors: false })
                }
                onPressEnter={makeAuth}
              />
            </div>
          </div>
        </form>
        {loginData.errors && (
          <div className="ErrorMessage">Ошибка авторизации</div>
        )}
        <div className="LoginFormButtons">
          <ButtonPrimary
            disabled={false}
            loading={loginData.pending}
            className="LoginFormEnter"
            onClick={makeAuth}
          >
            Войти
          </ButtonPrimary>
        </div>
      </div>
    </div>
  )
}

export default Authentication
