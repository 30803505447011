export const howToGetConstants = {
  title: `Как добраться до обучающего центра`,
  address: {
    label: `Адрес:`,
    text: ` г. Москва, ул. Новоостаповская, д. 6Б.`
  },
  map: {
    defaultState: {
      center: [55.71981, 37.670106],
      zoom: 15,
      controls: []
    },
    width: `100%`,
    height: `424px`,
    zoomControl: { float: "right" },
    geometry: [55.71981, 37.670106],
    routeButton: { float: "right" },
    geolocationControl: { float: "left" }
  },
  text: {
    part_one: `Станция метро «Дубровка», первый вагон из центра, из стекляных дверей
        направо. Далее по прямой, до конца сквера, после чего перейти на
        противоположную сторону Новоостаповской улицы по пешеходному переходу и
        повернуть налево.`,
    part_two: `Необходимо пройти вдоль бизнес-центра «Seasons» (ориентиры: магазин
            «Красное&Белое», пиццерия «Папа Джонс», бар «LOOT», бар «ШТАБ»).`,
    part_three: `Между дверью с вывеской «Сыромания» и баром «Штаб» находится вход в
            обучающий центр. Для входа необходимо позвонить в домофон.`
  },
  img_description: `Фасад`,
  contacts: {
    label: `Контакты:`,
    call_center: {
      text: `При возникновении вопросов по записи на обучение обращайтесь в отдел коммуникаций наземной сети по тел. ${" "}`,
      phone: `+79629224286`,
      href: `tel:+79629224286`
    },
    mail: {
      label: `E-Mail:${" "}`,
      value: `communications@betboom.org`,
      href: `mailto:communications@betboom.org`
    },
    manager: {
      text: `По всем интересующим вопросам по программе обучения обращайтесь к
          менеджеру отдела обучения Горбатюк Лии по тел.${" "}`,
      phone: `+7(901)3512095`,
      href: `tel:+79013512095`
    }
  }
}
