import React from "react"
import { StoreDictionaryCourse } from "store/dictionary/types"
import { ReactComponent as OkIcon } from "Components/icons/check.svg"
import { ReactComponent as CrossIcon } from "Components/icons/redCross.svg"
import { ReactComponent as PendingIcon } from "Components/icons/pending-icon.svg"
import { ReactComponent as LearnsIcon } from "Components/icons/learns-icon.svg"
import moment from "moment"
import { Columns } from "Components/Table/types"

const getColumn = ({
  course
}: {
  course: StoreDictionaryCourse[]
}): Array<Columns> => {
  return [
    {
      title: "№ клуба",
      key: "hall",
      dataIndex: "hall",
      width: 80,
      withoutSorter: false
      // eslint-disable-next-line react/display-name
    },
    {
      title: "ФИО",
      key: "employee_name",
      dataIndex: "employee_name",
      width: 150,
      withoutSorter: true
      // eslint-disable-next-line react/display-name
    },
    {
      title: "Номер телефона",
      key: "employee_phone",
      dataIndex: "employee_phone",
      width: 100,
      withoutSorter: true
      // eslint-disable-next-line react/display-name
    },
    {
      title: "№ заявки",
      key: "application_id",
      dataIndex: "application_id",
      withoutSorter: false,
      width: 70,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div
          className="HistoryTableCourseNumber"
          style={{ paddingLeft: `16px` }}
        >
          {text}
        </div>
      )
    },
    {
      title: "Статус",
      key: "employee_status",
      dataIndex: "employee_status",
      withoutSorter: true,
      width: 80,
      // eslint-disable-next-line react/display-name
      render: (status: string | null) => (
        <div className="ReportUserStatus">
          {status === `pending` && (
            <>
              <PendingIcon />В ожидании
            </>
          )}
          {status === `complete` && (
            <>
              <OkIcon />
              Прошёл
            </>
          )}
          {status === `learns` && (
            <>
              <LearnsIcon />В процессе
            </>
          )}
          {status === `failed` && (
            <>
              <CrossIcon />
              Не завершил
            </>
          )}
          {status === `moderated` && <>На согласовании</>}
        </div>
      )
    },
    {
      title: "Тип обучения",
      key: "course_id",
      dataIndex: "course_id",
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (courseId: number | null) => {
        const currentCourse = course.find(item =>
          item.id === courseId ? item : null
        )
        return (
          <div>
            <div className="HistoryTableCourseLabel">
              {currentCourse ? currentCourse.name : ``}
            </div>
            <div className="HistoryTableCourseDescription">
              {currentCourse ? `(${currentCourse.description})` : ``}
            </div>
          </div>
        )
      }
    },
    {
      title: "Даты",
      key: "dates",
      dataIndex: "dates",
      withoutSorter: true,
      width: 100,
      // eslint-disable-next-line react/display-name
      render: (
        date: number[] | null,
        record: { date_start: number | null; date_end: number | null }
      ) => (
        <div>
          {record.date_start && record.date_end
            ? `${moment(record.date_start).format(`DD.MM`)} — ${moment
                .utc(record.date_end)
                .format(`DD.MM.YYYY`)}`
            : ``}
        </div>
      )
    },
    {
      title: "Результат",
      key: "employee_result",
      dataIndex: "employee_result",
      withoutSorter: true,
      width: 80,
      // eslint-disable-next-line react/display-name
      render: (
        result: number | null,
        status: { employee_status: string | null }
      ) => (
        <div>
          {status.employee_status === `failed`
            ? `Не допущен`
            : status.employee_status === `pending` ||
              status.employee_status === `learns`
            ? ``
            : result}
        </div>
      )
    }
  ]
}

export default getColumn
