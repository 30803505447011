import BetRequest from "api/request"
import { RouteComponentProps } from "react-router-dom"
import { StoreDevelopState } from "store/developer/types"

interface IGetCalendar {
  month: number
  year: number
  course_id: number | null
  logout: () => void
  history: RouteComponentProps["history"]
  setCourseData: (data: any) => void
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

const getCalendarData = ({
  month,
  year,
  course_id,
  history,
  logout,
  setCourseData,

  developer,
  addDeveloperLink
}: IGetCalendar) => {
  BetRequest({
    url: `course/graphs`,
    history,

    developer,
    addDeveloperLink,
    logout,
    method: `POST`,
    requestBody: {
      course_id,
      year,
      month
    }
  }).then(({ data }: any) => setCourseData(data))
}

export default getCalendarData
