/* eslint react/prop-types: "off" */
import React from "react"

import { DatePicker, TimePicker } from "antd"

import "moment/locale/ru"
import locale from "antd/es/date-picker/locale/ru_RU"
import "antd/dist/antd.css"

import { ReactComponent as DatePickerIcon } from "Components/icons/date-picker.svg"
import { ReactComponent as TimeIcon } from "./time.svg"

import {
  TimePickerComponentProps,
  DatePickerComponentProps,
  RangePickerComponentProps
} from "./types"
import { dateField, rangeField } from "./constants"

const { RangePicker } = DatePicker

const DatePickerComponent = ({
  value,
  error = false,
  showTime,
  onChange,
  style,
  ...rest
}: DatePickerComponentProps) => (
  <div className={`DatePickerWrapper ${error ? `ErrorPickerWrapper` : ``}`}>
    <DatePicker
      value={dateField(value, !!showTime)}
      locale={locale}
      allowClear={true}
      showTime={showTime}
      onChange={onChange}
      suffixIcon={<DatePickerIcon />}
      {...style}
      {...rest}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const TimePickerComponent = ({ ...rest }: TimePickerComponentProps) => {
  return (
    <div className="TimePickerComponentWrapper">
      <TimePicker
        format="HH:mm"
        showNow={false}
        allowClear={false}
        {...rest}
        suffixIcon={<TimeIcon />}
        popupClassName="TimePickerComponentPopupWrapper"
      />
    </div>
  )
}

export const RangePickerComponent = ({
  value,
  error = false,
  onChange,
  ...rest
}: RangePickerComponentProps) => (
  <div
    className={`RangePickerWrapper ${error ? `ErrorRangePickerWrapper` : ``}`}
  >
    <RangePicker
      value={rangeField(value)}
      style={{ width: `100%` }}
      allowClear={true}
      locale={locale}
      placeholder={[`Начало`, `Конец`]}
      onChange={changeData => {
        if (changeData) {
          const start = changeData[0] ? changeData[0].startOf(`day`) : null
          const end = changeData[1] ? changeData[1].endOf(`day`) : null
          onChange([start, end])
        } else onChange(changeData)
      }}
      suffixIcon={<DatePickerIcon />}
      {...rest}
    />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default DatePickerComponent
