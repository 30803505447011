import React from "react"
import { IDate } from "Containers/CreateComponent"
import { ReactComponent as DeleteIcon } from "Components/icons/delete_small.svg"

const PanelEmployeeList = ({ panelState, changePanelState }: IDate) => {
  const {
    employee: { list, data }
  } = panelState
  return (
    <div className="PanelEmployeeListWrapper">
      {!!data &&
        list.map(item => (
          <div
            key={`${data[item].name}_${item}`}
            className="PanelEmployeeListItem"
          >
            <div className="PanelEmployeeListItemName">{data[item].name}</div>
            <div
              onClick={() => {
                const newDate = data
                const newList = list
                newList.splice(
                  list.findIndex(listItem => listItem === item),
                  1
                )
                delete newDate[item]
                changePanelState({
                  ...panelState,
                  employee: { data: newDate, list: newList }
                })
              }}
              className="PanelEmployeeListItemDelete"
            >
              <DeleteIcon />
            </div>
          </div>
        ))}
    </div>
  )
}

export default PanelEmployeeList
