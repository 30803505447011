import React, { useEffect, useMemo, useState } from "react"
import { useSelector, useDispatch } from "react-redux"

import { useNow, useRequest } from "api/hooks"

import { getDictionaryStatus } from "store/dictionary/selector"
import { getDictionary } from "store/dictionary/actions"
import { StoreDictionary } from "store/dictionary/types"

import AllRouteComponents from "./RouteComponents"

import { CreateModalData, InfoListActive, InfoState } from "./types"
import { informationUrl } from "./constants"
import { checkIsCanEdit } from "./helper"

import CreateModal from "./CreateModal"

const { InfoTitle, InfoList } = AllRouteComponents

const InfoRoute = () => {
  const dispatch = useDispatch()
  const storeDictionary: (data: StoreDictionary) => void = data =>
    dispatch(getDictionary(data))
  const status = useSelector(getDictionaryStatus)

  const canEdit = useMemo(() => checkIsCanEdit(), [])
  const { timestamp, refresh } = useNow()

  const [activeList, toggleActiveList] = useState<InfoListActive>(
    InfoListActive.list
  )

  const [createModal, toggleCreateModal] = useState<CreateModalData>({
    isOpen: false,
    initial: undefined,
    refreshList: refresh
  })

  const [infoData, setInfoData] = useState<InfoState>({
    data: [],
    pending: false
  })
  const { request: getData } = useRequest({
    url: informationUrl[InfoListActive[activeList]],
    method: "POST",
    withHall: true
  })

  useEffect(() => {
    setInfoData({ ...infoData, pending: true })
    getData()
      .then(({ data }) => {
        setInfoData({ data, pending: false })
        storeDictionary({ status, course: data })
      })
      .catch(() => setInfoData({ ...infoData, pending: false }))
  }, [activeList, timestamp])
  return (
    <div className="InfoWrapper">
      {createModal.isOpen ? (
        <CreateModal
          close={() =>
            toggleCreateModal(prev => ({
              ...prev,
              initial: undefined,
              isOpen: false
            }))
          }
          refreshList={refresh}
          initial={createModal.initial}
        />
      ) : (
        <div>
          <InfoTitle
            canEdit={canEdit}
            toggleActiveList={toggleActiveList}
            activeList={activeList}
            openCreate={() =>
              toggleCreateModal(prev => ({ ...prev, isOpen: true }))
            }
          />
          <InfoList
            infoData={infoData}
            canEdit={canEdit}
            refresh={refresh}
            toggleEditModal={toggleCreateModal}
          />
        </div>
      )}
    </div>
  )
}

export default InfoRoute
