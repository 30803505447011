import React, { useState } from "react"
import TableComponent from "Components/Table"
import { ReactComponent as FilterEmptyIcon } from "Components/icons/filtersEmptyTable.svg"
import { IDate } from "../index"
import { getColumns } from "./helper"
import { Columns } from "Components/Table/types"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"
import InputComponent from "Components/Input"

const Employee = ({ panelState, changePanelState }: IDate) => {
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user:
    | { token: string; hall: number; role: string }
    | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  const isAdmin =
    !!user && (user.role === `ROLE_TRAINING` || user.role === `ROLE_SUPPORT`)
  const columns: Array<Columns> = getColumns(panelState, changePanelState)
  const [hallSearch, changeHallSearch] = useState<string | undefined>(undefined)
  const extraRequestBody = {
    course_id:
      panelState && panelState.course ? panelState.course.id : undefined,
    hall: isAdmin ? hallSearch : undefined
  }

  return (
    <div>
      <div className="EmployeeHeaderWrapper">
        <div className="CreateModalTitle">Выберите сотрудников обучения:</div>
        {isAdmin && (
          <div className="EmployeeSearchWrapper">
            <InputComponent
              allowClear
              value={hallSearch}
              placeholder="Введите номер клуба"
              style={{ width: `215px` }}
              suffix={<SearchIcon />}
              onChange={({ target: { value } }) => {
                changeHallSearch(value)
              }}
            />
          </div>
        )}
      </div>
      <div className="EmployeeTableWrapper">
        {!hallSearch && isAdmin ? (
          <div className="EmployeeTableComponentWrapper">
            <div className="IconWrapperSvg">
              <FilterEmptyIcon />
            </div>
            <div className="EmptyTableIconDescription">
              Введите клуб для поиска сотрудников
            </div>
          </div>
        ) : (
          <TableComponent
            dataSource="employees"
            columnsData={columns}
            extraRequestBody={extraRequestBody}
            scrollX={columns.length * 150}
            scrollY="calc(100vh - 500px)"
            classNameForPagination="EmployeeTablePaginationWrapper"
            scrollWithoutPagination={true}
            withHall={!isAdmin}
            refresh={isAdmin ? [hallSearch] : undefined}
          />
        )}
      </div>
    </div>
  )
}

export default Employee
