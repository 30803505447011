import React from "react"
import { Pagination } from "antd"

interface IPaginationComponent {
  page: number
  total: number
  pending: boolean
  changePage: (value: number) => void
}

const PaginationComponent = ({
  page,
  changePage,
  total,
  pending
}: IPaginationComponent) => {
  return (
    <div className="Pagination">
      <Pagination
        style={{ color: `black !important` }}
        defaultCurrent={page}
        current={page}
        total={total}
        pageSize={10}
        hideOnSinglePage
        showSizeChanger={false}
        onChange={value => changePage(value)}
        disabled={pending}
      />
    </div>
  )
}

export default PaginationComponent
