import React, { useState, useEffect } from "react"
import { useHistory } from "react-router-dom"
// lib start
import Paper from "@material-ui/core/Paper"
import TableCell from "@material-ui/core/TableCell"
import { ViewState, EditingState } from "@devexpress/dx-react-scheduler"
import {
  Scheduler,
  MonthView,
  Appointments,
  Toolbar,
  DateNavigator,
  EditRecurrenceMenu,
  DragDropProvider
} from "@devexpress/dx-react-scheduler-material-ui"
// lib end
import moment, { Moment } from "moment"
import getSchedulerData, { editDragDrop } from "./helper"
import { schedulerText } from "./constant"

import CreateGraph from "./CreateGraph"
import { message } from "antd"

import { useNow, useRequestParams } from "api/hooks"

import { Popover } from "antd"
import { CreateFormState } from "./CreateGraph/types"
import { errorCanvas } from "Components/Messages"

type AppointmentContentProps = Appointments.AppointmentContentProps
type AppointmentProps = Appointments.AppointmentProps

interface AppointmentPropsWithModal extends AppointmentProps {
  toggleModal: (data: ICreateState) => void
  createModal: ICreateState
}

const DayScaleCell = (props: any) => (
  <MonthView.DayScaleCell
    {...props}
    className="DayScaleCellWrapper"
    style={{ textAlign: "center", fontWeight: "bold" }}
  />
)

interface ICustomNavigator extends DateNavigator.RootProps {
  setCurrentPosition: (data: number) => void
  currentPosition: number
}

interface ICreateState {
  active: boolean
  dateStart: null | number | Moment
  isEditable: boolean
  inProgress: boolean
  initial: CreateFormState
}

const offset = new Date().getTimezoneOffset() * 60000

const MonthCustomNavigator = (props: ICustomNavigator) => {
  const {
    navigationButtonComponent,
    setCurrentPosition,
    currentPosition
  } = props
  const NavigatorButton = navigationButtonComponent
  return (
    <div className="MonthCustomNavigatorWrapper">
      <NavigatorButton
        type="back"
        onClick={() => {
          const value = moment(currentPosition)
            .subtract(1, `month`)
            .valueOf()
          setCurrentPosition(value)
        }}
      />
      <div className="MonthCustomNavigatorTextWrapper">
        {moment(currentPosition).format(`MMMM YYYY`)}
      </div>
      <NavigatorButton
        type="forward"
        onClick={() => {
          const value = moment(currentPosition)
            .add(1, `month`)
            .valueOf()
          setCurrentPosition(value)
        }}
      />
    </div>
  )
}

interface ICellBase extends MonthView.TimeTableCellProps {
  toggleModal: (data: ICreateState) => void
  createModal: ICreateState
}

// eslint-disable-next-line react/display-name
const CellBase = React.memo(({ ...props }: ICellBase) => {
  const day = moment(props.startDate).date()
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  const isEditableGraph =
    props.endDate &&
    moment(props.endDate).valueOf() > moment().valueOf() &&
    !isSupport

  return (
    <TableCell
      className={`SchedulerCellWrapper ${
        isEditableGraph ? `SchedulerActiveCellWrapper` : ``
      }`}
      style={{
        backgroundColor: props.otherMonth ? `#FBFBFB` : `#fff`,
        color: props.otherMonth ? `#AAB4B5` : `#1D1F21`
      }}
      onClick={() => {
        if (isEditableGraph) {
          props.toggleModal({
            ...props.createModal,
            active: true,
            dateStart: moment(props.startDate).valueOf(),
            isEditable: isEditableGraph,
            inProgress: false,
            initial: {
              id: undefined,
              course_id: null,
              name: undefined,
              students: undefined,
              time_start: null,
              date_start: null,
              date_end: null,
              employees: []
            }
          })
        }
      }}
    >
      <div
        className="SchedulerCellDateWrapper"
        style={{ backgroundColor: props.today ? `yellow` : `` }}
      >
        {day}
      </div>
    </TableCell>
  )
})

const Appointment = ({
  toggleModal,
  createModal,
  ...restProps
}: AppointmentPropsWithModal) => {
  const { data } = restProps
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  return (
    <div className="AppointmentWrapper">
      <Appointments.Appointment
        {...restProps}
        style={{
          backgroundColor: data.color,
          borderRadius: "8px"
        }}
        onClick={() => {
          const currentValue = moment().valueOf()
          const isEditableGraph =
            !!data.startDate &&
            moment(data.startDate).valueOf() > moment().valueOf() &&
            !isSupport
          const inProgress =
            moment(data.startDate)
              .startOf(`day`)
              .valueOf() < currentValue &&
            currentValue <
              moment(data.endDate)
                .endOf(`day`)
                .valueOf()
          const initialTimeStart = data.startDate
          toggleModal({
            active: true,
            dateStart: moment.utc(data.startDate).valueOf(),
            isEditable: isEditableGraph,
            inProgress: inProgress,
            initial: {
              id: data.id,
              graph_id: Number(data.id),
              course_id: Number(data.course_id),
              name: data.title,
              students: data.students,
              time_start: moment(initialTimeStart),
              date_start: moment(data.startDate),
              date_end: moment(data.endDate),
              employees: data.employees
            }
          })
        }}
      />
    </div>
  )
}

const AppointmentContent = ({ ...restProps }: AppointmentContentProps) => {
  const { data } = restProps
  const startTimeWithOffset = moment(data.startDate).valueOf() - offset
  return (
    <Popover
      arrowContent={<span />}
      content={
        <div className="AvailableEntrollmentWrapper">
          <span style={{ textAlign: `center`, display: `block` }}>
            Доступно
          </span>
          <span
            style={{ textAlign: `center`, display: `block` }}
          >{`${data.students - data.booking_students}/${data.students}`}</span>
        </div>
      }
      placement="topRight"
      trigger="hover"
    >
      <div className="AppointmentTextWrapper">
        <div
          style={{
            position: `fixed`,
            left: `50%`,
            top: `50%`,
            transform: `translate(-50%, -50%)`,
            textOverflow: `ellipsis`,
            overflow: `hidden`,
            width: `90%`,
            whiteSpace: `nowrap`
          }}
        >
          {`${data.title}. Начало в ${moment
            .utc(startTimeWithOffset)
            .format(`HH:mm`)}`}
        </div>
      </div>
    </Popover>
  )
}

const DragDropAppointmentComponent = (
  props: DragDropProvider.DraftAppointmentProps
) => (
  <div
    className="DragDropAppointmentComponentWrapper"
    {...props}
    style={{ ...props.style, backgroundColor: props.data.color }}
  >
    <div>{props.data.title}</div>
  </div>
)

const DragDropSource = (props: DragDropProvider.SourceAppointmentProps) => (
  <div className="DragDropSourceWrapper" {...props}>
    <div>{props.data.title}</div>
  </div>
)

const SchedulerComponent = () => {
  const { history, developer, addDeveloperLink, logout } = useRequestParams()
  const [currentPosition, setCurrentPosition] = useState<number>(
    moment().valueOf()
  )
  const [graphData, setGraphData] = useState<any[]>([])
  const { timestamp, refresh } = useNow()
  useEffect(() => {
    getSchedulerData({
      logout,
      history,
      month: moment(currentPosition).month() + 1,
      year: moment(currentPosition).year(),
      setGraphData,
      developer,
      addDeveloperLink,
      graphData
    })
  }, [currentPosition, timestamp])
  const changeData = ({ changed }: any) => {
    if (changed) {
      const oldData = graphData.filter(appointment => changed[appointment.id])
      const isEditable =
        oldData &&
        oldData[0] &&
        oldData[0].startDate &&
        moment(oldData[0].startDate).valueOf() > moment().valueOf()
      const newData = graphData.map((appointment: any) =>
        changed[appointment.id]
          ? {
              ...appointment,
              ...changed[appointment.id],
              startDate: moment(changed[appointment.id].startDate).valueOf(),
              endDate: moment(changed[appointment.id].endDate).valueOf()
            }
          : appointment
      )
      const graph_id: number =
        changed && Object.keys(changed) ? Number(Object.keys(changed)[0]) : 0
      if (isEditable) {
        editDragDrop({
          logout,
          history,
          developer,
          addDeveloperLink,
          graph_id,
          date_start: moment
            .utc(changed[graph_id].startDate)
            .local()
            .valueOf(),
          date_end: moment
            .utc(changed[graph_id].endDate)
            .local()
            .valueOf()
        })
          .then(() => setGraphData(newData || graphData))
          .catch(({ response }) => {
            errorCanvas({
              errors: response.data.errors,
              description: response.data.description
            })
          })
      } else {
        errorCanvas({ errors: [], description: schedulerText.editError })
      }
    }
  }

  const [createModal, toggleModal] = useState<ICreateState>({
    active: false,
    dateStart: null,
    isEditable: true,
    inProgress: false,
    initial: {
      id: undefined,
      course_id: null,
      name: undefined,
      students: undefined,
      time_start: null,
      date_start: null,
      date_end: null,
      employees: []
    }
  })
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  return (
    <div className="SchedulerComponentWrapper">
      <Paper>
        <Scheduler data={graphData} locale="ru-RU" firstDayOfWeek={1}>
          <EditingState onCommitChanges={changeData} />
          <ViewState currentDate={currentPosition} />

          <MonthView
            timeTableCellComponent={props => (
              <CellBase
                toggleModal={toggleModal}
                createModal={createModal}
                {...props}
              />
            )}
            dayScaleCellComponent={DayScaleCell}
          />
          <Appointments
            appointmentComponent={props => (
              <Appointment
                {...props}
                toggleModal={toggleModal}
                createModal={createModal}
              />
            )}
            appointmentContentComponent={props => (
              <AppointmentContent {...props} />
            )}
          />
          <Toolbar />
          <DateNavigator
            rootComponent={props => (
              <MonthCustomNavigator
                setCurrentPosition={setCurrentPosition}
                currentPosition={currentPosition}
                {...props}
              />
            )}
          />
          <EditRecurrenceMenu />
          {!isSupport && (
            <DragDropProvider
              allowResize={() => false}
              draftAppointmentComponent={props => (
                <DragDropAppointmentComponent {...props} />
              )}
              sourceAppointmentComponent={props => (
                <DragDropSource {...props} />
              )}
            />
          )}
        </Scheduler>
      </Paper>
      {createModal.active && (
        <div>
          <CreateGraph
            close={() => toggleModal({ ...createModal, active: false })}
            refresh={refresh}
            dateStart={moment.utc(createModal.dateStart)}
            isEditable={createModal.isEditable}
            inProgress={createModal.inProgress}
            initial={createModal.initial}
          />
        </div>
      )}
    </div>
  )
}

export default SchedulerComponent
