import axios, { Method, AxiosPromise } from "axios"
import { ERROR_PAGE, SERVER_ERROR_PAGE } from "Containers/BaseLayout/constants"
import { RouteComponentProps } from "react-router-dom"
import { StoreDevelopState } from "store/developer/types"

interface IBetRequest {
  url: string
  method: Method | undefined
  logout: () => void
  requestBody?: any
  params?: unknown
  history: RouteComponentProps["history"]
  withHall?: boolean
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

const BetRequest = ({
  url,
  method,
  logout,
  withHall,
  requestBody,
  params,
  developer,
  addDeveloperLink,
  history
}: IBetRequest): AxiosPromise => {
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user: { token: string; hall: number } | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage
  return axios(`${process.env.REACT_APP_AUTH_URL}/${url}`, {
    method,
    withCredentials: true,
    data: withHall
      ? { ...requestBody, hall: user ? user.hall : requestBody.hall }
      : requestBody,
    params,
    headers: {
      "Content-Type": `application/json`,
      token: user ? user.token : ``
    }
  })
    .then(response => {
      localStorage.removeItem(`error`)
      const { headers } = response
      if (headers[`x-debug-token-link`]) {
        addDeveloperLink({
          ...developer,
          [url]: { url, link: headers[`x-debug-token-link`] }
        })
      }
      return response
    })
    .catch(error => {
      if (history && error.response) {
        localStorage.setItem(`error`, error.response.status)
        if (error.response.status === 404) {
          localStorage.setItem(`error`, `404`)
          history.push(ERROR_PAGE)
        }
        if (error.response.status === 403) {
          localStorage.setItem(`error`, `403`)
          logout()
        }
        if (error.response.status === 500 || error.response.status === 502) {
          history.push(SERVER_ERROR_PAGE)
        }
        if (error.response.status === 504) {
          localStorage.setItem(`error`, `404`)
          history.push(SERVER_ERROR_PAGE)
        }
      }
      throw error
    })
}

export default BetRequest
