import { InfoListActive } from "./types"

export const archiveLabel = `Архивированный`
export const hideLabel = `Скрытый`

export const textInfoTitle = {
  componentTitle: "Курсы",
  createLabel: "Новый курс",
  tabs: {
    [InfoListActive.list]: `Активные`,
    [InfoListActive.archive]: `Архив`
  }
}

export const informationUrl = {
  list: "course/list",
  archive: "course/archive/list"
}
