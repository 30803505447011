import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import moment, { Moment } from "moment"
import { RouteComponentProps } from "react-router-dom"
import StatusComponent from "Components/Status"
import ButtonDefault from "Components/Button"
import {
  StoreDictionaryCourse,
  StoreDictionaryStatus
} from "store/dictionary/types"
import { IEmployeeData } from "Containers/CreateComponent/Employee/helper"
import { ReactComponent as CancelIcon } from "Components/icons/redBlock.svg"
import getRequestInfo, { getAdminCourse } from "./helper"
import { CancelModalData } from "Containers/History/types"
import { LOGOUT } from "store/auth/constants"
import { ButtonPrimary } from "Components/Button"
import { StoreDevelopState } from "store/developer/types"
import { IRootState } from "store"
import { ADD_LINK } from "store/developer/constants"
import SelectComponent from "Components/Select"
import { Select } from "antd"

const { Option } = Select

type MoreModalType = {
  id: null | number
  isAdmin: boolean
  history: RouteComponentProps["history"]
  status: StoreDictionaryStatus[]
  course: StoreDictionaryCourse[]
  logout: () => void
  toggleCancelModal: (data: CancelModalData) => void
  close: () => void
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export interface IGraphList {
  graph_id: number
  name: string
  date_start: number
  date_end: number
  students: number
  booking_students: number
}

export interface IMoreModalData {
  hall: number | null
  status_id: number | null
  course_id: number | null
  date_start: number | null
  date_end: number | null
  lesson_duration: number | null
  employees: IEmployeeData[] | undefined[]
  date_hired?: number | null
  graph: { name: string | null }
  graph_id: number | null
  time_start: Moment | null | undefined
}

export interface IMoreModal {
  pending: boolean
  data: IMoreModalData
}

const MoreModal = ({
  id,
  isAdmin,
  history,
  status,
  course,
  logout,
  close,
  toggleCancelModal,
  developer,
  addDeveloperLink
}: MoreModalType) => {
  const [requestData, changeRequestData] = useState<IMoreModal>({
    pending: false,
    data: {
      hall: null,
      status_id: null,
      course_id: null,
      date_hired: null,
      date_start: null,
      lesson_duration: null,
      date_end: null,
      employees: [],
      graph: { name: null },
      graph_id: null,
      time_start: undefined
    }
  })
  useEffect(() => {
    getRequestInfo({
      id,
      history,
      logout,
      requestData,
      changeData: changeRequestData,
      developer,
      addDeveloperLink
    })
  }, [id])
  const currentStatus = status.find(
    item => item.id === requestData.data.status_id
  )
  const currentCourse = course.find(
    item => item.id === requestData.data.course_id
  )
  const currentGraphId = requestData.data.graph_id
  const adminEditDate =
    currentStatus && currentStatus.id === 1 && isAdmin && !currentGraphId
  const [graphList, setGraphList] = useState<{
    data: Array<IGraphList>
    pending: boolean
  }>({
    data: [],
    pending: false
  })
  useEffect(() => {
    if (requestData.data.course_id && adminEditDate) {
      getAdminCourse({
        id: requestData.data.course_id,
        history,
        logout,
        graphList,
        developer,
        addDeveloperLink,
        setGraphList
      })
    }
  }, [requestData.data.course_id])
  const [adminGraph, changeAdminGraph] = useState<number | null | undefined>(
    null
  )

  return (
    <div>
      <div className="MoreModalOverflow" onClick={close} />
      <div className="MoreModalWrapper">
        <div className="MoreModalHeader">
          <div className="MoreModalTitle">Заявка №{id}</div>
          <div className="MoreModalStatus">
            {currentStatus && (
              <StatusComponent
                label={currentStatus.name || ``}
                color={currentStatus.color || `#fff`}
                id={currentStatus.id}
              />
            )}
          </div>
        </div>
        <div className="MoreModalContent">
          {isAdmin && (
            <div
              className="MoreModalContentTitle"
              style={{ margin: `-16px 0 -8px 0` }}
            >
              Клуб №{requestData.data.hall}
            </div>
          )}
          <div className="MoreModalContentTitle">Тип обучения</div>
          <div className="MoreModalContentData">
            {currentCourse
              ? `${currentCourse.name || ``} (${currentCourse.description ||
                  ``})`
              : ``}
          </div>
          <div className="MoreModalContentRectangle" />
          <div className="MoreModalContentTitle">Даты обучения</div>
          <div className="MoreModalContentData">
            {adminEditDate && (
              <div>
                <SelectComponent
                  name="training_type"
                  style={{ width: `100%` }}
                  placeholder="Тип обучения"
                  value={adminGraph}
                  onChange={(value: number | null) => changeAdminGraph(value)}
                >
                  {graphList.data.map(item => (
                    <Option
                      key={`${item.graph_id}_${item?.date_end}`}
                      value={item.graph_id || 0}
                    >
                      <div className="TrainingTypeOptionsWrapper">
                        <div className="HistoryTableCourseLabel">
                          {item.name}
                        </div>
                        <div className="HistoryTableCourseDescription">
                          <span>{`${
                            item.date_start
                              ? moment(item.date_start).format(`DD.MM`)
                              : `*`
                          } — ${
                            item.date_end
                              ? moment.utc(item.date_end).format(`DD.MM.YYYY`)
                              : `*`
                          } (с ${
                            item.date_start
                              ? moment(item.date_start).format(`HH:mm`)
                              : `*`
                          })`}</span>
                        </div>
                      </div>
                    </Option>
                  ))}
                </SelectComponent>
                <div>
                  <ButtonPrimary
                    style={{ width: `100%`, height: `40px`, marginTop: `8px` }}
                    disabled={!adminGraph}
                    onClick={() => {
                      toggleCancelModal({
                        active: true,
                        status: 2,
                        id,
                        isAdmin,
                        graphId: adminGraph
                      })
                    }}
                  >
                    Сохранить
                  </ButtonPrimary>
                </div>
              </div>
            )}
            {currentGraphId && (
              <span>{`${
                requestData.data.date_start
                  ? moment(requestData.data.date_start).format(`DD.MM`)
                  : `*`
              } — ${
                requestData.data.date_end
                  ? moment.utc(requestData.data.date_end).format(`DD.MM.YYYY`)
                  : `*`
              } (с ${
                requestData.data.date_start
                  ? moment(requestData.data.date_start).format(`HH:mm`)
                  : `*`
              })`}</span>
            )}
            <br />
            {!adminEditDate && (
              <span>
                {requestData.data.lesson_duration} часов/день{" "}
                {requestData.data.graph
                  ? `, ${requestData.data.graph.name}`
                  : ``}
              </span>
            )}
          </div>
          <div className="MoreModalContentRectangle" />
          <div className="MoreModalContentTitle">Сотрудники</div>
          <div className="MoreModalContentEmployeeWrapper">
            {requestData.data.employees.map(
              (item: IEmployeeData | undefined) => (
                <div
                  className="MoreModalContentEmployeeItem"
                  key={`${item?.id || 0}_${item?.name}`}
                >
                  {item?.name}
                </div>
              )
            )}
          </div>
          {isAdmin && (
            <div>
              <div className="MoreModalContentTitle">Дата трудоустройства</div>
              <div className="MoreModalContentEmployeeWrapper">
                <div className="MoreModalContentEmployeeItem">
                  {requestData.data.date_hired
                    ? moment(requestData.data.date_hired).format(`DD.MM.YYYY`)
                    : ``}
                </div>
              </div>
            </div>
          )}
        </div>
        <div className="MoreModalFooter">
          <div className="MoreModalContentRectangle" />
          <div className="MoreModalFooterButtonsWrapper">
            {currentStatus && currentStatus.id !== 3 && currentStatus.id !== 4 && (
              <div
                className="MoreModalFooterCancelWrapper"
                onClick={() => {
                  toggleCancelModal({ active: true, status: 3, id, isAdmin })
                }}
              >
                <CancelIcon />
                <div className="MoreModalFooterCancelText">Отменить заявку</div>
              </div>
            )}
            <ButtonDefault
              style={{ width: `91px`, height: `34px` }}
              disabled={false}
              onClick={close}
            >
              Закрыть
            </ButtonDefault>
          </div>
        </div>
      </div>
    </div>
  )
}

const connector = connect(
  (state: IRootState) => ({
    developer: state.developer
  }),
  {
    addDeveloperLink: (data: StoreDevelopState) => ({ type: ADD_LINK, data }),
    logout: () => ({ type: LOGOUT })
  }
)

export default connector(MoreModal)
