import React from "react"
import "./App.less"
import * as Sentry from "@sentry/browser"
import { ConfigProvider } from "antd"
import ruRu from "antd/es/locale/ru_RU"
import BaseLayout from "./Containers/BaseLayout"

Sentry.init({
  dsn: "https://d7b3eb6bfb82bb64509f6a7567445c85@sentry.offline.shelopes.com/5"
})

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
const App = () => {
  return (
    <ConfigProvider locale={ruRu}>
      <div className="App">
        <BaseLayout />
      </div>
    </ConfigProvider>
  )
}

export default App
