import React from "react"

import {
  EmployeesType,
  onChangeNewEmployee,
  NewEmployeeItemProps
} from "../types"
import { hideSnils, checkNumber } from "../helper"

import { employeesFields } from "./constants"

import { Input, Form } from "antd"

export const EmployeesList = ({
  employees
}: {
  employees: EmployeesType[]
}) => {
  return (
    <div>
      {employees.map(item => (
        <div key={`${item.id}_${item.name}`} className="FlexRow">
          <div className="CreateGraphModalEmployeesHall">Клуб №{item.hall}</div>
          <div className="CreateGraphModalEmployeesName">{item.name}</div>
          <div className="CreateGraphModalEmployeesPhone">{item.phone}</div>
          <div className="CreateGraphModalEmployeesSnils">
            {hideSnils(item.snils || ``)}
          </div>
        </div>
      ))}
    </div>
  )
}

export const NewEmployeeItem = ({
  field,
  form,
  clear
}: NewEmployeeItemProps) => {
  const onChange = ({ value, key, isNumber, name }: onChangeNewEmployee) => {
    const fields = form.getFieldsValue()
    const { employees } = fields
    const newValueNum =
      checkNumber(value) || !value ? value : value.slice(0, -1)
    Object.assign(employees[key], {
      ...employees[key],
      [`${name}`]: isNumber ? newValueNum : value
    })
    form.setFieldsValue({ employees })
    clear(employees)
  }
  return (
    <div className="FlexRow">
      <div className="CreateGraphModalEmployeesHall FlexRow">
        <span>{employeesFields.hall.label}</span>
        <Form.Item
          name={[field.name, employeesFields.hall.name]}
          validateTrigger={employeesFields.hall.validateTrigger}
          rules={employeesFields.hall.rules}
        >
          <Input
            className="CreateGraphModalEmployeesInput"
            placeholder={employeesFields.hall.placeholder}
            bordered={false}
            onChange={({ target: { value } }) => {
              onChange({
                value,
                key: field.key,
                isNumber: true,
                name: employeesFields.hall.name
              })
            }}
          />
        </Form.Item>
      </div>
      <div className="CreateGraphModalEmployeesName">
        <Form.Item
          name={[field.name, employeesFields.name.name]}
          validateTrigger={employeesFields.name.validateTrigger}
          rules={employeesFields.name.rules}
        >
          <Input
            className="CreateGraphModalEmployeesInput"
            placeholder={employeesFields.name.placeholder}
            bordered={false}
            onChange={({ target: { value } }) => {
              onChange({
                value,
                key: field.key,
                isNumber: false,
                name: employeesFields.name.name
              })
            }}
          />
        </Form.Item>
      </div>
      <div className="CreateGraphModalEmployeesPhone FlexRow">
        <span>{employeesFields.phone.label}</span>
        <Form.Item
          name={[field.name, employeesFields.phone.name]}
          validateTrigger={employeesFields.phone.validateTrigger}
          rules={[
            ...employeesFields.phone.rules,
            {
              validator: async (_, snils) => {
                if (
                  !snils ||
                  snils.length < employeesFields.phone.extraValidate.count
                ) {
                  return Promise.reject(
                    new Error(employeesFields.phone.extraValidate.text)
                  )
                }
              }
            }
          ]}
        >
          <Input
            className="CreateGraphModalEmployeesInput"
            placeholder={employeesFields.phone.placeholder}
            bordered={false}
            maxLength={employeesFields.phone.extraValidate.count}
            onChange={({ target: { value } }) => {
              onChange({
                value,
                key: field.key,
                isNumber: true,
                name: employeesFields.phone.name
              })
            }}
          />
        </Form.Item>
      </div>
      <div className="CreateGraphModalEmployeesSnils">
        <Form.Item
          name={[field.name, employeesFields.snils.name]}
          validateTrigger={employeesFields.snils.validateTrigger}
          rules={[
            ...employeesFields.snils.rules,
            {
              validator: async (_, snils) => {
                if (
                  !snils ||
                  snils.length < employeesFields.snils.extraValidate.count
                ) {
                  return Promise.reject(
                    new Error(employeesFields.snils.extraValidate.text)
                  )
                }
              }
            }
          ]}
        >
          <Input
            className="CreateGraphModalEmployeesInput"
            placeholder={employeesFields.snils.placeholder}
            bordered={false}
            maxLength={employeesFields.snils.extraValidate.count}
            onChange={({ target: { value } }) => {
              onChange({
                value,
                key: field.key,
                isNumber: true,
                name: employeesFields.snils.name
              })
            }}
          />
        </Form.Item>
      </div>
    </div>
  )
}
