import { Columns } from "Components/Table/types"
import { CheckBoxComponent } from "Components/CheckBox"
import { IEditData } from "Containers/History/EditModal"
import React from "react"
import PhoneInputComponent from "Components/PhoneInput"

export interface IEmployeeData {
  id: number
  name: string
  position: string
  hired: number
  phone: string
  fired: boolean
}

export const getColumns = (
  initialData: IEditData,
  changeInitialData: (data: IEditData) => void
): Array<Columns> => {
  const disableAll = initialData.employees.list.length === initialData.students
  const {
    employees: { list, data }
  } = initialData
  return [
    {
      title: null,
      key: `check`,
      dataIndex: `check`,
      withoutSorter: true,
      width: 7
    },
    {
      title: `Сотрудники`,
      key: `name`,
      dataIndex: `name`,
      withoutSorter: false,
      render: (
        text: string | undefined,
        record: IEmployeeData,
        index: number | undefined
      ) => {
        const { id } = record
        const isChecked = list.findIndex(item => item === id) >= 0
        return (
          <div className="EmployeeTableCheckColumnWrapper">
            <CheckBoxComponent
              checked={isChecked}
              disabled={!isChecked && disableAll}
              onChange={({
                target: { checked }
              }: {
                target: { checked: any }
              }) => {
                const newList = list
                const newData = data || {}
                if (checked) {
                  changeInitialData({
                    ...initialData,
                    employees: {
                      list: newList.concat(id),
                      data: { ...newData, [id]: record }
                    }
                  })
                } else {
                  newList.splice(
                    list.findIndex(item => item === id),
                    1
                  )
                  delete newData[id]
                  changeInitialData({
                    ...initialData,
                    employees: {
                      list: newList,
                      data: newData
                    }
                  })
                }
              }}
              label=""
            />
            <div
              className={`EmployeeTableCheckColumnText ${
                disableAll && !isChecked ? `EmployeeTableTextDisabled` : ``
              }`}
            >
              {text}
            </div>
          </div>
        )
      }
    },
    {
      title: `Дожность`,
      key: `position`,
      dataIndex: `position`,
      withoutSorter: true,
      // eslint-disable-next-line react/display-name
      render: (
        text: string | undefined,
        record: IEmployeeData,
        index: number | undefined
      ) => {
        const { id } = record
        const isChecked = list.findIndex(item => item === id) >= 0
        return (
          <div
            className={
              disableAll && !isChecked ? `EmployeeTableTextDisabled` : ``
            }
          >
            {text}
          </div>
        )
      }
    },
    {
      title: `Телефон`,
      key: `phone`,
      dataIndex: `phone`,
      withoutSorter: true,
      width: 160,
      // eslint-disable-next-line react/display-name
      render: (
        text: string | undefined,
        record: IEmployeeData,
        index: number | undefined
      ) => {
        const { id } = record
        const isChecked = list.findIndex(item => item === id) >= 0
        return (
          <div
            className={
              disableAll && !isChecked ? `EmployeeTableTextDisabled` : ``
            }
          >
            <PhoneInputComponent phone={text} employeeId={id} />
          </div>
        )
      }
    }
  ]
}
