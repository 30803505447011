import React, { useState, useEffect } from "react"
import { ReactComponent as SuccessIcon } from "Components/icons/successEdit.svg"
import { ReactComponent as CloseIcon } from "Components/icons/close.svg"

type SuccessType = {
  close: () => void
  refresh: () => void
}

const SuccessEdit = ({ close, refresh }: SuccessType) => {
  const [seconds, setSeconds] = useState<number>(2)
  useEffect(() => {
    if (seconds > 0) {
      setTimeout(() => setSeconds(seconds - 1), 1000)
    } else {
      close()
      refresh()
    }
  })
  return (
    <div className="SuccessEditWrapper">
      <div
        className="SuccessEditClose"
        onClick={() => {
          close()
          refresh()
        }}
      >
        <CloseIcon />
      </div>
      <div className="SuccessEditTitle">Заявка отредактирована!</div>
      <div className="SuccessEditContentWrapper">
        <div className="IconSuccessWrapperSvg">
          <SuccessIcon />
          <div className="SuccessEditTimer">
            *Окно с успешным редактированием закроется через {seconds} сек
          </div>
        </div>
      </div>
    </div>
  )
}

export default SuccessEdit
