import { useEffect, useState, useRef } from "react"
import { RouteComponentProps, useHistory } from "react-router-dom"
import BetRequest from "api/request"

import { UseTableProps, UseTableData, UseTableReturn } from "./types"
import { initialTableData } from "./constants"

const getOrder = (order: string | undefined) => {
  if (order === `ascend`) return `asc`
  if (order === `descend`) return `desc`
  return null
}

const useTable = ({
  dataSource,
  waitRequest,
  mockData,
  scrollWithoutPagination,
  filter,
  filterDeps,
  refresh,
  logout,
  toggleTableEmpty,
  EmptyTable,
  getTableData,
  withHall,
  developer,
  extraRequestBody,
  addDeveloperLink
}: UseTableProps): UseTableReturn => {
  const [tableData, setTableData] = useState<UseTableData>(initialTableData)
  const history: RouteComponentProps["history"] = useHistory()
  const extraBody = extraRequestBody ? extraRequestBody : {}

  const refreshCond = refresh || []

  const changePage = (value: number) =>
    setTableData({ ...tableData, page: value })
  const changeSort = (value: {
    field: string | undefined
    order: string | undefined
  }) => setTableData({ ...tableData, sort: value, page: 1 })

  const makeRequest = (page?: number | undefined) => {
    if (!waitRequest) {
      setTableData({
        ...tableData,
        status: { pending: true, error: false, completed: false }
      })
      const requestBody = {
        ...extraBody,
        filter: filter ? { ...filter } : undefined,
        limit: scrollWithoutPagination ? undefined : 10,
        offset: scrollWithoutPagination
          ? undefined
          : ((page || tableData.page || 1) - 1) * 10,
        sort: tableData.sort.order
          ? { ...tableData.sort, order: getOrder(tableData.sort.order) }
          : undefined
      }

      BetRequest({
        method: `POST`,
        url: dataSource,
        requestBody,
        history,
        logout,
        developer,
        addDeveloperLink,
        withHall
      })
        .then(({ data, headers }) => {
          if (getTableData) getTableData(data)
          if (
            headers[`total-count`] === `0` &&
            !!toggleTableEmpty &&
            !!EmptyTable
          ) {
            toggleTableEmpty(true)
          } else {
            if (!!toggleTableEmpty) toggleTableEmpty(false)
            setTableData({
              ...tableData,
              total: headers[`total-count`] || tableData.total,
              data,
              status: { pending: false, error: false, completed: true }
            })
          }
        })
        .catch(() =>
          setTableData({
            ...tableData,
            data: mockData,
            status: { pending: false, error: true, completed: false }
          })
        )
    }
  }

  const skipFirstRender = useRef(false)

  useEffect(() => {
    if (skipFirstRender.current) {
      if (tableData.page === 1) {
        makeRequest(1)
      } else {
        changePage(1)
      }
    } else {
      skipFirstRender.current = true
    }
  }, [filterDeps])

  useEffect(() => makeRequest(), [
    dataSource,
    tableData.page,
    tableData.sort,
    waitRequest,
    ...refreshCond
  ])

  return { ...tableData, changePage, changeSort }
}

export default useTable
