import React from "react"

import { Table } from "antd"

import { useRequestParams } from "api/hooks"

import PaginationComponent from "Components/Pagination/index"

import useTable from "./useTable"
import { TableComponentProps, UseTableReturn } from "./types"

const DEFAULT_COLUMN_WIDTH = 130

/**
 * @param {array} data - TODO: mock data, remove and use api in useTable
 * @param {string} dataSource - part of url, source of data for Table
 * @param {array} columnsData - config for creating columns for table
 * * columnsData:
 * * * @param {string, string, number, type } title, name, indexId, type - basic params of columnsData
 * @param {string} scrollX - not required param, for table with many columns
 */

const TableComponent = (props: TableComponentProps) => {
  const { logout, developer, addDeveloperLink } = useRequestParams()
  const {
    dataSource,
    waitRequest,
    columnsData,
    scrollX,
    scrollWithoutPagination,
    scrollY,
    refresh,
    classNameForPagination,
    mockData,
    filter,
    filterDeps,
    statusData,
    toggleTableEmpty,
    withHall,
    getTableData,
    isTableEmpty,
    EmptyTable,
    extraRequestBody,
    ...rest
  } = props

  const {
    data,
    total,
    status,
    page,
    changePage,
    sort,
    changeSort
  }: UseTableReturn = useTable({
    dataSource,
    scrollWithoutPagination,
    mockData,
    refresh,
    filter,
    filterDeps,
    statusData,
    logout,
    toggleTableEmpty,
    getTableData,
    waitRequest,
    withHall,
    developer,
    addDeveloperLink,
    EmptyTable,
    extraRequestBody
  })

  const columns: any = columnsData.map(item => ({
    title: item.title,
    dataIndex: item.dataIndex,
    key: item.key,
    fixed: item.fixed,
    width: item.width || DEFAULT_COLUMN_WIDTH,
    sorter: item.withoutSorter ? null : () => null,
    sortDirections: ["descend", "ascend"],
    sortOrder: sort.field === item.key ? sort.order : null,
    render: item.render
  }))

  const withoutPagination = Boolean(10 >= total) && !scrollWithoutPagination

  return (
    <div>
      {isTableEmpty && !!EmptyTable ? (
        <EmptyTable />
      ) : (
        <div className="ComponentWrapper">
          <div>
            <Table
              onChange={(pagination: any, filters: any, sorter: any) => {
                if (
                  sorter.order !== sort.order ||
                  sorter.field !== sort.field
                ) {
                  const order = sorter.order ? `${sorter.order}` : undefined
                  const field = sorter.field ? `${sorter.field}` : undefined
                  changeSort({ order, field })
                }
              }}
              columns={columns}
              dataSource={data}
              pagination={false}
              showSorterTooltip={false}
              tableLayout="fixed"
              scroll={{
                x: scrollX,
                y:
                  scrollY ||
                  `calc(100vh - ${withoutPagination ? `265` : `335`}px)`
              }}
              loading={status.pending}
              {...rest}
            />
          </div>
          {!withoutPagination && (
            <div className={classNameForPagination || `PaginationWrapper`}>
              <PaginationComponent
                page={page}
                pending={status.pending}
                changePage={changePage}
                total={total}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default TableComponent
