import React from "react"
import BetRequest from "api/request"
import moment from "moment"
import { Progress } from "antd"
import { RouteComponentProps } from "react-router-dom"
import { ReactComponent as OkIcon } from "Components/icons/check.svg"
import { ReactComponent as CrossIcon } from "Components/icons/redCross.svg"
import { ISuccessPage, ISuccessData } from "Containers/SuccessPage"
import { CheckBoxComponent } from "Components/CheckBox"
import { InputTextAreaComponent, InputNumberComponent } from "Components/Input"
import { StoreDevelopState } from "store/developer/types"
import PhoneInputComponent from "Components/PhoneInput"
import { IEmployeeData } from "Containers/CreateComponent/Employee/helper"

const updateRequestVisit = ({
  data,
  logout,
  history,
  developer,
  addDeveloperLink,
  url
}: {
  data: ISuccessData
  logout: () => void
  history: RouteComponentProps["history"]
  url: string
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}) =>
  BetRequest({
    method: `PUT`,
    url,
    logout,
    history,
    developer,
    addDeveloperLink,
    requestBody: { ...data }
  })

type getColumnsProps = {
  duration: number
  lesson_duration: number
  date_end: number | null
  date_start: number | null
  initial: ISuccessData
  setSuccessData: (data: ISuccessData) => void
  editMode: boolean
  successMode: boolean
}

export const getColumns = ({
  duration,
  lesson_duration,
  date_end,
  date_start,
  initial,
  setSuccessData,
  editMode,
  successMode
}: getColumnsProps) => {
  const offset = new Date().getTimezoneOffset() * 60000
  const disableByEndOfCourse =
    moment
      .utc(date_end)
      .startOf(`day`)
      .add(moment.utc(date_start).hour() + lesson_duration, `hours`)
      .valueOf() +
      offset >
    moment.utc().valueOf()
  const visitArr = []
  for (let i = 0; i < duration; i++) {
    visitArr.push(`value`)
  }
  const gradeColumns = visitArr.map((item, key) => ({
    title: `${key + 1}-й д.`,
    key: `visit-${key}`,
    dataIndex: `visit-${key}`,
    withoutSorter: true,
    width: 40,
    // eslint-disable-next-line react/display-name
    render: (
      text: number | null,
      record: { visit: number[] | null; id: number }
    ) => {
      const startForDisabled = moment
        .utc(date_start)
        .add(key, `days`)
        .valueOf()
      const endForDisabled = moment().valueOf()
      const disabledCheckBox = startForDisabled > endForDisabled
      return (
        <div key={`visit-${key}`} className="SuccessCourseTableItem">
          {successMode ? (
            <div>
              <CheckBoxComponent
                label=""
                disabled={disabledCheckBox}
                checked={Boolean(
                  initial &&
                    initial[record.id] &&
                    initial[record.id].visits &&
                    initial[record.id].visits[key]
                )}
                onChange={() => {
                  if (record.id && initial[record.id].visits) {
                    const newVisits = [...initial[record.id].visits]
                    newVisits[key] = initial[record.id].visits[key] ? 0 : 1
                    const dataForSave = {
                      ...initial,
                      [record.id]: {
                        ...initial[record.id],
                        visits: newVisits,
                        result: undefined
                      }
                    }
                    setSuccessData(dataForSave)
                  }
                }}
              />
            </div>
          ) : (
            <div>
              {record.visit && record.visit[key] ? <OkIcon /> : <CrossIcon />}
            </div>
          )}
        </div>
      )
    }
  }))
  return [
    {
      title: "ФИО сотрудника",
      key: "name",
      dataIndex: "name",
      withoutSorter: false,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div className="SuccessCourseTableName">{text}</div>
      )
    },
    {
      title: "Дата трудоустройства",
      key: "date_hired",
      dataIndex: "date_hired",
      withoutSorter: true,
      width: 80,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div className="SuccessCourseTableItem">
          {moment(text).format(`DD.MM.YYYY`)}
        </div>
      )
    },
    {
      title: "Должность",
      key: "position",
      dataIndex: "position",
      withoutSorter: true,
      width: 80,
      // eslint-disable-next-line react/display-name
      render: (text: number | null) => (
        <div className="SuccessCourseTableItem">{text}</div>
      )
    },
    {
      title: "Номер телефона",
      key: "phone",
      dataIndex: "phone",
      withoutSorter: true,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (phone: string | undefined, record: IEmployeeData) => {
        return (
          <div className="SuccessCourseTableItem">
            {successMode ? (
              <PhoneInputComponent phone={phone} employeeId={record.id} />
            ) : (
              phone
            )}
          </div>
        )
      }
    },
    ...gradeColumns,
    {
      title: "Комментарий",
      key: "comment",
      dataIndex: "comment",
      withoutSorter: true,
      width: 120,
      // eslint-disable-next-line react/display-name
      render: (text: number | null, record: { id: number }) => (
        <div className="SuccessCourseTableComment">
          {successMode || editMode ? (
            <div>
              <InputTextAreaComponent
                placeholder=""
                value={initial[record.id].comment || undefined}
                onChange={({ target: { value } }) => {
                  const dataForSave = {
                    ...initial,
                    [record.id]: { ...initial[record.id], comment: value }
                  }
                  setSuccessData(dataForSave)
                }}
              />
            </div>
          ) : (
            <div>{text}</div>
          )}
        </div>
      )
    },
    {
      title: "Результат",
      key: "result",
      dataIndex: "result",
      withoutSorter: true,
      width: 60,
      // eslint-disable-next-line react/display-name
      render: (text: number | null, record: { id: number }) => {
        let percentColor = { color: ``, background: `` }
        if (text !== null) {
          if (text < 26)
            percentColor = { color: `#FF6E6E`, background: `#fadbdb` }
          if (25 < text && text < 75)
            percentColor = { color: `#FFA800`, background: `#fae9bf` }
          if (text > 74)
            percentColor = { color: `#20C933`, background: `#c7f1cc` }
        }
        const resultDisabled =
          (editMode || successMode) &&
          initial &&
          initial[record.id] &&
          ((initial[record.id].visits &&
            initial[record.id].visits.indexOf(0) > -1) ||
            !initial[record.id].visits)
        return (
          <div>
            {editMode || successMode ? (
              <div>
                <InputNumberComponent
                  disabled={resultDisabled || !date_end || disableByEndOfCourse}
                  value={initial[record.id].result}
                  min={0}
                  max={100}
                  onChange={(value: number | undefined | string) => {
                    if (initial[record.id]) {
                      setSuccessData({
                        ...initial,
                        [record.id]: {
                          ...initial[record.id],
                          result: value && value > 100 ? 100 : value
                        }
                      })
                    }
                  }}
                />
              </div>
            ) : (
              <div>
                {text !== null ? (
                  <div className="SuccessCourseTablePercentWrapper">
                    <div className="SuccessCourseTablePercentText">{text}%</div>
                    <Progress
                      trailColor={percentColor.background}
                      strokeColor={percentColor.color}
                      showInfo={false}
                      percent={text}
                    />
                  </div>
                ) : (
                  <div className="SuccessCourseTableItem">Не допущен</div>
                )}
              </div>
            )}
          </div>
        )
      }
    }
  ]
}

type getRequestInfoType = {
  id: string | undefined
  logout: () => void
  history: RouteComponentProps["history"]
  changeData: (data: ISuccessPage) => void
  requestData: ISuccessPage
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

const getRequestInfo = ({
  id,
  logout,
  history,
  changeData,
  requestData,
  developer,
  addDeveloperLink
}: getRequestInfoType) => {
  changeData({ ...requestData, pending: true })
  BetRequest({
    url: `application/${id}`,
    developer,
    addDeveloperLink,
    method: `GET`,
    history,
    logout
  })
    .then(({ data }) => changeData({ ...requestData, data, pending: false }))
    .catch(() => changeData({ ...requestData, pending: false }))
}

interface ISaveCompleted {
  id: string | undefined
  logout: () => void
  history: RouteComponentProps["history"]
  completedData: ISuccessData
  status_id: number | undefined
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

export const saveCompleted = ({
  id,
  history,
  logout,
  completedData,
  developer,
  addDeveloperLink,
  status_id
}: ISaveCompleted) => {
  const completedArray = (Object.values(completedData) || []).map(item => ({
    employee_id: item.id,
    visit: item.visits,
    result:
      item.result === undefined || item.result === null ? null : item.result,
    comment: item.comment
  }))
  const isSupport = localStorage.getItem(`role`) === `ROLE_SUPPORT`
  if (!isSupport) {
    BetRequest({
      url: `admin/application/${id}/employees`,
      method: `PUT`,
      developer,
      addDeveloperLink,
      history,
      logout,
      requestBody: { employees: completedArray, status_id }
    }).then(() => history.push(`/history`))
  } else {
    history.push(`/history`)
  }
}

export default getRequestInfo
