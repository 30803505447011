import React, { useMemo, useState } from "react"

import { Form } from "antd"
import { FormInstance } from "antd/lib/form/Form"
import { useRequest } from "api/hooks"

import { CreateModalProps } from "./types"
import {
  checkValidate,
  createApproveText,
  createModalFormConstants,
  createModalText,
  dayOptions,
  editApproveText,
  formatOptions,
  FormItemNames,
  getLabelByFormat,
  newInitialValues
} from "./constants"

import moment, { Moment } from "moment"

import DayFields from "./Days"
import ApproveModal from "../Approve"

import { ReactComponent as BackIcon } from "Components/icons/backArrow.svg"
import InputComponent from "Components/Input"
import { ButtonPrimary } from "Components/Button"
import SelectComponent from "Components/Select"
import { TimePickerComponent } from "Components/DatePicker"
import { TIME } from "Components/DatePicker/constants"
import EditorComponent from "Components/Editor"
import { checkEditor } from "Components/Editor/constants"
import { InfoData } from "../types"

const CreateModal = ({ close, initial, refreshList }: CreateModalProps) => {
  const id = initial?.id
  const initialDays = Array.from(Array(initial?.days?.length || 0).keys()) || []

  const initialValues = useMemo(() => newInitialValues(initial), [id])

  const [daysArray, setDaysArray] = useState<number[]>(initialDays)
  const [approveModal, setApproveModal] = useState(false)

  const [disableSubmit, setDisabled] = useState(true)

  const [form] = Form.useForm()
  const [saveData, setSaveData] = useState<InfoData>()

  const onChangeHandle = ({
    value,
    field,
    currentForm
  }: {
    value: string | number | Moment
    field: string
    currentForm: FormInstance<any>
  }) => {
    form.setFieldsValue({ [field]: value })
    setDisabled(checkValidate(currentForm))
  }

  const formatDurationDuration = Form.useWatch(
    createModalFormConstants.format.field,
    form
  )

  const formatDescription = Form.useWatch(
    createModalFormConstants.lesson_format_description.field,
    form
  )

  const formatDescForSave =
    !formatDescription || checkEditor(formatDescription)
      ? undefined
      : formatDescription

  const onFinish = (value: InfoData) => {
    setApproveModal(true)
    setSaveData({
      ...value,
      lesson_assemble: moment(value.lesson_assemble).format(TIME),
      lesson_start: moment(value.lesson_start).format(TIME),
      lesson_format_description: formatDescForSave,
      is_graph_visible: value.is_graph_visible
    })
  }
  const currentDuration = Form.useWatch(
    createModalFormConstants.lesson_duration.field,
    form
  )

  const formatLabel = useMemo(() => getLabelByFormat(formatDurationDuration), [
    formatDurationDuration
  ])

  const requestUrl = useMemo(() => createModalFormConstants.url(id), [id])
  const { request: saveCourse } = useRequest({
    url: requestUrl,
    requestBody: {
      ...saveData,
      visible: !!initial?.visible,
      is_graph_visible: !!saveData?.is_graph_visible
    },
    method: id ? "PUT" : "POST"
  })

  return (
    <div className="CreateModalWrapper">
      <div className="CreateModalHeader FlexRow">
        <div onClick={close}>
          <BackIcon />
        </div>
        <div className="CreateModalTitle">
          {id ? createModalText.editTitle : createModalText.createTitle}
        </div>
      </div>
      <div className="CreateModalFormWrapper">
        <Form form={form} onFinish={onFinish} initialValues={initialValues}>
          <div className="CreateModalFormItemWrapper">
            <div className="CreateCourseFieldLabel">
              {createModalFormConstants.name.label}
            </div>
            <Form.Item name={createModalFormConstants.name.field}>
              <InputComponent
                maxLength={createModalFormConstants.name.maxLength}
                value={form.getFieldValue(createModalFormConstants.name.field)}
                placeholder={createModalFormConstants.name.placeholder}
                onChange={({ target: { value } }) => {
                  onChangeHandle({
                    value,
                    field: createModalFormConstants.name.field,
                    currentForm: form
                  })
                }}
              />
            </Form.Item>
          </div>
          <div className="FlexRow CreateModalFormRow">
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants[FormItemNames.graph].label}
              </div>
              <Form.Item
                name={createModalFormConstants[FormItemNames.graph].field}
              >
                <SelectComponent
                  className="CreateCourseSelectField"
                  allowClear={false}
                  placeholder={
                    createModalFormConstants[FormItemNames.graph].placeholder
                  }
                  onChange={value =>
                    onChangeHandle({
                      value,
                      field:
                        createModalFormConstants[FormItemNames.graph].field,
                      currentForm: form
                    })
                  }
                  options={
                    createModalFormConstants[FormItemNames.graph].options
                  }
                />
              </Form.Item>
            </div>
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants.format.label}
              </div>
              <Form.Item name={createModalFormConstants.format.field}>
                <SelectComponent
                  className="CreateCourseSelectField"
                  allowClear={false}
                  placeholder={createModalFormConstants.format.placeholder}
                  onChange={value =>
                    onChangeHandle({
                      value,
                      field: createModalFormConstants.format.field,
                      currentForm: form
                    })
                  }
                  options={formatOptions}
                />
              </Form.Item>
            </div>
          </div>
          <div className="FlexRow CreateModalFormRow">
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants.duration.label}
              </div>
              <Form.Item name={createModalFormConstants.duration.field}>
                <SelectComponent
                  className="CreateCourseSelectField"
                  allowClear={false}
                  onChange={value => {
                    form.setFieldsValue({ duration: value })
                    setDaysArray(Array.from(Array(value || 0).keys()))
                    form.setFieldsValue({ days: Array.from(Array(value || 0)) })
                    setDisabled(checkValidate(form))
                  }}
                  placeholder={createModalFormConstants.duration.placeholder}
                  options={dayOptions}
                />
              </Form.Item>
            </div>
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants.lesson_duration.label}
              </div>
              <Form.Item name={createModalFormConstants.lesson_duration.field}>
                <InputComponent
                  placeholder={
                    createModalFormConstants.lesson_duration.placeholder
                  }
                  style={{ width: `100%` }}
                  value={currentDuration}
                  disabled={false}
                  onChange={({ target: { value } }) => {
                    const lastChar = value?.charAt(value?.length - 1)
                    const isValid =
                      !!value && !isNaN(Number(lastChar)) && Number(value) < 25

                    if (value) {
                      form.setFieldsValue({
                        [createModalFormConstants.lesson_duration
                          .field]: isValid ? value : value?.slice(0, -1)
                      })
                    }
                    setDisabled(checkValidate(form))
                  }}
                />
              </Form.Item>
            </div>
          </div>
          <div className="FlexRow CreateModalFormTimeRow CreateModalFormRow">
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants.lesson_assemble.label}
              </div>
              <Form.Item name={createModalFormConstants.lesson_assemble.field}>
                <TimePickerComponent
                  onChange={value => {
                    onChangeHandle({
                      value: moment(value, TIME),
                      field: createModalFormConstants.lesson_assemble.field,
                      currentForm: form
                    })
                  }}
                />
              </Form.Item>
            </div>
            <div className="CreateModalFormItemWrapper">
              <div className="CreateCourseFieldLabel">
                {createModalFormConstants.lesson_start.label}
              </div>
              <Form.Item name={createModalFormConstants.lesson_start.field}>
                <TimePickerComponent
                  onChange={value =>
                    onChangeHandle({
                      value: moment(value, TIME),
                      field: createModalFormConstants.lesson_start.field,
                      currentForm: form
                    })
                  }
                />
              </Form.Item>
            </div>
          </div>
          {!!formatDurationDuration && (
            <Form.Item
              className="CreateModalFormItemFormatDescription"
              name={createModalFormConstants.lesson_format_description.field}
            >
              <div className="CreateCourseFieldLabel">{formatLabel}</div>
              <EditorComponent
                disabled={false}
                initial={initial?.lesson_format_description}
                setFormData={(text: string) =>
                  onChangeHandle({
                    value: text,
                    field:
                      createModalFormConstants.lesson_format_description.field,
                    currentForm: form
                  })
                }
              />
            </Form.Item>
          )}
          <div>
            <DayFields
              form={form}
              daysArray={daysArray}
              setDisabled={setDisabled}
              initial={initial}
            />
          </div>
          <ButtonPrimary
            className="CreateModalSubmitButton"
            disabled={disableSubmit}
            htmlType="submit"
          >
            {id ? createModalText.edit : createModalText.create}
          </ButtonPrimary>
        </Form>
      </div>
      {approveModal && (
        <ApproveModal
          close={close}
          cancel={() => setApproveModal(false)}
          refresh={refreshList}
          approve={(success: () => void) => {
            saveCourse().then(() => {
              success()
            })
          }}
          data={id ? editApproveText : createApproveText}
        />
      )}
    </div>
  )
}

export default CreateModal
