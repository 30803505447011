import React, { useState, useEffect } from "react"
import { RouteComponentProps } from "react-router-dom"
import moment, { Moment } from "moment"
import CalendarComponent from "Components/Calendar"
import { ReactComponent as LeftArrowIcon } from "Components/icons/leftArrow.svg"
import { ReactComponent as RightArrowIcon } from "Components/icons/rightArrow.svg"
import { IEditData } from "../index"
import GraduationComponent from "./Graduation"
import getCalendarData from "./helper"
import { StoreDictionaryCourse } from "store/dictionary/types"
import { StoreDevelopState } from "store/developer/types"

export interface ICurrentCourseData {
  name: string
  graph_id: number
  students: number
  booking_students: number
  date_start: number
  date_end: number
  day: number
}

type IEditDate = {
  logout: () => void
  history: RouteComponentProps["history"]
  initialData: IEditData
  changeInitialData: (data: IEditData) => void
  currentCourse: StoreDictionaryCourse | undefined
  developer: StoreDevelopState
  addDeveloperLink: (data: StoreDevelopState) => void
}

interface ICourseData {
  [key: number]: Array<ICurrentCourseData>
}

const EditDate = ({
  logout,
  history,
  initialData,
  changeInitialData,
  currentCourse,
  developer,
  addDeveloperLink
}: IEditDate) => {
  const [courseData, setCourseData] = useState<ICourseData>({})
  const [currentPosition, changePosition] = useState<Moment>(
    initialData.date_start ? moment(initialData.date_start) : moment()
  )
  const currentDay = moment()
    .startOf(`day`)
    .valueOf()
  useEffect(() => {
    const currentMonth: number = currentPosition.month() + 1
    const currentYear: number = currentPosition.year()
    getCalendarData({
      month: currentMonth,
      year: currentYear,
      course_id: initialData.course_id,
      setCourseData,
      history,
      developer,
      addDeveloperLink,
      logout
    })
  }, [currentPosition.month()])

  return (
    <div className="EditModalDateCalendarComponentWrapper">
      <CalendarComponent
        value={currentPosition}
        onPanelChange={(value: Moment) => changePosition(value)}
        disabledDate={(date: Moment) =>
          date.valueOf() < currentDay || date.day() === 6 || date.day() === 0
        }
        headerRender={({ value, type, onChange, onTypeChange }: any) => {
          const currentMonthYear = value.format(`MMMM YYYY`)
          const isCurrentMonth =
            moment().month() === value.month() &&
            moment().year() === value.year()
          return (
            <div className="CalendarComponentHeaderEdit">
              <div
                className="CalendarComponentHeaderArrow CalendarComponentHeaderArrowLeft"
                style={{
                  visibility: !isCurrentMonth ? `inherit` : undefined,
                  marginLeft: `auto`
                }}
                onClick={() => {
                  const newValue = value
                    .clone()
                    .month(currentPosition.month() - 1)
                  onChange(newValue)
                  changePosition(newValue)
                }}
              >
                <LeftArrowIcon />
              </div>
              <div className="CalendarComponentHeaderDate">
                {currentMonthYear}
              </div>
              <div
                className="CalendarComponentHeaderArrow"
                onClick={() => {
                  const newValue = value
                    .clone()
                    .month(currentPosition.month() + 1)
                  onChange(newValue)
                  changePosition(newValue)
                }}
              >
                <RightArrowIcon />
              </div>
            </div>
          )
        }}
        dateCellRender={(value: Moment) => {
          let isCellActive = false
          if (initialData.date_start) {
            isCellActive =
              moment(initialData.date_start)
                .startOf(`day`)
                .valueOf() <= value.valueOf() &&
              value.valueOf() <=
                moment
                  .utc(initialData.date_end)
                  .endOf(`day`)
                  .valueOf() &&
              value.day() !== 0 &&
              value.day() !== 6
          }

          const currentDay: number = value.date()
          const isCurrentMonth = currentPosition.month() === value.month()
          const isDisabled =
            value.valueOf() < moment().valueOf() ||
            value.day() === 6 ||
            value.day() === 0

          if (courseData && courseData[currentDay] && isCurrentMonth) {
            return (
              <div>
                <div className={isCellActive ? `ActiveCalendarCell` : ``} />
                <GraduationComponent
                  course={courseData[currentDay]}
                  currentCourse={currentCourse}
                  initialData={initialData}
                  changeInitialData={changeInitialData}
                  startDate={value}
                  isDisabled={isDisabled}
                />
              </div>
            )
          }
          return <div className={isCellActive ? `ActiveCalendarCell` : ``} />
        }}
      />
    </div>
  )
}

export default EditDate
