import axios from "axios"

import { MakeLoginProps } from "./types"
import { StoreUserState } from "store/auth/types"

const makeLogin = ({
  loginData,
  login,
  setLoginData,
  developer,
  addDeveloperLink,
  token
}: MakeLoginProps) => {
  const { password, username } = loginData
  setLoginData({ ...loginData, pending: true })
  axios({
    url: `${process.env.REACT_APP_AUTH_URL}/${
      !!token ? `loginByToken/${token}` : "login"
    }`,
    method: !!token ? "get" : `post`,
    withCredentials: true,
    headers: {
      "Access-Control-Allow-Credentials": true
    },
    data: !!token ? undefined : { username, password }
  })
    .then(response => {
      const { data, headers } = response
      const responseData: StoreUserState = {
        fullName: data.fullName || ``,
        role: data.role || ``,
        token: data.token || ``,
        isAuth: data.isAuth || false,
        hall: data.hall || null,
        halls: data.halls || [],
        hallsAddress: data.hallsAddress || {},
        isAdmin: data.role === `ROLE_TRAINING` || data.role === `ROLE_SUPPORT`
      }

      setLoginData({ ...loginData, pending: false, errors: false })
      localStorage.setItem(`user`, JSON.stringify(responseData))
      localStorage.setItem(`role`, data.role)
      if (data.role === `ROLE_DEVELOPER`) {
        localStorage.setItem(
          `developer`,
          JSON.stringify({
            devLinks: [],
            isDeveloper: data.role === `ROLE_DEVELOPER`,
            iconNumber: Math.floor(Math.random() * 5)
          })
        )
      }
      if (headers[`x-debug-token-link`]) {
        addDeveloperLink({
          ...developer,
          [`login`]: { url: `login`, link: headers[`x-debug-token-link`] }
        })
      }
      login({ ...responseData, isAuth: true })
    })
    .catch(() => {
      localStorage.removeItem(`user`)
      localStorage.removeItem(`developer`)
      setLoginData({ ...loginData, pending: false, errors: true })
    })
}

export default makeLogin
