/* eslint react/prop-types: "off" */
import React from "react"
import { Select, TreeSelect } from "antd"
import { SelectProps, OptionProps } from "antd/lib/select"
import { ReactComponent as SelectArrowIcon } from "Components/icons/select-arrow.svg"

interface ISelectComponent extends SelectProps<any> {
  rest?: any
  name?: string
  error?: boolean | string
}

type IOptionComponent = OptionProps

export const CustomOption = ({
  value,
  title,
  description
}: IOptionComponent) => {
  return (
    <Select.Option value={value}>
      <div>{title}</div>
      <div>{description}</div>
    </Select.Option>
  )
}

const SelectComponent = ({
  children,
  placeholder,
  error = false,
  ...rest
}: ISelectComponent) => (
  <div className={`SelectWrapper ${error ? `ErrorSelectWrapper` : ``}`}>
    <Select
      className="SelectComponent"
      placeholder={placeholder}
      allowClear={true}
      suffixIcon={<SelectArrowIcon />}
      {...rest}
    >
      {children}
    </Select>
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export const TreeSelectComponent = ({ error = false, ...rest }) => (
  <div className={`TreeSelectWrapper ${error ? `ErrorSelectWrapper` : ``}`}>
    <TreeSelect suffixIcon={<SelectArrowIcon />} {...rest} />
    {!!error && <div className="ErrorText">{error}</div>}
  </div>
)

export default SelectComponent
