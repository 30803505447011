import React from "react"

import { Form } from "antd"

import { checkValidate, createModalFormConstants } from "../constants"
import { DayFieldsProps, DayProps } from "./types"

import { textOpenCategory } from "../../helper"

import EditorComponent from "Components/Editor"

const Day = ({ day, form, setDisabled, initial }: DayProps) => {
  const label = textOpenCategory.getDay(day + 1)
  return (
    <div className="CreateModalFormItemDayWrapper">
      <div className="CreateCourseFieldLabel">{label}</div>
      <EditorComponent
        disabled={false}
        initial={initial}
        setFormData={(text: string) => {
          const newDays =
            form.getFieldsValue([createModalFormConstants.days.field])?.days ||
            []
          const removed = newDays.splice(day, 1, text)
          form.setFieldsValue({ days: newDays })
          setDisabled(checkValidate(form))
        }}
      />
    </div>
  )
}

const DayFields = ({
  form,
  daysArray,
  setDisabled,
  initial
}: DayFieldsProps) => {
  return (
    <Form.Item name={createModalFormConstants.days.field}>
      {daysArray.map(item => {
        const initialCurrentDay = initial?.days[item] || undefined
        return (
          <Day
            key={`day_${item}_component`}
            day={item}
            form={form}
            setDisabled={setDisabled}
            initial={initialCurrentDay}
          />
        )
      })}
    </Form.Item>
  )
}

export default DayFields
