import React, { useState, useMemo } from "react"
import { useSelector } from "react-redux"

import { useHistory } from "react-router-dom"

import { useNow } from "api/hooks"
import { CREATE_PAGE } from "Containers/BaseLayout/constants"

import { Select } from "antd"

import { Moment } from "moment"

import { ReactComponent as PlusIcon } from "Components/icons/plus.svg"
import { ReactComponent as SearchIcon } from "Components/icons/search.svg"

import { ButtonPrimary } from "Components/Button"
import SelectComponent from "Components/Select"
import { RangePickerComponent } from "Components/DatePicker"
import TableComponent from "Components/Table"
import InputComponent from "Components/Input"

import CancelModal from "./CancelModal"
import EmptyTable from "./EmptyTable"
import EditComponent from "./EditModal"
import MoreModal from "./MoreModal"
import CheckModal from "./CheckModal"

import DownloadComponent, { getColumns } from "./helper"

import { StoreDictionary } from "store/dictionary/types"
import { getDictionary } from "store/dictionary/selector"

import { TableFilter, CancelModalData, CancelModalInitial } from "./types"
import { HistoryModalData } from "Components/History/types"
import HistoryModal from "../../Components/History"

const { Option } = Select

const HistoryComponent = (props: {
  groupHall: string | undefined
}): JSX.Element => {
  const { status, course }: StoreDictionary = useSelector(getDictionary)
  const { groupHall } = props
  const userFromStorage: string | undefined =
    localStorage.getItem(`user`) || undefined
  const user:
    | { token: string; hall: number; role: string }
    | undefined = userFromStorage
    ? JSON.parse(userFromStorage)
    : userFromStorage

  const isAdmin =
    !!user &&
    (user.role === `ROLE_TRAINING` ||
      user.role === `ROLE_SUPPORT` ||
      user.role === `ROLE_DEVELOPER`)
  const isUser = !!user && user.role === `ROLE_PARTNER`

  const { timestamp, refresh } = useNow()
  const history = useHistory()

  const initialTableFilter: TableFilter = {
    date_start: undefined,
    date_end: undefined,
    course_id: undefined,
    status_id: undefined,
    hall: undefined,
    query: undefined
  }

  const [tableFilter, changeTableFilter] = useState<TableFilter>(
    initialTableFilter
  )

  const [moreModalOpen, toggleMoreModal] = useState<null | number>(null)
  const [cancelModalOpen, toggleCancelModal] = useState<CancelModalData>(
    CancelModalInitial
  )
  const [editModalOpen, toggleEditModal] = useState<boolean | number>(false)
  const [isTableEmpty, toggleTableEmpty] = useState(false)
  const [historyModal, toggleHistoryModal] = useState<{
    isOpen: boolean
    id?: number
    data: HistoryModalData[]
  }>({ isOpen: false, data: [], id: undefined })
  const isFilterActive =
    !!tableFilter.date_start ||
    !!tableFilter.course_id ||
    !!tableFilter.hall ||
    !!tableFilter.query ||
    !!tableFilter.status_id
  const [openStatusModal, toggleStatusModal] = useState<number | undefined>(
    undefined
  )
  const columns = useMemo(() => {
    if (!!status && !!course) {
      return getColumns({
        isAdmin,
        status,
        course,
        toggleMoreModal,
        toggleCancelModal,
        toggleEditModal,
        toggleStatusModal,
        toggleHistoryModal
      })
    } else return []
  }, [status, historyModal])
  const redirectToCreate = () => history.push(CREATE_PAGE)

  const isFilterShow = !isTableEmpty || (isTableEmpty && isFilterActive)

  const filterForTable = isUser
    ? {
        ...tableFilter,
        hall: tableFilter.hall || groupHall
      }
    : tableFilter

  const filterDeps = JSON.stringify(filterForTable)

  return (
    <div className="HistoryWrapper">
      {isFilterShow && (
        <div>
          <h1 className="HistoryTitle">История заявок</h1>
          <div className="HistoryEvents">
            <div className="HistoryEventsLeft">
              {isAdmin && !isUser && (
                <div className="HistoryEventsLeftItem">
                  <InputComponent
                    style={{ width: `200px`, marginRight: `16px` }}
                    allowClear={true}
                    suffix={<SearchIcon />}
                    placeholder="Поиск"
                    value={tableFilter.query}
                    onChange={({ target: { value } }) =>
                      changeTableFilter({
                        ...tableFilter,
                        query: value || undefined
                      })
                    }
                  />
                </div>
              )}
              <div className="HistoryEventsLeftItem">
                <RangePickerComponent
                  name="training_date"
                  format="DD.MM.YYYY"
                  style={{ width: `240px`, marginRight: `16px` }}
                  value={
                    tableFilter.date_start && tableFilter.date_end
                      ? [tableFilter.date_start, tableFilter.date_end]
                      : null
                  }
                  onChange={(value: null | Array<Moment>) =>
                    changeTableFilter({
                      ...tableFilter,
                      date_start:
                        value && value[0]
                          ? value[0].startOf(`day`).valueOf()
                          : undefined,
                      date_end:
                        value && value[1]
                          ? value[1].endOf(`day`).valueOf()
                          : undefined
                    })
                  }
                />
              </div>
              <div className="HistoryEventsLeftItem">
                <SelectComponent
                  name="training_type"
                  style={{ width: `280px`, marginRight: `16px` }}
                  placeholder="Тип обучения"
                  value={tableFilter.course_id}
                  onChange={(value: number | null) =>
                    changeTableFilter({ ...tableFilter, course_id: value })
                  }
                >
                  {course.map(item => (
                    <Option
                      key={`${item.name}_${item.id}`}
                      value={item.id || 0}
                    >
                      <div className="TrainingTypeOptionsWrapper">
                        <div className="HistoryTableCourseLabel">
                          {item.name}
                        </div>
                        <div className="HistoryTableCourseDescription">{`(${item.description})`}</div>
                      </div>
                    </Option>
                  ))}
                </SelectComponent>
              </div>
              <div className="HistoryEventsLeftItem">
                <SelectComponent
                  style={{ width: `240px`, marginRight: `16px` }}
                  placeholder="Статус заявки"
                  value={tableFilter.status_id}
                  onChange={(value: number | null) =>
                    changeTableFilter({ ...tableFilter, status_id: value })
                  }
                >
                  {status.map(item => (
                    <Option
                      key={`${item.name}_${item.id}`}
                      value={item.id || 0}
                    >
                      {item.name}
                    </Option>
                  ))}
                </SelectComponent>
              </div>
              <DownloadComponent tableFilter={filterForTable} />
            </div>
            <div className="HistoryEventsRight">
              <ButtonPrimary
                icon={
                  <div style={{ paddingRight: `16px`, marginBottom: `-2px` }}>
                    <PlusIcon />
                  </div>
                }
                disabled={false}
                onClick={redirectToCreate}
              >
                Новая заявка
              </ButtonPrimary>
            </div>
          </div>
        </div>
      )}
      <div className="HistoryTableWrapper">
        <TableComponent
          scrollX={columns.length * 150}
          scrollY="calc(100vh - 490px)"
          columnsData={columns}
          refresh={[timestamp, groupHall]}
          dataSource="applications"
          filter={filterForTable}
          filterDeps={filterDeps}
          withHall={!isFilterActive && !isAdmin}
          statusData={status}
          toggleTableEmpty={toggleTableEmpty}
          isTableEmpty={isTableEmpty}
          EmptyTable={() => (
            <div>
              <EmptyTable
                redirectToCreate={redirectToCreate}
                isFilterShow={isFilterShow}
              />
            </div>
          )}
        />
        {!!moreModalOpen && (
          <MoreModal
            id={moreModalOpen}
            isAdmin={isAdmin}
            status={status}
            course={course}
            history={history}
            close={() => toggleMoreModal(null)}
            toggleCancelModal={toggleCancelModal}
          />
        )}
        {!!cancelModalOpen.active && (
          <CancelModal
            cancelData={cancelModalOpen}
            history={history}
            close={() => {
              toggleCancelModal({
                active: false,
                id: null,
                status: null,
                isAdmin: false,
                graphId: null
              })
              if (moreModalOpen) {
                toggleMoreModal(null)
              }
            }}
            refresh={refresh}
          />
        )}
        {!!editModalOpen && (
          <EditComponent
            status={status}
            course={course}
            history={history}
            id={editModalOpen}
            close={() => toggleEditModal(false)}
            refresh={refresh}
          />
        )}
        {openStatusModal && (
          <CheckModal
            refresh={refresh}
            open={openStatusModal}
            close={() => toggleStatusModal(undefined)}
          />
        )}
        {historyModal.isOpen && (
          <HistoryModal
            close={() =>
              toggleHistoryModal({ isOpen: false, data: [], id: undefined })
            }
            data={historyModal.data}
            id={historyModal.id}
          />
        )}
      </div>
    </div>
  )
}

export default HistoryComponent
