import React, { useState } from "react"
import moment, { Moment } from "moment"
import { ReactComponent as GraduationIcon } from "Components/icons/graduation.svg"
import { PanelState } from "Containers/CreateComponent"
import { Popover, Tooltip, TooltipProps } from "antd"

import { ICurrentCourseData } from "Containers/CreateComponent/Date"

const GraduationComponent = ({
  course,
  panelState,
  changePanelState,
  startDate,
  isDisabled
}: {
  course: Array<ICurrentCourseData>
  panelState: PanelState
  changePanelState: (data: PanelState) => void
  startDate: Moment
  isDisabled: boolean
}) => {
  const currentWeekDay: number = startDate.day()
  const popupDirection =
    0 < currentWeekDay && currentWeekDay < 5 ? `right` : `left`
  const [visiblePopover, changeVisiblePopover] = useState(false)
  const [hoverInfo, changeHoverInfo] = useState<{
    hour: string | null
    start_date: string | null
  }>({
    hour: null,
    start_date: null
  })
  const cellWidth = (window.innerWidth - 770) / 7
  const noHover = window.innerWidth < 920
  return (
    <div className="CalendarGraduationWrapper">
      <Popover
        placement="bottomLeft"
        content={
          <div className="CalendarGraduationPopup">
            {course.map(item => {
              const haveVacancy = item && item.students > item.booking_students
              return (
                <Tooltip
                  placement={popupDirection}
                  key={`${item.date_start}_${item.graph_id}`}
                  style={{ width: `300px` }}
                  color="#FAEBEB"
                  trigger={!haveVacancy ? `hover` : ``}
                  overlayClassName="CoursePopupTooltip"
                  overlay={
                    <div className="CoursePopupTooltipWrapper">
                      <div className="CoursePopupTooltipTitle">
                        В этой группе недостаточно мест
                      </div>
                      <div className="CoursePopupTooltipText">
                        Выберите другую группу или день для подачи заявки
                      </div>
                    </div>
                  }
                >
                  <div
                    key={`${item.date_start}_${item.graph_id}`}
                    className={`CalendarGraduationPopupItem ${
                      haveVacancy ? `GraduationPopupItemActive` : ``
                    }`}
                    onClick={e => {
                      if (haveVacancy) {
                        e.stopPropagation()
                        const currentStartDay = startDate
                          .startOf(`day`)
                          .valueOf()
                        changePanelState({
                          ...panelState,
                          employee: {
                            list: [],
                            data: {}
                          },
                          date: {
                            name: item.name,
                            students: item.students,
                            booking_students: item.booking_students,
                            graph_id: item.graph_id,
                            course_date: currentStartDay,
                            date_end: item.date_end,
                            date_start: item.date_start,
                            day: item.day
                          }
                        })
                        changeVisiblePopover(false)
                      }
                    }}
                    onMouseEnter={() => {
                      if (haveVacancy && !noHover) {
                        changeHoverInfo({
                          hour: `${panelState.course.lesson_duration} часов/день`,
                          start_date: `начало в ${moment(
                            item.date_start
                          ).format(`HH:mm`)}`
                        })
                      }
                    }}
                    onMouseLeave={() =>
                      changeHoverInfo({
                        hour: null,
                        start_date: null
                      })
                    }
                  >
                    <div className="CoursePopupItemName">{item.name}</div>
                    <div className="CoursePopupItemInfo">
                      Доступно мест:
                      <span
                        className={
                          (panelState.date.graph_id === item.graph_id &&
                            panelState.date.students &&
                            panelState.date.students ===
                              panelState.date.booking_students) ||
                          item.booking_students === item.students
                            ? `CoursePopupItemWithoutFreePlace`
                            : `CoursePopupItemPlace`
                        }
                      >
                        {` ${
                          panelState.date.graph_id === item.graph_id &&
                          panelState.date.students &&
                          panelState.date.booking_students !== null
                            ? panelState.date.students -
                              panelState.date.booking_students
                            : item.students - item.booking_students
                        }`}
                      </span>
                      <span className="CoursePopupItemPlace">{`/${item.students}`}</span>
                    </div>
                  </div>
                </Tooltip>
              )
            })}
          </div>
        }
        trigger="click"
        visible={visiblePopover}
        onVisibleChange={changeVisiblePopover}
      >
        <div
          className={
            isDisabled
              ? `DisabledGraduationIconWrapper`
              : `GraduationIconWrapper`
          }
        >
          <GraduationIcon />
        </div>
      </Popover>
      {hoverInfo.hour ? (
        <div
          style={{
            width: `${
              panelState.course.duration
                ? panelState.course.duration * cellWidth
                : 240
            }px`
          }}
          className="CourseHoverInfo"
        >
          <div className="CourseHoverInfoHour">{hoverInfo.hour}</div>
          <div className="CourseHoverInfoDateStart">{`начало в ${hoverInfo.start_date}`}</div>
        </div>
      ) : (
        <div />
      )}
    </div>
  )
}

export default GraduationComponent
