import BetRequest from "api/request"
import { errorCanvas } from "Components/Messages"
import { CreateRequest, IsCourseAvailableRequest } from "./types"

export const isCourseAvailableRequest = ({
  courseId,
  graphId,
  logout,
  history,
  developer,
  addDeveloperLink,
  toggleErrorModal
}: IsCourseAvailableRequest) => {
  return BetRequest({
    url: "application/validate",
    method: "POST",
    logout,
    history,
    developer,
    addDeveloperLink,
    requestBody: {
      course_id: courseId,
      graph_id: graphId
    }
  })
    .then(res => {
      toggleErrorModal({ open: false, description: "" })
      return true
    })
    .catch(err => {
      toggleErrorModal({
        open: true,
        description: err.response.data.description
      })
      return false
    })
}

const createRequest = ({
  isSupport,
  course_id,
  employee_hall,
  employees,
  graph_id,
  toggleSuccessModal,
  logout,
  developer,
  setDisabledButton,
  addDeveloperLink,
  history,
  toggleErrorModal
}: CreateRequest) => {
  setDisabledButton(true)
  isCourseAvailableRequest({
    courseId: course_id,
    graphId: graph_id,
    logout,
    history,
    developer,
    addDeveloperLink,
    toggleErrorModal
  }).then(data => {
    if (data) {
      if (!isSupport) {
        BetRequest({
          url: `application`,
          method: `POST`,
          logout,
          history,
          developer,
          addDeveloperLink,
          requestBody: {
            course_id,
            employees,
            graph_id,
            hall: employee_hall
          }
        })
          .then(() => {
            setDisabledButton(false)
            toggleSuccessModal(true)
          })
          .catch(({ response }) => {
            setDisabledButton(false)
            errorCanvas({
              errors: response.data.errors,
              description: response.data.description
            })
          })
      } else {
        toggleSuccessModal(true)
      }
    }
  })
}

export default createRequest
